import { CAMERA_TYPE_BIRDSEYE, CAMERA_TYPE_FIRSTPERSON } from "../../app/factories/canvasData";
import { radiansToDegrees } from "../../app/utility/math";
import { _venueHistory } from "../../services/venueHistoryService";

export const SHARED_STORAGE_KEY = "exec-shared-settings";
let SAVE_SNAPSHOT_TRIGGER = false;

export const getDefaultSharedSettings = () => {
    return {
        cameraLatitude: 270,
        cameraLongitude: 90,
        cameraZoom: 90,
        cameraPosition: { x: 0, y: 0, z: 0 },
        backgroundType: "color",
        backgroundColor: "#000000",
        backgroundImage: "",
        cameraType: CAMERA_TYPE_FIRSTPERSON,
    }

}

export const backgroundOptions = [
    { name: 'color', value: 'color' },
    { name: 'image', value: 'image' },
    { name: 'video', value: 'video' },
]

const init = () => {
    let saved = sessionStorage.getItem(SHARED_STORAGE_KEY);
    if (saved)
        saved = JSON.parse(saved);
    return saved || getDefaultSharedSettings();
}

const reduce = (state, action) => {
    SAVE_SNAPSHOT_TRIGGER = true;
    switch (action.type) {
        case 'DEFAULT_SHARED':
        case 'LOAD_SHARED':
            action.shared = {
                ...getDefaultSharedSettings(),
                ...(action.shared || {})
            }
            return {
                ...getDefaultSharedSettings(),
                ...action.shared,
            }
        case 'SETTING_CHANGE':
            state[action.name] = action.value;
            return {
                ...state,
            }
        case 'SETTINGS_CHANGE':
            for (let key of Object.keys(action.value))
                state[key] = action.value[key];
            return {
                ...state,
            }
        default:
            SAVE_SNAPSHOT_TRIGGER = false;
            return state
    }
}

const sharedReducer = (state = init(), action) => {
    state = reduce(state, action);
    if (SAVE_SNAPSHOT_TRIGGER) {
        _venueHistory.sharedSnapshot(state);
        sessionStorage.setItem(SHARED_STORAGE_KEY, JSON.stringify(state));
    }
    
    return state;
}
export default sharedReducer