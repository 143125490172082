import * as BABYLON from 'babylonjs';
import { degreesToRadians } from '../../../utility/math';
import { _assetManager } from '../assets/assetManager';
import { getAttributePropertyShapeValue, getShapeValue } from '../shapes/shapeOptions/utility';
import PARTICLE_BASE64 from '../utility/particle';

const updateMesh = (mesh, component, audioFactor, time, parent) => {
    mesh.position.x = getAttributePropertyShapeValue(component, 'position', 'x', audioFactor, time)
    mesh.position.y = getAttributePropertyShapeValue(component, 'position', 'y', audioFactor, time)
    mesh.position.z = getAttributePropertyShapeValue(component, 'position', 'z', audioFactor, time)

    mesh.scaling = BABYLON.Vector3.Zero();
    mesh.scaling.x = getAttributePropertyShapeValue(component, 'scaling', 'x', audioFactor, time)
    mesh.scaling.y = getAttributePropertyShapeValue(component, 'scaling', 'y', audioFactor, time)
    mesh.scaling.z = getAttributePropertyShapeValue(component, 'scaling', 'z', audioFactor, time)

    mesh.rotation = BABYLON.Vector3.Zero();
    mesh.rotation.x = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'x', audioFactor, time))
    mesh.rotation.y = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'y', audioFactor, time))
    mesh.rotation.z = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'z', audioFactor, time))

    const opacity = getShapeValue(component, 'opacity', audioFactor, time);
    mesh.visibility = parent ? (parent.visibility * opacity) : opacity;
}

export const buildParticles = (scene, gizmoManager, component, parent) => {
    let _component;
    let mesh;
    let particleSystem;
    let texture;

    mesh = new BABYLON.Mesh(component.id, scene);
    if (particleSystem) {
        particleSystem.dispose();
    }
    // if (BABYLON.GPUParticleSystem.IsSupported) {
    //     particleSystem = new BABYLON.GPUParticleSystem(component.id, { capacity:1000000 }, scene);
    //     // particleSystem.activeParticleCount = 200000;
    // } else 
    {
        particleSystem = new BABYLON.ParticleSystem(component.id, 50000 , scene);
    }
    particleSystem.id = component.id;
    particleSystem.particleTexture = texture = new BABYLON.Texture(PARTICLE_BASE64, scene)
    if (component.image){
        let imagePromise = _assetManager.get(component.image, scene).then(tex => {
            particleSystem.particleTexture = texture = tex;
            particleSystem.reset();
        });
    }
    
    particleSystem.emitter = mesh;
    particleSystem.start();
    // mesh.onEnabledStateChangedObservable.add(m => {
    //     setVisibility(m, .5)
    // })
    const updateComponent = (component) => {
        _component = component;
        component.opacity = typeof component.opacity === "undefined" ? 1 : component.opacity;
        mesh.setEnabled(component.visible);

        mesh.position.x = component.position.x
        mesh.position.y = component.position.y
        mesh.position.z = component.position.z

        mesh.scaling = BABYLON.Vector3.Zero();
        mesh.scaling.x = component.scaling.x
        mesh.scaling.y = component.scaling.y
        mesh.scaling.z = component.scaling.z

        mesh.rotation = BABYLON.Vector3.Zero();
        mesh.rotation.x = degreesToRadians(component.rotation.x);
        mesh.rotation.y = degreesToRadians(component.rotation.y);
        mesh.rotation.z = degreesToRadians(component.rotation.z);

        if (particleSystem) {
            switch (component.emitterType.type) {
                case "point":
                    particleSystem.particleEmitterType = new BABYLON.PointParticleEmitter();
                    // particleSystem.particleEmitterType.direction1 = new BABYLON.Vector3(0, 1, 0); 
                    // particleSystem.particleEmitterType.direction2 = new BABYLON.Vector3(0, -1, 0);
                    break;
                case "box":
                    particleSystem.particleEmitterType = new BABYLON.BoxParticleEmitter();
                    let length = (component.emitterType.length || .01)/2;
                    let width = (component.emitterType.width||.01)/2;
                    let height = (component.emitterType.height||.01)/2;
                    //particleSystem.particleEmitterType.direction1 = new BABYLON.Vector3(-5, 2, 1); 
                    //particleSystem.particleEmitterType.direction2 = new BABYLON.Vector3(5, 2, 1);  
                    particleSystem.particleEmitterType.minEmitBox = new BABYLON.Vector3(-length, -height, -width);  
                    particleSystem.particleEmitterType.maxEmitBox = new BABYLON.Vector3(length, height, width);
                    break;
                case "sphere":
                    particleSystem.particleEmitterType = new BABYLON.SphereParticleEmitter();
                    particleSystem.particleEmitterType.radius = component.emitterType.radius || .01;
                    // particleSystem.particleEmitterType.direction1 = new BABYLON.Vector3(-5, 2, 1); 
                    // particleSystem.particleEmitterType.direction2 = new BABYLON.Vector3(5, 2, -1);
                    break;
                case "cylinder":
                    particleSystem.particleEmitterType = new BABYLON.CylinderParticleEmitter();
                    particleSystem.particleEmitterType.radius = component.emitterType.radius || .01;
                    particleSystem.particleEmitterType.radiusRange = component.emitterType.radiusRange || .01;
                    particleSystem.particleEmitterType.height = component.emitterType.height || .01;
                    // particleSystem.particleEmitterType.directionRandomizer = 1;
                    break;
                case "cone":
                    particleSystem.particleEmitterType = new BABYLON.ConeParticleEmitter();
                    particleSystem.particleEmitterType.radius = component.emitterType.radius || .01;
                    particleSystem.particleEmitterType.angle = degreesToRadians(component.emitterType.angle || 1);
                    break;
            }
            particleSystem.particleTexture = texture;
            particleSystem.minSize = component.minSize;
            particleSystem.maxSize = component.maxSize;
            particleSystem.minEmitPower = component.minSpeed;
            particleSystem.maxEmitPower = component.maxSpeed;
            particleSystem.minLifeTime = component.minLifetime;
            particleSystem.maxLifeTime = component.maxLifetime;
            particleSystem.emitRate = component.rate;
            let color1 = new BABYLON.Color3.FromHexString(component.color1).toColor4();
            particleSystem.color1.a = component.opacity;
            particleSystem.color1 = color1
            let color2 = new BABYLON.Color3.FromHexString(component.color2).toColor4();
            particleSystem.color2.a = component.opacity;
            particleSystem.color2 = color2;
            let color3 = new BABYLON.Color3.FromHexString(component.color3).toColor4()
            particleSystem.colorDead.a = component.opacity;
            particleSystem.colorDead = color3;
            particleSystem.textureMask = new BABYLON.Color4(1, 1, 1, component.opacity)

            particleSystem.reset();
        }

        gizmoManager.attachableMeshes.push(mesh);
        mesh.visibility = component.opacity;

        if (parent) {
            mesh.parent = parent;
            if (typeof parent.visibility != "undefined") mesh.visibility *= parent.visibility
        }
        
        return mesh;
    }
    updateComponent(component);

    class ParticlesComponent {
        id = component.id;
        mesh = mesh;
        component = _component;
        gizmoActive = false;
        parent = parent;
        particleSystem = particleSystem;
        dispose = () => {
            const index = gizmoManager.attachableMeshes.indexOf(mesh);
            if (index > -1) {
                gizmoManager.attachableMeshes.splice(index, 1);
            }

            mesh.dispose();
            mesh = undefined;
            particleSystem.dispose();
            particleSystem = undefined;
        }
        update = (component) => {
            updateComponent(component);
            this.mesh = mesh;
            this.component = _component;
        }
        animate = (d) => {
            updateMesh(mesh, component, d.audioFactor, d.currentTime, parent);
        }
    }
    
    return new ParticlesComponent();
}