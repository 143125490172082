let subscribers = {};
export const _frequencyData = {
    publish: (data) => {
        Object.keys(subscribers).forEach(key => {
            subscribers[key](data);
        });
    },
    subscribe: (id, cb) => {
        subscribers[id] = cb;
    },
    unsubscribe: (id) => {
        delete subscribers[id];
    },
}
