import { Paper, Box, Tabs, Tab } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import UserManager from './userManager';
import ProjectManager from './projectManager';

class AdminPage extends React.Component {
    state = {
        tab: "users",
    }

    changeTab = (tab) => {
        this.setState({ tab: tab });
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const tab = params.get('tab') || "users";
        this.setState({ tab: tab });
    }

    render() {
        return (
            <Box p={1} style={{ display: 'flex', flexGrow: 1, height: "90vh", width: "100vw" }}>
                <Tabs
                    style={{ borderRight: `1px solid black`, backgroundColor: "black", height: "100%" }}
                    orientation="vertical"
                    variant="scrollable"
                    value={this.state.tab}
                    onChange={(ev, tab) => this.changeTab(tab)}
                >
                    <Tab label={<span><i className="fas fa-users"></i> Users</span>} value="users" />
                    <Tab label={<span><i className="fas fa-folder-open"></i> Scenes</span>} value="projects" />

                </Tabs>
                {
                    this.state.tab === "users" &&
                    <Paper p={1} style={{ width: "inherit" }}>
                        <UserManager />
                    </Paper>
                }      
                {
                    this.state.tab === "projects" &&
                    <Paper p={1} style={{ width: "inherit" }}>
                        <ProjectManager history={this.props.history} />
                    </Paper>
                }
            </Box>
        );
    }
}


const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(AdminPage);