import React from 'react';
import { ASSET_TYPE_3D, ASSET_TYPE_IMG, GetComponentFields, GetDefaultComponentFieldValue, getDefaultTimeLFO, getDefaultTimeLFOTimes, getDefaultTimeLinkValues } from '../../factories/canvasData';
import { connect } from 'react-redux';
import { addComponent, propChange } from '../../../redux/actions/componentsDataActions'
import SliderInput from '../inputs/slider';
import { Box, Grid, InputLabel } from '@material-ui/core';
import Vector3Input from '../inputs/vector3';
import TextInputBox from '../inputs/textbox';
import SelectInput from '../inputs/selectbox';
import AudioInput from '../inputs/audio';
import DraggableGraph from '../inputs/chart';
import { appSettingChange } from '../../../redux/actions/appSettingsActions';
import { sharedSettingChange } from '../../../redux/actions/sharedActions';
import Material from '../inputs/material';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ToggleButton from '@material-ui/lab/ToggleButton';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import TuneIcon from '@material-ui/icons/Tune';
import TimerIcon from '@material-ui/icons/Timer';
import { roundToHundredth } from '../../utility/math';
import ColorGradient from '../inputs/colorGradient';
import FileInput from '../inputs/file';
import { MOBILE_WIDTH } from '../../constants';
import ParticleEmitterType from '../inputs/particleEmitterType';
import ModelInput from '../inputs/model';
import Physics from '../inputs/physics';

class ComponentEditor extends React.Component {
    state = {
        open: true,
    }
    toggleOpen = () => {
        this.setState({ open: !this.state.open })
    }
    onSelect = (e) => {
        this.setState({ input: e.target.value })
    }
    setValue = (e) => {
        let value = e.target.value;
        if (e.target.type === "number" || e.target.type === "range")
            value = Number(value);

        if (e.target.type === "checkbox")
            value = e.target.checked;

        this.props.propChange(this.props.appSettings.selectedComponentId, e.target.name, value);
    }
    setObjectValue = (name, e) => {
        let propName = e.target.name;
        let propValue = e.target.value;
        if (e.target.type === "number" || e.target.type === "range")
            propValue = Number(propValue);
        if (e.target.type === "checkbox")
            propValue = e.target.checked;
        let component = this.props.components.find(c => c.id === this.props.appSettings.selectedComponentId);
        let value = component[name];
        value[propName] = propValue;
        this.props.propChange(this.props.appSettings.selectedComponentId, name, value);
    }

    audioLinkChange = (name) => {
        let component = this.props.components.find(c => c.id === this.props.appSettings.selectedComponentId);
        if (component.audio.links.includes(name)) {
            component.audio.links.splice(component.audio.links.indexOf(name), 1);
        }
        else {
            if (!component.audio.linkValues[name]) {
                component.audio.linkValues[name] = component[name]
                let field = GetComponentFields(component.type).find(f => f.name == name);
                if (name == "material" && !component[name].colorGradient.length) {
                    component[name].colorGradient = Array(100).fill(component[name].colorHex)
                    component[name].colorGradientInputs = [component[name].colorHex]
                    component[name].colorGradientBreakpoints = [0]
                    this.props.propChange(this.props.appSettings.selectedComponentId, name, component[name]);
                }
                else if (field.type == 'color') {
                    component[name + "ColorGradient"] = Array(100).fill(component[name] || "#FFFFFF")
                    component[name + "ColorGradientInputs"] = [component[name] || "#FFFFFF"]
                    component[name + "ColorGradientBreakpoints"] = [0]
                    this.props.propChange(this.props.appSettings.selectedComponentId, name, component[name]);
                }
            }
            component.audio.links.push(name);
        }
        this.props.propChange(this.props.appSettings.selectedComponentId, 'audio', component.audio);
    }
    audioLinkValueChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (e.target.type === "number" || e.target.type === "range") {
            value = Number(value);
        }
        let component = this.props.components.find(c => c.id === this.props.appSettings.selectedComponentId);
        component.audio.linkValues[name] = value;
        this.props.propChange(this.props.appSettings.selectedComponentId, 'audio', component.audio);
    }

    timeLinkChange = (name) => {
        let component = this.props.components.find(c => c.id === this.props.appSettings.selectedComponentId);
        if (component.time.links.includes(name)) {
            component.time.links.splice(component.time.links.indexOf(name), 1);
        }
        else {
            if (!component.time.lfo[name]) {
                component.time.lfo[name] = getDefaultTimeLFO(name)
                component.time.times[name] = getDefaultTimeLFOTimes(name)
                component.time.linkValues[name] = getDefaultTimeLinkValues(name, component[name]);
                let field = GetComponentFields(component.type).find(f => f.name == name);
                if (name == "material" && !component[name].colorGradient.length) {
                    component[name].colorGradient = Array(100).fill(component[name].colorHex)
                    component[name].colorGradientInputs = [component[name].colorHex]
                    component[name].colorGradientBreakpoints = [0]
                    this.props.propChange(this.props.appSettings.selectedComponentId, name, component[name]);
                }
                else if (field.type == 'color') {
                    component[name + "ColorGradient"] = Array(100).fill(component[name] || "#FFFFFF")
                    component[name + "ColorGradientInputs"] = [component[name] || "#FFFFFF"]
                    component[name + "ColorGradientBreakpoints"] = [0]
                    this.props.propChange(this.props.appSettings.selectedComponentId, name, component[name]);
                }
            }
            component.time.links.push(name);
        }
        this.props.propChange(this.props.appSettings.selectedComponentId, 'time', component.time);
    }
    timeLinkValueChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (e.target.type === "number" || e.target.type === "range") {
            value = Number(value);
        }
        let component = this.props.components.find(c => c.id === this.props.appSettings.selectedComponentId);
        component.time.linkValues[name] = value;
        this.props.propChange(this.props.appSettings.selectedComponentId, 'time', component.time);
    }
    setTimeLinkLFOData = (name, d) => {
        let component = this.props.components.find(c => c.id === this.props.appSettings.selectedComponentId);
        component.time.lfo[name] = d;
        this.props.propChange(this.props.appSettings.selectedComponentId, 'time', component.time);
    }
    setTimeLinkTimes = (name, val) => {
        let component = this.props.components.find(c => c.id === this.props.appSettings.selectedComponentId);
        if (val <= 0) {
            val = .01
        }
        component.time.times[name] = val;
        this.props.propChange(this.props.appSettings.selectedComponentId, 'time', component.time);
    }

    render() {
        const icon = this.state.open ? <span style={{ fontSize: "1em", cursor: "pointer", color: 'white' }} className="material-icons">close</span> : <span style={{ fontSize: "1em", cursor: "pointer", color: 'white' }} className="material-icons">menu</span>
        const isMobileMode = window.innerWidth <= MOBILE_WIDTH;
        let menuWidth = 400;
        if (isMobileMode) {
            menuWidth = window.innerWidth;
        }
        let closeButtonStyle = {
             transform: this.state.open ? `translate(-${menuWidth-50}px)` : "translate(0)", 
             transition: "all 0.6s ease-in-out", 
             position: "absolute", 
             right: "0px", 
             top: 0, 
             width: "50px", 
             height: "50px", 
             textAlign: "center",
        }
        let sideNavStyle = { 
            width: this.state.open ? `${menuWidth}px` : "0px", 
            transition: "all 0.6s ease-in-out", 
            paddingTop: "50px", 
            right: "0", 
            overflowY: "hidden",
            display:"flex",
            flexDirection:"column",
        }

        if (isMobileMode) {
            closeButtonStyle = {
                ...closeButtonStyle,
                left: 0,
                transform: this.state.open ? `translate(${menuWidth-50}px)` : "translate(0)", 
            }
            sideNavStyle = {
                ...sideNavStyle,
                left: 0,
            }
        }
        return (
            <div id="layers-menu" style={{pointerEvents:"auto", border: "1px solid rgb(50,50,50)"}}>
                {isMobileMode || <div onClick={this.toggleOpen} style={closeButtonStyle} className="closebtn">{icon}</div>}
                <div id="mySidenav" className="sidenav" style={sideNavStyle}>
                        {this.renderComponent()}
                </div>
            </div >
        );
    }

    renderComponent() {
        let inputs = this.props.inputs.map((i, ndx) => {
            let name = i.type == "mic" ? i.deviceName : i.audioFileName;
            return {
                name: `${ndx + 1}: ${name}`,
                value: ndx
            }
        });
        let videoInputs = this.props.videoInputs.map((i, ndx) => {
            let name = "";
            switch (i.type) {
                case "webcam": name = i.deviceName; break;
                case "file": name = i.videoFileName; break;
            }
            return {
                name: `${ndx + 1}: ${name}`,
                value: ndx
            }
        });
        let component = this.props.components.find(c => c.id === this.props.appSettings.selectedComponentId);
        if (component) {
            return (
                    <Box style={{ opacity: this.state.open ? "1" : "0", transition: "all 0.3s ease-in-out", height:"100%", display:"flex", flexDirection:"column" }} p={1} pt={0}>
                        <TextInputBox key={"name"} type={"text"} name={"name"} value={component["name"]} onChange={this.setValue} label={"name"} />
                        <hr style={{ margin: "5px" }} />
                        <Tab.Container defaultActiveKey="configure">
                            <Nav justify variant="tabs" style={{ opacity: this.state.open ? "1" : "0", transition: "all 0.3s ease-in-out" }}>
                                <Nav.Item>
                                    <Nav.Link style={{ border: "1px solid rgb(50,50,50)", paddingTop:"5px", paddingBottom:"5px" }} eventKey="configure"><TuneIcon fontSize="small" /> params</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link style={{ border: "1px solid rgb(50,50,50)", paddingTop:"5px", paddingBottom:"5px" }} eventKey="audio"><GraphicEqIcon fontSize="small" /> audio</Nav.Link>
                                </Nav.Item>
                                {
                                    component.animatesWithoutLinks
                                        ? ""
                                        : (
                                            <Nav.Item>
                                                <Nav.Link style={{ border: "1px solid rgb(50,50,50)", paddingTop:"5px", paddingBottom:"5px" }} eventKey="time"><TimerIcon fontSize="small" /> time</Nav.Link>
                                            </Nav.Item>
                                        )
                                }

                            </Nav>
                            <Tab.Content className='scroller' style={{ opacity: this.state.open ? "1" : "0", transition: "all 0.3s ease-in-out", overflow: "auto", height:"100%" }}>
                                <Tab.Pane eventKey="configure">
                                    <Box>
                                        <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                            {GetComponentFields(component.type).map(field => {
                                                switch (field.type) {
                                                    case "vector3": {
                                                        return (
                                                            <Vector3Input key={field.name} name={field.name} value={component[field.name]} step={field.step} min={field.min} max={field.max} icon={field.icon} onChange={this.setObjectValue} gizmo={this.props.appSettings.gizmo} gizmoChange={(value) => this.props.appSettingChange("gizmo", value)} />
                                                        )
                                                    }
                                                    default: {
                                                        return (
                                                            ""
                                                        )
                                                    }
                                                }
                                            })}
                                        </Box>
                                        {GetComponentFields(component.type).map(field => {
                                            switch (field.type) {
                                                case "range": {
                                                    return (
                                                        <SliderInput key={field.name} name={field.name} value={component[field.name]} onChange={this.setValue} min={field.min} max={field.max} step={field.step} label={field.name} />
                                                    )
                                                }
                                                case "select": {
                                                    return (
                                                        <SelectInput key={field.name} name={field.name} value={component[field.name]} onChange={this.setValue} options={field.options.map(fo => { return { name: fo, value: fo } })} label={field.name} />
                                                    )
                                                }
                                                case "material": {
                                                    return (
                                                        <Material key={field.name} name={field.name} value={component[field.name]} onChange={this.setValue} inputs={inputs} label={field.name} userId={this.props.user.userId} componentType={component.type} videoInputs={videoInputs}/>
                                                    )
                                                }
                                                case "physics": {
                                                    return (
                                                        <Physics key={field.name} name={field.name} value={component[field.name]} onChange={this.setValue} inputs={inputs} label={field.name} userId={this.props.user.userId} componentType={component.type} />
                                                    )
                                                }
                                                case "particleEmitterType": {
                                                    return (
                                                        <ParticleEmitterType key={field.name} name={field.name} value={component[field.name]} onChange={this.setValue} label={field.name} componentType={component.type} />
                                                    )
                                                }
                                                case "file": {
                                                    if (field.assetType == ASSET_TYPE_3D) {
                                                        return (<ModelInput key={field.name} type={field.type} name={field.name} value={component[field.name]} onChange={this.setValue} label={field.name} assetType={field.assetType} userId={this.props.user.userId} />)
                                                    }
                                                    else if (field.assetType == ASSET_TYPE_IMG){
                                                        return (<FileInput key={field.name} type={field.type} name={field.name} value={component[field.name]} onChange={this.setValue} label={field.name} assetType={field.assetType} userId={this.props.user.userId} />)
                                                    }
                                                    else {
                                                        return ""
                                                    }
                                                }
                                                case "text":
                                                case "color":
                                                    if (field.name != "name") {
                                                        return (
                                                            <TextInputBox key={field.name} type={field.type} name={field.name} value={component[field.name]} onChange={this.setValue} label={field.name} />
                                                        )
                                                    }
                                                    else {
                                                        return ""
                                                    }          
                                                default: {
                                                    return (
                                                        ""
                                                    )
                                                }
                                            }
                                        })}
                                    </Box>
                                </Tab.Pane>
                                <Tab.Pane eventKey="audio">
                                    <Box p={1}>
                                        <AudioInput key={component.name} name={"audio"} value={component["audio"]} onChange={this.setValue} inputs={inputs} label={"audio"} />
                                        <hr style={{ margin: "5px" }} />
                                        {
                                            component.animatesWithoutLinks
                                                ? ""
                                                : (
                                                    <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                            spacing={1}
                                                        >
                                                            {GetComponentFields(component.type).filter(field => !field.isConstant).map(field => {
                                                                return (
                                                                    <Grid key={field.name} item>
                                                                        <ToggleButton
                                                                            onChange={() => this.audioLinkChange(field.name)}
                                                                            value={component.audio?.links.indexOf(field.name) > -1}
                                                                            selected={component.audio?.links.indexOf(field.name) > -1}
                                                                            size="small">
                                                                            {field.name}
                                                                        </ToggleButton>
                                                                    </Grid>
                                                                )
                                                            })}
                                                        </Grid>
                                                        <hr style={{ margin: "5px" }} />
                                                        {GetComponentFields(component.type).filter(field => component.audio?.links.indexOf(field.name) > -1).map(field => {
                                                            let value = typeof component[field.name] !== 'undefined' ? component[field.name] : GetDefaultComponentFieldValue(component.type, field.name);
                                                            let linkValue = typeof component.audio.linkValues[field.name] === 'undefined' ? value : component.audio.linkValues[field.name];
                                                            switch (field.type) {
                                                                case "range": {
                                                                    return (
                                                                        <SliderInput
                                                                            key={field.name}
                                                                            name={field.name}
                                                                            value={linkValue}
                                                                            min={field.min}
                                                                            max={field.max}
                                                                            step={.01}
                                                                            label={field.name}
                                                                            onChange={this.audioLinkValueChange}
                                                                            marks={[{ value: value, label: roundToHundredth(value) }]} />
                                                                    )
                                                                }
                                                                case "color": {
                                                                    return (
                                                                        <Box key={field.name + "-colorHex"} p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                            <InputLabel style={{ fontSize: "small" }}>{field.name}</InputLabel>
                                                                            <ColorGradient
                                                                                name="colorHex"
                                                                                label="color gradient"
                                                                                output={component[field.name + "ColorGradient"]}
                                                                                input={component[field.name + "ColorGradientInputs"]}
                                                                                breakpoints={component[field.name + "ColorGradientBreakpoints"]}
                                                                                onChange={e => this.setValue({ target: { name: field.name + "ColorGradient", value: e.target.value } })}
                                                                            />
                                                                        </Box>
                                                                    );

                                                                }
                                                                case "material": {
                                                                    return (<Box key={field.name} p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                        <InputLabel style={{ fontSize: "small" }}>{field.name}</InputLabel>
                                                                        {
                                                                            (component['material'].type == 'wireframe' || component['material'].type == 'color' || component['material'].type == 'advanced') ?
                                                                                (
                                                                                    <Box key={field.name + "-colorHex"} p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                                        <InputLabel style={{ fontSize: "small" }}>color</InputLabel>
                                                                                        <ColorGradient
                                                                                            name="colorHex"
                                                                                            label="color gradient"
                                                                                            output={component[field.name].colorGradient}
                                                                                            input={component[field.name].colorGradientInputs}
                                                                                            breakpoints={component[field.name].colorGradientBreakpoints}
                                                                                            onChange={e => this.setValue({ target: { name: field.name, value: { ...component[field.name], colorGradient: e.target.value } } })}
                                                                                        />
                                                                                    </Box>

                                                                                ) : ""
                                                                        }
                                                                        {
                                                                            (component['material'].type == 'wireframe') ?
                                                                                (
                                                                                    <SliderInput
                                                                                        key={field.name}
                                                                                        name="wireframethickness"
                                                                                        min={.01}
                                                                                        max={20}
                                                                                        step={.01}
                                                                                        label="thickness"
                                                                                        value={linkValue.wireframethickness}
                                                                                        onChange={e => this.audioLinkValueChange({ target: { name: field.name, value: { ...linkValue, wireframethickness: Number(e.target.value) } } })}
                                                                                        marks={[{ value: value.wireframethickness, label: roundToHundredth(value.wireframethickness) }]} />
                                                                                ) : ""
                                                                        }
                                                                    </Box>
                                                                    )
                                                                }
                                                                case "vector3": {
                                                                    return (
                                                                        <Box key={field.name} p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                            <InputLabel style={{ fontSize: "small" }}>{field.name}</InputLabel>
                                                                            <SliderInput
                                                                                name={field.name}
                                                                                value={linkValue.x}
                                                                                min={field.min}
                                                                                max={field.max}
                                                                                step={.01}
                                                                                label={"x"}
                                                                                onChange={e => this.audioLinkValueChange({ target: { name: field.name, value: { ...linkValue, x: Number(e.target.value) } } })}
                                                                                marks={[{ value: value.x, label: roundToHundredth(value.x) }]} />
                                                                            <SliderInput
                                                                                name={field.name}
                                                                                value={linkValue.y}
                                                                                min={field.min}
                                                                                max={field.max}
                                                                                step={.01}
                                                                                label={"y"}
                                                                                onChange={e => this.audioLinkValueChange({ target: { name: field.name, value: { ...linkValue, y: Number(e.target.value) } } })}
                                                                                marks={[{ value: value.y, label: roundToHundredth(value.y) }]} />
                                                                            <SliderInput
                                                                                name={field.name}
                                                                                value={linkValue.z}
                                                                                min={field.min}
                                                                                max={field.max}
                                                                                step={.01}
                                                                                label={"z"}
                                                                                onChange={e => this.audioLinkValueChange({ target: { name: field.name, value: { ...linkValue, z: Number(e.target.value) } } })}
                                                                                marks={[{ value: value.z, label: roundToHundredth(value.z) }]} />
                                                                        </Box>
                                                                    )
                                                                }
                                                                default: {
                                                                    return (
                                                                        ""
                                                                    )
                                                                }
                                                            }
                                                        })}
                                                    </Box>
                                                )
                                        }
                                    </Box>
                                </Tab.Pane>
                                <Tab.Pane eventKey="time">
                                    <Box p={1}>
                                        <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                {GetComponentFields(component.type).filter(field => !field.isConstant).map(field => {
                                                    return (
                                                        <Grid key={field.name} item>
                                                            <ToggleButton
                                                                onChange={() => this.timeLinkChange(field.name)}
                                                                value={component.time?.links.indexOf(field.name) > -1}
                                                                selected={component.time?.links.indexOf(field.name) > -1}
                                                                size="small">
                                                                {field.name}
                                                            </ToggleButton>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                            <hr style={{ margin: "5px" }} />
                                            {GetComponentFields(component.type).filter(field => component.time?.links.indexOf(field.name) > -1).map(field => {
                                                let value = typeof component[field.name] !== 'undefined' ? component[field.name] : GetDefaultComponentFieldValue(component.type, field.name);
                                                let linkValue = typeof component.time.linkValues[field.name] === 'undefined' ? value : component.time.linkValues[field.name];
                                                switch (field.type) {
                                                    case "range": {
                                                        return (
                                                            <Box key={field.name} p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                <InputLabel style={{ fontSize: "small" }}>{field.name}</InputLabel>
                                                                <DraggableGraph id={`${field.name}-time-chart`} data={component.time.lfo[field.name]} onChange={(d) => this.setTimeLinkLFOData(field.name, [...d])} xmin={0} xmax={100} ymin={0} ymax={100} loopLength={component.time.times[field.name]} showRenderClock={true} />
                                                                <TextInputBox type="number" name="time" value={component.time.times[field.name]} onChange={(e) => this.setTimeLinkTimes(field.name, Number(e.target.value))} label="time (sec)" min={.01} step={.01} />
                                                                <SliderInput
                                                                    name={field.name}
                                                                    value={linkValue}
                                                                    min={field.min}
                                                                    max={field.max}
                                                                    step={.01}
                                                                    label={"range"}
                                                                    onChange={this.timeLinkValueChange}
                                                                    marks={[{ value: value, label: roundToHundredth(value) }]} />
                                                            </Box>
                                                        )
                                                    }
                                                    case "color": {
                                                        return (
                                                            <Box key={field.name + "-colorHex"} p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                <InputLabel style={{ fontSize: "small" }}>{field.name}</InputLabel>
                                                                <DraggableGraph id={`${field.name}-colorHex-time-chart`} data={component.time.lfo[field.name]} onChange={(d) => { let val = [...d]; this.setTimeLinkLFOData(field.name, val) }} xmin={0} xmax={100} ymin={0} ymax={100} loopLength={component.time.times[field.name]} showRenderClock={true} />
                                                                <TextInputBox type="number" name="time" value={component.time.times[field.name]} onChange={(e) => { let val = Number(e.target.value <= 0 ? .01 : e.target.value); this.setTimeLinkTimes(field.name, val) }} label="time (sec)" min={.01} step={.01} />
                                                                <ColorGradient
                                                                    name="colorHex"
                                                                    label="color gradient"
                                                                    output={component[field.name + "ColorGradient"]}
                                                                    input={component[field.name + "ColorGradientInputs"]}
                                                                    breakpoints={component[field.name + "ColorGradientBreakpoints"]}
                                                                    onChange={e => this.setValue({ target: { name: field.name + "ColorGradient", value: e.target.value } })}
                                                                />
                                                            </Box>
                                                        )
                                                    }
                                                    case "material": {
                                                        return (
                                                            <Box key={field.name} p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                <InputLabel style={{ fontSize: "small" }}>{field.name}</InputLabel>
                                                                {
                                                                    (component['material'].type == 'wireframe' || component['material'].type == 'color' || component['material'].type == 'advanced') ?
                                                                        (
                                                                            <Box key={field.name + "-colorHex"} p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                                <InputLabel style={{ fontSize: "small" }}>color</InputLabel>
                                                                                <DraggableGraph id={`${field.name}-colorHex-time-chart`} data={component.time.lfo[field.name].colorHex} onChange={(d) => { let val = { ...component.time.lfo[field.name], colorHex: [...d] }; this.setTimeLinkLFOData(field.name, val) }} xmin={0} xmax={100} ymin={0} ymax={100} loopLength={component.time.times[field.name].colorHex} showRenderClock={true} />
                                                                                <TextInputBox type="number" name="time" value={component.time.times[field.name].colorHex} onChange={(e) => { let val = { ...component.time.times[field.name], colorHex: Number(e.target.value <= 0 ? .01 : e.target.value) }; this.setTimeLinkTimes(field.name, val) }} label="time (sec)" min={.01} step={.01} />
                                                                                <ColorGradient
                                                                                    name="colorHex"
                                                                                    label="color gradient"
                                                                                    output={component[field.name].colorGradient}
                                                                                    input={component[field.name].colorGradientInputs}
                                                                                    breakpoints={component[field.name].colorGradientBreakpoints}
                                                                                    onChange={e => this.setValue({ target: { name: field.name, value: { ...component[field.name], colorGradient: e.target.value } } })}
                                                                                />
                                                                            </Box>

                                                                        ) : ""
                                                                }
                                                                {
                                                                    (component['material'].type == 'wireframe') ?
                                                                        (
                                                                            <Box key={field.name + "-wireframethickness"} p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                                <InputLabel style={{ fontSize: "small" }}>wireframe thickness</InputLabel>
                                                                                <DraggableGraph id={`${field.name}-wireframethickness-time-chart`} data={component.time.lfo[field.name].wireframethickness} onChange={(d) => { let val = { ...component.time.lfo[field.name], wireframethickness: [...d] }; this.setTimeLinkLFOData(field.name, val) }} xmin={0} xmax={100} ymin={0} ymax={100} loopLength={component.time.times[field.name].wireframethickness} showRenderClock={true} />
                                                                                <TextInputBox type="number" name="time" value={component.time.times[field.name].wireframethickness} onChange={(e) => { let val = { ...component.time.times[field.name], wireframethickness: Number(e.target.value <= 0 ? .01 : e.target.value) }; this.setTimeLinkTimes(field.name, val) }} label="time (sec)" min={.01} step={.01} />
                                                                                <SliderInput
                                                                                    name="wireframethickness"
                                                                                    min={.01}
                                                                                    max={20}
                                                                                    step={.01}
                                                                                    label="thickness"
                                                                                    value={linkValue.wireframethickness}
                                                                                    onChange={e => this.timeLinkValueChange({ target: { name: field.name, value: { ...linkValue, wireframethickness: Number(e.target.value) } } })}
                                                                                    marks={[{ value: value.wireframethickness, label: roundToHundredth(value.wireframethickness) }]} />
                                                                            </Box>

                                                                        ) : ""
                                                                }
                                                            </Box>
                                                        )
                                                    }
                                                    case "vector3": {
                                                        return (
                                                            <Box key={field.name} p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                <InputLabel style={{ fontSize: "small" }}>{field.name}</InputLabel>
                                                                <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                    <InputLabel style={{ fontSize: "small" }}>x</InputLabel>
                                                                    <DraggableGraph id={`${field.name}-x-time-chart`} data={component.time.lfo[field.name].x} onChange={(d) => { let val = { ...component.time.lfo[field.name], x: [...d] }; this.setTimeLinkLFOData(field.name, val) }} xmin={0} xmax={100} ymin={0} ymax={100} loopLength={component.time.times[field.name].x} showRenderClock={true} />
                                                                    <TextInputBox type="number" name="time" value={component.time.times[field.name].x} onChange={(e) => { let val = { ...component.time.times[field.name], x: Number(e.target.value <= 0 ? .01 : e.target.value) }; this.setTimeLinkTimes(field.name, val) }} label="time (sec)" min={.01} step={.01} />
                                                                    <SliderInput
                                                                        name={"x"}
                                                                        value={linkValue.x}
                                                                        min={field.min}
                                                                        max={field.max}
                                                                        step={.01}
                                                                        label={"range"}
                                                                        onChange={e => this.timeLinkValueChange({ target: { name: field.name, value: { ...linkValue, x: Number(e.target.value) } } })}
                                                                        marks={[{ value: value.x, label: roundToHundredth(value.x) }]} />
                                                                </Box>
                                                                <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                    <InputLabel style={{ fontSize: "small" }}>y</InputLabel>
                                                                    <DraggableGraph id={`${field.name}-y-time-chart`} data={component.time.lfo[field.name].y} onChange={(d) => { let val = { ...component.time.lfo[field.name], y: [...d] }; this.setTimeLinkLFOData(field.name, val) }} xmin={0} xmax={100} ymin={0} ymax={100} loopLength={component.time.times[field.name].y} showRenderClock={true} />
                                                                    <TextInputBox type="number" name="time" value={component.time.times[field.name].y} onChange={(e) => { let val = { ...component.time.times[field.name], y: Number(e.target.value <= 0 ? .01 : e.target.value) }; this.setTimeLinkTimes(field.name, val) }} label="time (sec)" min={.01} step={.01} />
                                                                    <SliderInput
                                                                        name={field.name}
                                                                        value={linkValue.y}
                                                                        min={field.min}
                                                                        max={field.max}
                                                                        step={.01}
                                                                        label={"range"}
                                                                        onChange={e => this.timeLinkValueChange({ target: { name: field.name, value: { ...linkValue, y: Number(e.target.value) } } })}
                                                                        marks={[{ value: value.y, label: roundToHundredth(value.y) }]} />
                                                                </Box>
                                                                <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                                                    <InputLabel style={{ fontSize: "small" }}>z</InputLabel>
                                                                    <DraggableGraph id={`${field.name}-z-time-chart`} data={component.time.lfo[field.name].z} onChange={(d) => { let val = { ...component.time.lfo[field.name], z: [...d] }; this.setTimeLinkLFOData(field.name, val) }} xmin={0} xmax={100} ymin={0} ymax={100} loopLength={component.time.times[field.name].z} showRenderClock={true} />
                                                                    <TextInputBox type="number" name="time" value={component.time.times[field.name].z} onChange={(e) => { let val = { ...component.time.times[field.name], z: Number(e.target.value <= 0 ? .01 : e.target.value) }; this.setTimeLinkTimes(field.name, val) }} label="time (sec)" min={.01} step={.01} />
                                                                    <SliderInput
                                                                        name={field.name}
                                                                        value={linkValue.z}
                                                                        min={field.min}
                                                                        max={field.max}
                                                                        step={.01}
                                                                        label={"range"}
                                                                        onChange={e => this.timeLinkValueChange({ target: { name: field.name, value: { ...linkValue, z: Number(e.target.value) } } })}
                                                                        marks={[{ value: value.z, label: roundToHundredth(value.z) }]} />
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
                                                    default: {
                                                        return (
                                                            ""
                                                        )
                                                    }
                                                }
                                            })}
                                        </Box>
                                    </Box>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Box>
            )
        } else {
            return (
                <span style={{ padding: "10px" }}>select an element in the project tree</span>
            )
        }
    }
}

const mapCanvasData = (state) => { return { components: state.components, shared: state.shared, inputs: state.inputs, appSettings: state.appSettings, user: state.user, videoInputs: state.videoInputs } };

const mapDispatch = {
    addComponent: addComponent,
    propChange: propChange,
    sharedSettingChange: sharedSettingChange,
    appSettingChange: appSettingChange,
};

export default connect(mapCanvasData, mapDispatch)(ComponentEditor);