export const loadDefaultShared = () => ({
    type: 'DEFAULT_SHARED',
})

export const loadShared = (shared) => ({
    type: 'LOAD_SHARED',
    shared: shared,
})

export const sharedSettingsChange = (value) => ({
    type: 'SETTINGS_CHANGE',
    value: value,
})

export const sharedSettingChange = (name, value) => ({
    type: 'SETTING_CHANGE',
    name: name,
    value: value,
})
