import '../shaders/babylonShaders';
import * as BABYLON from 'babylonjs';
import { degreesToRadians } from '../../../utility/math';
import { getShapeValue, getAttributePropertyShapeValue, updateBarycentrics } from '../shapes/shapeOptions/utility';
import { bindMeshAndMaterial, getGlowLayer, getMaterial, updateMaterial } from '../material/material';
import { scramble } from '../utility/noise';
import { _assets } from '../../../factories/assets';
import { _assetManager } from '../assets/assetManager';
import { createNoise2D } from 'simplex-noise';

let noise = undefined;
let t = 0;
const getMesh = (scene, component, audioFactor, time) => {
    const width = getShapeValue(component, "width", audioFactor, time)
    const length = getShapeValue(component, "length", audioFactor, time)
    const height = getShapeValue(component, "height", audioFactor, time)
    const subdivisions = getShapeValue(component, "subdivisions", audioFactor, time)
    const noiseOffset = getShapeValue(component, "noiseOffset", audioFactor, time)
    const noiseVelocity = getShapeValue(component, "noiseVelocity", audioFactor, time)
    t+=.001 * noiseVelocity
    const xStep = length / subdivisions;
    const zStep = width / subdivisions;
    const offset =  t + noiseOffset;
    const paths = [];
    for (let l = 0; l < subdivisions; l++) {
        let path = [];
        let clampL = Math.abs(Math.abs((l - ((subdivisions-1)/2))) - ((subdivisions-1)/2))/((subdivisions-1)/2)
        for (let w = 0; w < subdivisions; w++) {
            let clampW = Math.abs(Math.abs((w - ((subdivisions-1)/2))) - ((subdivisions-1)/2))/((subdivisions-1)/2)
            //let factor = Math.abs(Math.abs(l-subdivisions/2) - subdivisions/2);
            let y = noise(w + offset, l);
            y *=  y * height * clampL * clampW;

            
            path.push(new BABYLON.Vector3((xStep*l)-length/2, y, (zStep*w)-width/2));
        }
        paths.push(path);
    }
    
    return BABYLON.MeshBuilder.CreateRibbon(component.id, {pathArray: paths, sideOrientation: 2}, scene);
}

const getShapeProperties = (component) => {
    return [
        "width",
        "length",
        "height",
        "subdivisions",
        "noiseOffset",
        "noiseVelocity",
    ]
}

const createMesh = (scene, component, audioFactor, time, mesh = undefined) => {
    const shapeProperties = getShapeProperties(component);
    let forceUpdateMesh = component.time?.links?.some(l => shapeProperties.indexOf(l) != -1) || component.audio?.links?.some(l => shapeProperties.indexOf(l) != -1)
    forceUpdateMesh |= component.noiseVelocity > 0;
    if (!mesh || forceUpdateMesh) {
        mesh = getMesh(scene, component, audioFactor, time);
        mesh.originalMeshPositions = [...mesh.getVerticesData("position")];
    }
    mesh.isVisible = component.visible;

    mesh.position.x = getAttributePropertyShapeValue(component, 'position', 'x', audioFactor, time)
    mesh.position.y = getAttributePropertyShapeValue(component, 'position', 'y', audioFactor, time)
    mesh.position.z = getAttributePropertyShapeValue(component, 'position', 'z', audioFactor, time)

    mesh.scaling = BABYLON.Vector3.Zero();
    mesh.scaling.x = getAttributePropertyShapeValue(component, 'scaling', 'x', audioFactor, time)
    mesh.scaling.y = getAttributePropertyShapeValue(component, 'scaling', 'y', audioFactor, time)
    mesh.scaling.z = getAttributePropertyShapeValue(component, 'scaling', 'z', audioFactor, time)

    mesh.rotation = BABYLON.Vector3.Zero();
    mesh.rotation.x = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'x', audioFactor, time))
    mesh.rotation.y = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'y', audioFactor, time))
    mesh.rotation.z = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'z', audioFactor, time))

    return mesh;
}

export const buildTerrain = (scene, gizmoManager, component, parent, getVideoInputs) => {
    let _component;
    let mesh;
    let color;
    let glowColor;
    let image;
    let bumpMap;
    let material;
    let glowLayer;
    let physics;
    let videoTexture = { ref: undefined };

    noise = createNoise2D();

    const setMaterialCallback = () => {
        if (material) {
            material.dispose();
        }
        material = getMaterial(scene, parent, component, color, image, bumpMap, videoTexture, getVideoInputs);
        bindMeshAndMaterial(component, mesh, material, glowLayer);
    }
    const updateComponent = (component, audioFactor) => {
        _component = component;
        color = new BABYLON.Color3.FromHexString(component.material.colorHex).toColor4();
        glowColor = new BABYLON.Color3.FromHexString(component.glowColor || component.material.colorHex).toColor4();
        color.a = component.opacity;
        glowColor.a = component.opacity;
        mesh = createMesh(scene, component, audioFactor);
        if (component.physics?.enable) {
            physics = new BABYLON.PhysicsAggregate(mesh, BABYLON.PhysicsShapeType.CONVEX_HULL, { mass: component.physics?.mass || 0, friction: component.physics?.friction || 0, restitution: component.physics?.restitution || 0 }, scene);
            physics.body.disablePreStep = false;
        }
        glowLayer = getGlowLayer(scene, component, mesh, glowColor);
        let morph = getShapeValue(component, 'morph');
        if (morph) {
            mesh.updateMeshPositions(scramble(morph, mesh))
        }
        gizmoManager.attachableMeshes.push(mesh);
        if (parent) {
            mesh.parent = parent;
            if (typeof parent.visibility != "undefined") mesh.visibility = parent.visibility;
        }

        let materialPromises = [];
        if ((component.material.type == "image" || component.material.type == "advanced") && component.material.image) {         
            let imagePromise = _assetManager.get(component.material.image, scene).then(texture => {
                image = texture;
            });
            materialPromises.push(imagePromise);
        }
        if (component.material.type == "advanced" && component.material.bumpMap) {
           let bumpMapPromise = _assetManager.get(component.material.bumpMap, scene).then(texture => {
                bumpMap = texture;
            });
            materialPromises.push(bumpMapPromise);
        }

        Promise.all(materialPromises).then(setMaterialCallback);
    }
    updateComponent(component);

    class ShapeComponent {
        id = component.id;
        mesh = mesh;
        component = _component;
        gizmoActive = false;
        parent = parent;
        updateMesh = (mesh, audioFactor, time) => {
            if (component.physics?.enable) {
                physics = new BABYLON.PhysicsAggregate(mesh, BABYLON.PhysicsShapeType.CONVEX_HULL, { mass: component.physics?.mass || 0, friction: component.physics?.friction || 0, restitution: component.physics?.restitution || 0 }, scene);
                physics.body.disablePreStep = false;
            }
            glowLayer.removeIncludedOnlyMesh(this.mesh);
            glowLayer.unReferenceMeshFromUsingItsOwnMaterial(this.mesh);
            const index = gizmoManager.attachableMeshes.indexOf(this.mesh);
            if (index > -1) {
                gizmoManager.attachableMeshes.splice(index, 1);
            }

            if (this.mesh != mesh) {
                this.mesh.dispose();
            }

            bindMeshAndMaterial(component, mesh, material, glowLayer, true);
            glowLayer.addIncludedOnlyMesh(mesh);
            let morph = getShapeValue(component, 'morph', audioFactor, time);
            if (morph) {
                mesh.updateMeshPositions(scramble(morph, mesh))
            }
            gizmoManager.attachableMeshes.push(mesh);
            if (parent) {
                mesh.parent = parent;
                if (typeof parent.visibility != "undefined") mesh.visibility = parent.visibility;
            }

            this.mesh = mesh;
        }
        dispose = () => {
            const index = gizmoManager.attachableMeshes.indexOf(mesh);
            if (index > -1) {
                gizmoManager.attachableMeshes.splice(index, 1);
            }
            if (videoTexture.ref) {
                videoTexture.ref.dispose();
                videoTexture.ref = undefined;
            }
            mesh.parent = null;
            mesh.dispose();
            if (material) {
                material.dispose();
            }
            if (physics) {
                physics.dispose();
                physics = undefined;
            }
            glowLayer.dispose();
        }
        update = (component) => {
            this.dispose();
            updateComponent(component);
            this.mesh = mesh;
            this.component = _component;
        }
        animate = (data) => {
            updateMaterial(component, material, glowLayer, data.audioFactor, data.currentTime, parent);
            mesh = createMesh(scene, component, data.audioFactor, data.currentTime, this.mesh);
            this.updateMesh(mesh, data.audioFactor, data.currentTime);
        }
    }

    return new ShapeComponent();
}