
import { GetComponentDefaultFieldValues, GetDefaultGroundComponent, GROUND_TYPE } from '../../app/factories/canvasData';
import { v4 as uuidv4 } from 'uuid';
import { _venueHistory } from '../../services/venueHistoryService';

export const COMPONENTS_STORAGE_KEY = "exec-components-settings";
let SAVE_SNAPSHOT_TRIGGER = false;

class DirtyComponents {
    add = [];
    delete = [];
    update = [];
    shouldReorder = false;
    shouldClearCanvas = false;
    clear = () => {
        this.add = [];
        this.delete = [];
        this.update = [];
        this.shouldReorder = false;
        this.shouldClearCanvas = false;
    }
}
export const dirtyComponents = new DirtyComponents();

export const getDefaultComponentsSettings = () => {
    return [
        {
            ...GetDefaultGroundComponent(GROUND_TYPE),
        }, 
    ]
}

const init = () => {
    let saved = sessionStorage.getItem(COMPONENTS_STORAGE_KEY);
    if (saved)
        saved = JSON.parse(saved);
    return saved || getDefaultComponentsSettings();
}

const gatherChildren = (state, arr, id) => {
    const children = state.filter(c => c.parentId == id);
    for (let child of children) {
        gatherChildren(state, arr, child.id);
    }
    arr.push(id);
}

const gatherParentsFirst = (state, arr, id) => {
    if (state.some(c => c.id == id)) {
        // wont be any if id of 0 (global parent)
        arr.push(id);
    }
    const children = state.filter(c => c.parentId == id);
    for (let child of children) {
        gatherParentsFirst(state, arr, child.id);
    }
}
const reduce = (state, action) => {
    SAVE_SNAPSHOT_TRIGGER = true;
    switch (action.type) {
        case 'DEFAULT_COMPONENTS':
            action.components = getDefaultComponentsSettings();
        case 'LOAD_COMPONENTS':
            action.components = action.components || getDefaultComponentsSettings();
            dirtyComponents.shouldClearCanvas = true;
            var toAdd = [];
            gatherParentsFirst(action.components, toAdd, 0);
            dirtyComponents.add = toAdd;
            return [
                ...action.components
            ];
        case 'ADD_COMPONENT':
            var component = GetComponentDefaultFieldValues(action.cType)
            dirtyComponents.add.push(component.id);
            return [
                {
                    ...component,
                },
                ...state,
            ];
        case 'ADD_DEFINED_COMPONENTS':
            var toAdd = [];
            gatherParentsFirst(action.components, toAdd, 0);
            dirtyComponents.add = toAdd;
            action.components.forEach(c => {
                state = [
                    {
                        ...c,
                    },
                    ...state,
                ]
            })
            return state;
        case 'DELETE_COMPONENT':
            const toDelete = [];        
            gatherChildren(state, toDelete, action.id);
            dirtyComponents.delete = toDelete;
            state = state.filter(c => toDelete.indexOf(c.id) == -1);
            return [
                ...state,
            ];
        case 'REORDER_COMPONENTS':
            dirtyComponents.shouldReorder = true;
            state = action.components;
            return [
                ...state,
            ];
        case 'PROP_CHANGE':
            const toUpdate = [];
            gatherChildren(state, toUpdate, action.id);
            toUpdate.forEach(u => dirtyComponents.update.unshift(u));
            var component = state.find(c => c.id === action.id);
            if (component)
                component[action.name] = action.value;
            return [
                ...state,
            ];
        case 'COPY_COMPONENT':
            var toAdd = [];
            const copyChildren = (comp, parentId) => {
                let component = JSON.parse(JSON.stringify(comp))
                component.id = uuidv4();
                component.parentId = parentId;
                toAdd.push(component);
                const children = state.filter(c => c.parentId == comp.id);
                for (let child of children) {
                    copyChildren(child, component.id);
                }
            }
            copyChildren(action.component, action.component.parentId);

            for (let c of toAdd){
                dirtyComponents.add.push(c.id);
                state = [...state, c];
            }
            
            return [
                ...state,
            ];
        default:
            SAVE_SNAPSHOT_TRIGGER = false;
            return state
    }
}

const componentsReducer = (state = init(), action) => {
    state = reduce(state, action);
    if (SAVE_SNAPSHOT_TRIGGER) {
        SAVE_SNAPSHOT_TRIGGER = false;
        _venueHistory.componentsSnapshot(state);
        sessionStorage.setItem(COMPONENTS_STORAGE_KEY, JSON.stringify(state));
    }
    
    return state;
}

export default componentsReducer