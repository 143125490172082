import * as BABYLON from 'babylonjs';
import { buildBars } from './components/bars/bars';
import { BARS_TYPE, WAVEFORM_TYPE, BOX_TYPE, FFTSIZE, SPHERE_TYPE, CYLINDER_TYPE, CAPSULE_TYPE, TORUS_TYPE, TORUSKNOT_TYPE, POLYHEDRON_TYPE, PLANE_TYPE, DISC_TYPE, GROUND_TYPE, TEXT_TYPE, GROUP_TYPE, MODEL_TYPE, ICOSPHERE_TYPE, PARTICLES_TYPE, TERRAIN_TYPE, LAYER_TYPE } from '../factories/canvasData';
import { dirtyComponents } from '../../redux/reducers/componentsReducer';
import { buildWaveform } from './components/waveform/waveform';
import { degreesToRadians, radiansToDegrees } from '../utility/math';
import { buildShape } from './components/shapes/shape';
import { buildText } from './components/text/text';
import { buildGroup } from './components/group/group';
import { buildModel } from './components/model/model';
import { _assets } from '../factories/assets';
import { _renderClock } from '../factories/renderClock';
import { _frequencyData } from '../factories/frequencyData';
import { _timeDomain } from '../factories/timeDomainData';
import { buildParticles } from './components/particles/particles';
import { buildTerrain } from './components/terrain/terrain';

export default class Universe {
    scene = undefined;
    engine = undefined;
    gizmoManager = undefined;
    camera = undefined;
    light = undefined;
    renderedComponents = [];
    userPreferences = undefined;
    appSettingChange = undefined;
    componentPropChange = undefined;
    getVideoInputs = undefined;
    constructor(userPreferences, engine, appSettingChange, getAppSettings, componentPropChange, getVideoInputs, scene, camera) {
        const _this = this;
        _this.getVideoInputs = getVideoInputs;
        _this.userPreferences = userPreferences;
        _this.appSettingChange = appSettingChange;
        _this.componentPropChange = componentPropChange;
        _this.getAppSettings = getAppSettings;
        _this.scene = scene;
        _this.camera = camera;
        var options = new BABYLON.SceneOptimizerOptions(_this.userPreferences.fps, 0);
        options.addOptimization(new BABYLON.HardwareScalingOptimization(0, 1));
        var optimizer = new BABYLON.SceneOptimizer(_this.scene, options);

        _this.light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(1, 1, 0), _this.scene);

        _this.gizmoManager = new BABYLON.GizmoManager(_this.scene, 3, new BABYLON.UtilityLayerRenderer(_this.scene))
        _this.gizmoManager.enableAutoPicking = false; ////////////
        _this.gizmoManager.boundingBoxGizmoEnabled = false;
        _this.gizmoManager.positionGizmoEnabled = false;
        _this.gizmoManager.rotationGizmoEnabled = false;
        _this.gizmoManager.scaleGizmoEnabled = false;
        // _this.gizmoManager.boundingBoxDragBehavior.rotateDraggedObject = false;
        _this.gizmoManager.attachableMeshes = [];
        _this.gizmoManager.onAttachedToMeshObservable.add((m) => {
            if (_this.activeGizmoRenderedComponent) {
                // if currently moving something via a gizmo don't select anything else
                let rc = _this.renderedComponents.find(rc => rc.id == m?.id);
                if (rc && _this.activeGizmoRenderedComponent != rc) {
                    _this.gizmoManager.attachToMesh(undefined);
                }
            }
            else {
                const id = m?.id;
                if (id && id.includes("-child")) {
                    let wrapperId = id.replace("-child", "");
                    let rc = _this.renderedComponents.find(rc => rc.id == wrapperId);
                    _this.gizmoManager.attachToMesh(rc.mesh);
                }
                else if (id && _this.getAppSettings().selectedComponentId != id) {
                    _this.appSettingChange('selectedComponentId', id)
                }
            }
        })
    }

    setCamera = (camera) => {
        this.camera = camera;
    }

    dispose = () => {
        
    }

    render = () => {
        const _this = this;
        _this.scene.render();
    }

    updateGizmos = (renderedComponent, appSettings) => {
        const _this = this;
        if (renderedComponent) {
            if (appSettings.selectedComponentId == renderedComponent.id) {
                _this.gizmoManager.attachToMesh(renderedComponent.mesh)
            }
            _this.gizmoManager.positionGizmoEnabled = appSettings.gizmo == "position";
            _this.gizmoManager.rotationGizmoEnabled = appSettings.gizmo == "rotation";
            _this.gizmoManager.scaleGizmoEnabled = appSettings.gizmo == "scaling";
            if (_this.gizmoManager.positionGizmoEnabled) {
                _this.gizmoManager.gizmos.positionGizmo.updateGizmoRotationToMatchAttachedMesh = false;
                _this.gizmoManager.gizmos.positionGizmo.onDragStartObservable.add(() => {
                    const id = _this.gizmoManager.gizmos.positionGizmo.attachedMesh.id;
                    const renderedComponent = _this.renderedComponents.find(rc => rc.id == id);
                    renderedComponent.gizmoActive = true;
                    _this.activeGizmoRenderedComponent = renderedComponent;
                    _this.gizmoManager.attachToMesh(renderedComponent.mesh)
                    _this.gizmoManager.gizmos.positionGizmo.onDragStartObservable.clear();
                    //_this.camera.detachControl();
                })
                _this.gizmoManager.gizmos.positionGizmo.onDragEndObservable.add(() => {
                    const id = _this.gizmoManager.gizmos.positionGizmo.attachedMesh.id;
                    const renderedComponent = _this.renderedComponents.find(rc => rc.id == id);
                    let position = { ...renderedComponent.component.position };
                    position.x = renderedComponent.mesh.position.x;
                    position.y = renderedComponent.mesh.position.y;
                    position.z = renderedComponent.mesh.position.z;
                    _this.gizmoManager.gizmos.positionGizmo.onDragEndObservable.clear();
                    renderedComponent.gizmoActive = false;
                    _this.activeGizmoRenderedComponent = undefined;
                    _this.componentPropChange(id, "position", position);
                    //_this.camera.attachControl(_this.canvas, true);
                })
            }
            else if (_this.gizmoManager.rotationGizmoEnabled) {
                _this.gizmoManager.gizmos.rotationGizmo.updateGizmoRotationToMatchAttachedMesh = false;
                _this.gizmoManager.gizmos.rotationGizmo.onDragStartObservable.add(() => {
                    const id = _this.gizmoManager.gizmos.rotationGizmo.attachedMesh.id;
                    const renderedComponent = _this.renderedComponents.find(rc => rc.id == id);
                    renderedComponent.gizmoActive = true;
                    _this.activeGizmoRenderedComponent = renderedComponent;
                    _this.gizmoManager.attachToMesh(renderedComponent.mesh)
                    _this.gizmoManager.gizmos.rotationGizmo.onDragStartObservable.clear();
                    //_this.camera.detachControl();
                })
                _this.gizmoManager.gizmos.rotationGizmo.onDragEndObservable.add(() => {
                    const id = _this.gizmoManager.gizmos.rotationGizmo.attachedMesh.id;
                    const renderedComponent = _this.renderedComponents.find(rc => rc.id == id);
                    let rotation = { ...renderedComponent.component.rotation };
                    rotation.x = radiansToDegrees(renderedComponent.mesh.rotation.x);
                    rotation.y = radiansToDegrees(renderedComponent.mesh.rotation.y);
                    rotation.z = radiansToDegrees(renderedComponent.mesh.rotation.z);
                    _this.gizmoManager.gizmos.rotationGizmo.onDragEndObservable.clear();
                    renderedComponent.gizmoActive = false;
                    _this.activeGizmoRenderedComponent = undefined;
                    _this.componentPropChange(id, "rotation", rotation);
                    //_this.camera.attachControl(_this.canvas, true);
                })
            }
            else if (_this.gizmoManager.scaleGizmoEnabled) {
                _this.gizmoManager.gizmos.scaleGizmo.updateGizmoRotationToMatchAttachedMesh = false;
                _this.gizmoManager.gizmos.scaleGizmo.onDragStartObservable.add(() => {
                    const id = _this.gizmoManager.gizmos.scaleGizmo.attachedMesh.id;
                    const renderedComponent = _this.renderedComponents.find(rc => rc.id == id);
                    renderedComponent.gizmoActive = true;
                    _this.activeGizmoRenderedComponent = renderedComponent;
                    _this.gizmoManager.attachToMesh(renderedComponent.mesh)
                    _this.gizmoManager.gizmos.scaleGizmo.onDragStartObservable.clear();
                    //_this.camera.detachControl();
                })
                _this.gizmoManager.gizmos.scaleGizmo.onDragEndObservable.add(() => {
                    const id = _this.gizmoManager.gizmos.scaleGizmo.attachedMesh.id;
                    const renderedComponent = _this.renderedComponents.find(rc => rc.id == id);
                    let scaling = { ...renderedComponent.component.scaling };
                    scaling.x = renderedComponent.mesh.scaling.x;
                    scaling.y = renderedComponent.mesh.scaling.y;
                    scaling.z = renderedComponent.mesh.scaling.z;
                    _this.gizmoManager.gizmos.scaleGizmo.onDragEndObservable.clear();
                    renderedComponent.gizmoActive = false;
                    _this.activeGizmoRenderedComponent = undefined;
                    _this.componentPropChange(id, "scaling", scaling);
                    //_this.camera.attachControl(_this.canvas, true);
                })
            }
        }
    }

    renderComponents = (parentId, components) => {
        const _this = this;
        components.filter(c => c.parentId == parentId).forEach(c => {
            _this.renderComponent(c);
            _this.renderComponents(c.id, components);
        })
    }

    renderComponent = (c) => {
        let _this = this;
        let res;
        let parent = _this.renderedComponents.find(rc => rc.component.id == c.parentId)?.mesh;
        switch (c.type) {
            case LAYER_TYPE:
            case GROUP_TYPE: {
                res = buildGroup(_this.scene, _this.gizmoManager, c, parent);
                break;
            }
            case MODEL_TYPE: {
                res = buildModel(_this.scene, _this.gizmoManager, c, parent, _this.getVideoInputs);
                break;
            }
            case BARS_TYPE: {
                res = buildBars(_this.scene, _this.gizmoManager, c, parent, _this.getVideoInputs);
                break;
            }
            case WAVEFORM_TYPE: {
                res = buildWaveform(_this.scene, _this.gizmoManager, c, parent);
                break;
            }
            case TEXT_TYPE: {
                res = buildText(_this.scene, _this.gizmoManager, c, parent, _this.getVideoInputs);
                break;
            }
            case PARTICLES_TYPE: {
                res = buildParticles(_this.scene, _this.gizmoManager, c, parent, _this.getVideoInputs);
                break;
            }
            case TERRAIN_TYPE: {
                res = buildTerrain(_this.scene, _this.gizmoManager, c, parent, _this.getVideoInputs);
                break;
            }
            case PLANE_TYPE:
            case DISC_TYPE:
            case GROUND_TYPE:
            case CYLINDER_TYPE:
            case CAPSULE_TYPE:
            case TORUS_TYPE:
            case TORUSKNOT_TYPE:
            case POLYHEDRON_TYPE:
            case SPHERE_TYPE:
            case ICOSPHERE_TYPE:
            case BOX_TYPE: {
                res = buildShape(_this.scene, _this.gizmoManager, c, parent, _this.getVideoInputs);
                break;
            }

            default: return undefined;
        }

        _this.renderedComponents.push(res);
        return res;
    }

    updateDirtyComponents = (components, appSettings) => {
        const _this = this;
        if (dirtyComponents.shouldClearCanvas) {
            _this.renderedComponents.forEach(renderedComponent => {
                renderedComponent.dispose();
            })
            _this.renderedComponents = [];
            _this.updateGizmos(undefined, appSettings);
        }
        dirtyComponents.add.forEach(cId => {
            const component = components.find(c => c.id == cId);
            const renderedComponent = _this.renderComponent(component);
            _this.updateGizmos(renderedComponent, appSettings);
            _this.appSettingChange('selectedComponentId', component.id)
        });
        dirtyComponents.delete.forEach(cId => {
            const renderedComponent = _this.renderedComponents.find(c => c.id == cId);
            if (renderedComponent) {
                renderedComponent.dispose();
            }
            _this.updateGizmos(undefined, appSettings);
            _this.renderedComponents = _this.renderedComponents.filter(rc => rc !== renderedComponent)
        });
        dirtyComponents.update.forEach(cId => {
            const component = components.find(c => c.id == cId);
            const renderedComponent = _this.renderedComponents.find(c => c.id == cId);
            renderedComponent.update(component);
            let rc = _this.renderedComponents.find(rc => rc.component.id == appSettings.selectedComponentId);
            _this.updateGizmos(rc, appSettings);
        });
        if (dirtyComponents.shouldReorder) {
            _this.renderedComponents.forEach(rc => rc.dispose());
            _this.renderedComponents = [];
            _this.renderComponents(0, components);
            if (appSettings.selectedComponentId) {
                let rc = _this.renderedComponents.find(rc => rc.component.id == appSettings.selectedComponentId);
                _this.updateGizmos(rc, appSettings);
            }
        }

        dirtyComponents.clear();
    }
}