import React from 'react';
import { Box, Button, Fab, FormControl, Grid, InputLabel, LinearProgress } from '@material-ui/core'
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import { download, getFileExtension } from '../../utility/utility';
import { GetSupportedAssetTypes } from '../../factories/canvasData';
import CloseIcon from '@material-ui/icons/Close';
import { _assets } from '../../factories/assets';
import GetAppIcon from '@material-ui/icons/GetApp';

class ModelInput extends React.Component {
    _3DViewer = undefined;
    state = {
        id: "",
        name: "",
        extension: "",
        base64: undefined,
        loading: false,
    };
    fileUpload = (e) => {
        let value = e.target.files;
        if (!this.props.userId) {
            alert("Please login to upload files");
            return;
        }
        if (e.target.files.length == 1) {
            const supportedFileTypes = GetSupportedAssetTypes(this.props.assetType);
            var file = value[0];
            let fileExtension = getFileExtension(file.name);
            if (supportedFileTypes.includes(fileExtension)) {
                file.assetType = this.props.assetType;
                file.extension = fileExtension;
                this.setState({ assetLoading: true });
                _assets.create(file).then(res => {
                    this.props.onChange({ target: { name: this.props.name, value: res.id } })
                }).catch(e => {
                    alert("Failed to upload asset.. please try again");
                    this.setState({ assetLoading: false });
                })
            } else {
                alert(`${file.name} is not supported. Please upload a file of type: (${supportedFileTypes.join(', ')})`)
            }
        }
    }
    load3dViewer = (base64) => {
        const _this = this;
        if (_this._3DViewer) {
            _this._3DViewer.dispose();
        }
        console.log(base64);
        const viewerDOM = document.getElementById(this.getAssetViewerId());
        if (viewerDOM) {
            viewerDOM.innerHTML = "";
            const canvas = document.createElement("canvas");
            canvas.style.width = "100%";
            canvas.style.borderRadius = "5px";
            viewerDOM.appendChild(canvas)
            canvas.addEventListener('wheel', e => { e.preventDefault(); e.stopPropagation() });
            _this._3DViewer = new BABYLON.Engine(canvas, true);
            const scene = new BABYLON.Scene(_this._3DViewer);
            scene.clearColor = new BABYLON.Color3(0, 0, 0)
            const camera = new BABYLON.ArcRotateCamera("camera", -Math.PI / 2, Math.PI / 2.5, 3, new BABYLON.Vector3(0, 0, 0));
            camera.useAutoRotationBehavior = true;
            camera.attachControl(canvas, true);
            camera.wheelPrecision = 100;
            camera.autoRotationBehavior.idleRotationSpeed = .5;
            const light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0));
            BABYLON.SceneLoader.LoadAssetContainer(
                "",
                base64,
                scene,
                (container) => {
                    let meshes = container.meshes;
                    let wrapperMesh = BABYLON.MeshBuilder.CreateBox("", { size: 1 }, scene);
                    let masterBoundingInfo = meshes[0].getBoundingInfo();
                    for (let i = 0; i < meshes.length; i++) {
                        let mesh = meshes[i];
                        let boundingInfo = mesh.getBoundingInfo();
                        masterBoundingInfo.encapsulateBoundingInfo(boundingInfo)
                        wrapperMesh.addChild(mesh);
                    }
                    let boundingBox = masterBoundingInfo.boundingBox;
                    wrapperMesh.isVisible = false;
                    let avg = ((boundingBox.maximum.x - boundingBox.minimum.x)
                        + (boundingBox.maximum.y - boundingBox.minimum.y)
                        + (boundingBox.maximum.z - boundingBox.minimum.z)) / 3;
                    let scale = 1.5 / avg;
                    wrapperMesh.scaling = BABYLON.Vector3.Zero();
                    wrapperMesh.scaling.x = scale
                    wrapperMesh.scaling.y = scale
                    wrapperMesh.scaling.z = scale
                    let center = boundingBox.centerWorld;
                    wrapperMesh.locallyTranslate(new BABYLON.Vector3(-center.x, -center.y, -center.z));
                    container.addAllToScene();
                },
                undefined,
                undefined,
                `.${_this.state.extension}`
            )
            _this._3DViewer.runRenderLoop(function () {
                scene.render();
            });
        }

    }
    componentWillReceiveProps(nextProps) {
        this.updateProps(nextProps)
    }
    componentDidMount() {
        this.updateProps(this.props)
    }
    updateProps = (nextProps) => {
        const _this = this;
        if (nextProps.value) {
            if (_this.state.id != nextProps.value) {
                _this.setState({ assetLoading: true });
                _assets.get(nextProps.value).then(asset => {
                    if (asset) {
                        _this.setState({ assetLoading: false });
                        if (asset) {
                            _this.setState({ id: asset.id, name: asset.name, base64: asset.base64, extension: asset.extension })
                            _this.load3dViewer(asset.base64);
                        }
                    }
                })
            }
        }
        else {
            _this.setState({
                info: {
                    name: ""
                },
                loading: false,
            })
            if (_this._3DViewer) {
                _this._3DViewer.dispose();
            }
            const viewerDOM = document.getElementById(this.getAssetViewerId());
            if (viewerDOM) {
                viewerDOM.innerHTML = "";
            }
        }
    }
    getAssetViewerId = () => {
        return `asset-viewer-${this.props.name}`;
    }
    delete = () => {
        this.props.onChange({ target: { name: this.props.name, value: undefined } });
        this.setState({ id: undefined, name: undefined, base64: undefined, extension: undefined })
    }
    download = () => {
        download(this.state.base64, this.state.name);
    }

    render() {
        const closeBtnStyle = { outline: "none", width: "20px", height: "20px", minHeight: "20px", marginBottom: ".5rem", marginLeft:"5px" }
        const btnText = `upload file (${GetSupportedAssetTypes(this.props.assetType).join(", ")})`
        return (
            <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                <InputLabel style={{ fontSize: "small" }}>{this.props.name}</InputLabel>
                <FormControl fullWidth>
                    <Button
                        style={{ height: "30px" }}
                        variant="contained"
                        component="label"
                    >
                        {<i class="fas fa-file"></i>}&nbsp;{btnText}
                        <input
                            type="file"
                            onChange={this.fileUpload}
                            hidden
                        />
                    </Button>
                </FormControl>
                <hr />
                {!this.state.name ||
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <InputLabel style={{ fontSize: "small" }}>{this.state.name}</InputLabel>
                        </Grid>
                        <Grid item>
                            <Fab style={closeBtnStyle} onClick={this.download}><GetAppIcon /></Fab>
                            <Fab style={closeBtnStyle} onClick={this.delete}><CloseIcon /></Fab>
                        </Grid>
                    </Grid>
                }
                {!this.state.assetLoading ? "" : <LinearProgress />}
                <div id={this.getAssetViewerId()}></div>
            </Box>
        );
    }
}
export default ModelInput;