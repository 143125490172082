import * as BABYLON from 'babylonjs';
import { getShapeValue } from "./utility";

export const torusknotProperties = [
    "radius",
    "thickness",
    "radialSegments",
    "tubularSegments",
    "twist1",
    "twist2",
]
export const torusknot = (scene, component, audioFactor, time) => {
    let options = {
        radius: getShapeValue(component, 'radius', audioFactor, time),
        tube: getShapeValue(component, 'thickness', audioFactor, time),
        radialSegments: Math.floor(getShapeValue(component, 'radialSegments', audioFactor, time)),
        tubularSegments: Math.floor(getShapeValue(component, 'tubularSegments', audioFactor, time)),
        p: getShapeValue(component, 'twist1', audioFactor, time),
        q: getShapeValue(component, 'twist2', audioFactor, time),
        updatable: true,
    }

    return BABYLON.MeshBuilder.CreateTorusKnot(component.id, options, scene);
}