import * as BABYLON from 'babylonjs';
import { getShapeValue } from "./utility";

export const boxProperties = [
    "height",
    "width",
    "depth",
]
export const box = (scene, component, audioFactor, time) => {
    let options = {
        height: getShapeValue(component, 'height', audioFactor, time),
        width: getShapeValue(component, 'width', audioFactor, time),
        depth: getShapeValue(component, 'depth', audioFactor, time),
        updatable: true,
    }

    return BABYLON.MeshBuilder.CreateBox(component.id, options, scene);
}