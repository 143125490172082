export const getShapeValue = (component, field, audioFactor, time, baseValue = undefined, forceUpperBound = undefined) => {
    baseValue = typeof baseValue !== "undefined" ?  baseValue : component[field];
    let value = baseValue;
    if (component.time.links.includes(field) && typeof time !== "undefined") {
        let loopTime = component.time.times[field];
        let lfoData = component.time.lfo[field];
        let upperBound = forceUpperBound || component.time.linkValues[field];
        let lowerBound = baseValue;
        value += getTimeValue(time, loopTime, lfoData, upperBound, lowerBound);
    }

    if (component.audio.links.includes(field) && typeof audioFactor !== "undefined") {
        let upperBound = forceUpperBound || component.audio.linkValues[field];
        let lowerBound = baseValue;
        value += getAudioValue(audioFactor, upperBound, lowerBound);
    }

    return value;
}

export const getAttributePropertyShapeValue = (component, field, property, audioFactor, time, baseValue = undefined, forceUpperBound = undefined) => {
    baseValue = typeof baseValue !== "undefined" ?  baseValue : component[field][property];
    let value = baseValue;
    if (component.time.links.includes(field) && typeof time !== "undefined") {
        let loopTime = component.time.times[field][property];
        let lfoData = component.time.lfo[field][property];
        let upperBound = forceUpperBound || component.time.linkValues[field][property];
        let lowerBound = baseValue;
        value += getTimeValue(time, loopTime, lfoData, upperBound, lowerBound);
    }

    if (component.audio.links.includes(field) && typeof audioFactor !== "undefined") {
        let upperBound =  forceUpperBound || component.audio.linkValues[field][property];
        let lowerBound = baseValue;
        value += getAudioValue(audioFactor, upperBound, lowerBound);
    }

    return value;
}

export const updateBarycentrics = (mesh) => {
    const barycentrics = [];
    const vertexData = mesh.getVerticesData("position");
    if (vertexData) {
        const numVertices = vertexData.length / 3;
        for (let i = 0; i < numVertices / 3; ++i) {
            barycentrics.push(1, 0, 0);
            barycentrics.push(0, 1, 0);
            barycentrics.push(0, 0, 1);
        }
        
        if (!mesh.getVertexBuffer("barycentric")){
            mesh.setVerticesData("barycentric", barycentrics, true, 3);
        }
        else {
            mesh.updateVerticesData("barycentric", barycentrics);
        }
    }   
}

const getTimeValue = (currentTime, loop, lfo, upperBound, lowerBound) => {
    let positionInLFO = (currentTime % (loop * 1000)) / 1000 / loop * 100;
    let highBoundIndex = lfo.findIndex(e => e.x > positionInLFO);
    let lowBoundIndex = highBoundIndex - 1;
    let rise = lfo[highBoundIndex].y - lfo[lowBoundIndex].y;
    let run = lfo[highBoundIndex].x - lfo[lowBoundIndex].x;
    let ratio = rise / run;
    let value = (positionInLFO - lfo[lowBoundIndex].x) * ratio;
    value += lfo[lowBoundIndex].y;
    let factor = value / 100;
    return (upperBound - lowerBound) * factor;
}

function getAudioValue(audioFactor, upperBound, lowerBound) {
    return (upperBound - lowerBound) * audioFactor
}
