export const degreesToRadians = (degrees) => { return degrees * (Math.PI / 180) }
export const radiansToDegrees = (radians) => { return radians * (180 / Math.PI) }
export const secondsToTrackTimeString = (seconds) => { return seconds ? `${Math.floor(seconds / 60)}:${addLeadingZero(Math.floor(((seconds / 60) % 1) * 60))}` : '0:00' }
export const normalizeRadians = (radians) => {
   return radians < 0 ? ((2*Math.PI) - reduceRadians(Math.abs(radians))) : reduceRadians(radians)
   return (radians > (2 * Math.PI)) ? normalizeRadians(radians - (2 * Math.PI)) : radians 
}
const reduceRadians = (radians) => {
   return (radians > (2 * Math.PI)) ? normalizeRadians(radians - (2 * Math.PI)) : radians 
}

const addLeadingZero = (n) => {
   return (n < 10) ? ("0" + n) : n;
}

export const rgbToHex = (r, g, b) => {
   return "#" + rbgNumberToHex(r) + rbgNumberToHex(g) + rbgNumberToHex(b);
}

export const rbgNumberToHex = (num) => {
   var hex = Number(num).toString(16).toUpperCase();
   if (hex.length < 2) {
      hex = "0" + hex;
   }
   return hex;
}

export const hexToRgb = (hex) => {
   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
   return {
     r: parseInt(result[1], 16),
     g: parseInt(result[2], 16),
     b: parseInt(result[3], 16)
   };
 }

 export const roundToHundredth = (val) => {
   return Math.round(val*100)/100;
 }