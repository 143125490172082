
import { GetDefaultVideoInputValue } from '../../app/factories/canvasData';
import { videoData } from '../../app/factories/videoData';

const STORAGE_KEY = "exec-video-inputs";
const getDefaultSettings = () => {
    return [
        // {
        //      ...GetDefaultVideoInputValue("none"),
        // }
    ]
}
const init = () => {
    let saved = sessionStorage.getItem(STORAGE_KEY);
    if (saved) {
        saved = JSON.parse(saved);
        saved.forEach(i => {
            if (i.type == 'file') {
                const newInput = GetDefaultVideoInputValue('file');
                Object.keys(newInput).forEach(key => {
                    if (key != 'id'){
                        i[key] = newInput[key];
                    }
                })
            }       
        })
    }
    return saved || getDefaultSettings();
}

const reduce = (state, action) => {
    switch (action.type) {
        case 'DEFAULT_VIDEO_INPUTS':
        case 'LOAD_VIDEO_INPUTS':
            action.inputs = action.inputs || getDefaultSettings();
            videoData.dirty.shouldClearCanvas = true;
            action.inputs.forEach(i => {
                videoData.dirty.add.push(i.id);
            })
            return [
                ...action.inputs
            ];
        case 'ADD_VIDEO_INPUT':
            var input = GetDefaultVideoInputValue(action.iType)
            videoData.dirty.add.push(input.id);
            return [          
                ...state,
                {
                    ...input,
                },
            ];
        case 'DELETE_VIDEO_INPUT':
            videoData.dirty.delete.push(action.id);
            state = state.filter(c => c.id !== action.id);
            return [
                ...state,
            ];
        case 'REORDER_VIDEO_INPUTS':
            state = action.inputs;
            return [
                ...state,
            ];
        case 'VIDEO_INPUT_PROP_CHANGE':
            videoData.dirty.update.push(action.id);
            var input = state.find(c => c.id === action.id);
            if (input)
                input[action.name] = action.value;
            return [
                ...state,
            ];
        default:
            return state
    }
}

const componentsReducer = (state = init(), action) => {
    state = reduce(state, action);
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    return state;
}

export default componentsReducer