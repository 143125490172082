export const loadDefaultComponents = () => ({
    type: 'DEFAULT_COMPONENTS',
})

export const loadComponents = (components) => ({
    type: 'LOAD_COMPONENTS',
    components: components,
})

export const addComponent = (type) => ({
    type: 'ADD_COMPONENT',
    cType: type,
})

export const addDefinedComponents = (components) => ({
    type: 'ADD_DEFINED_COMPONENTS',
    components: components,
})

export const deleteComponent = (id) => ({
    type: 'DELETE_COMPONENT',
    id: id,
})

export const propChange = (id, name, value) => ({
    type: 'PROP_CHANGE',
    id: id,
    name: name,
    value: value,
})

export const reorderComponents = (components) => ({
    type: 'REORDER_COMPONENTS',
    components: components
})

export const copyComponent = (component) => ({
    type: 'COPY_COMPONENT',
    component: component
})
