import { combineReducers } from "redux";
import user from "./userReducer";
import shared from "./sharedReducer";
import components from './componentsReducer';
import inputs from './inputsReducer';
import videoInputs from './videoInputsReducer';
import appSettings from './appSettingsReducer';
import userPreferences from './userPreferencesReducer';
import playlist from './playlistReducer';

export default combineReducers({ 
    user, 
    shared, 
    components, 
    inputs, 
    videoInputs, 
    appSettings, 
    userPreferences, 
    playlist,
});
