import React from 'react';
import { Grid, FormControl, Slider, InputLabel, Box } from '@material-ui/core'
import TextInput from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import { rbgNumberToHex, hexToRgb } from '../../utility/math';

class ColorInput extends React.Component {
    state = { value: 0, audioLinkValue: { r: [255, 255], g: [255, 255], b: [255, 255] } };
    keyPress = (e) => {
        if (e.keyCode === 13)
            e.target.blur();
    }
    componentWillReceiveProps(nextProps) {
        const rgb = hexToRgb(nextProps.value);
        const val = nextProps.audioLinkValue || {
            r: [rgb.r, 255],
            g: [rgb.g, 255],
            b: [rgb.b, 255],
        }
        this.setState({ value: nextProps.value, audioLinkValue: val })
    }
    componentDidMount() {
        const rgb = hexToRgb(this.props.value);
        const val = this.props.audioLinkValue || {
            r: [rgb.r, 255],
            g: [rgb.g, 255],
            b: [rgb.b, 255],
        }
        this.setState({ value: this.props.value, audioLinkValue: val })
    }
    audioLinkChange = (e) => {
        let ev = {
            target: {
                name: this.props.name,
                value: `#${rbgNumberToHex(this.state.audioLinkValue.r[0])}${rbgNumberToHex(this.state.audioLinkValue.g[0])}${rbgNumberToHex(this.state.audioLinkValue.b[0])}`
            }
        }
        this.props.onChange(ev);
        this.props.onAudioLinkValueChange(this.props.name, this.state.audioLinkValue);
        this.props.audioLinkChange(this.props.name)
    }

    onAudioLinkComponentChange = (name, val) => {
        this.state.audioLinkValue[name] = val;
        this.setState({ value: val[0], audioLinkValue: this.state.audioLinkValue });
        let ev = {
            target: {
                name: this.props.name,
                value: `#${rbgNumberToHex(this.state.audioLinkValue.r[0])}${rbgNumberToHex(this.state.audioLinkValue.g[0])}${rbgNumberToHex(this.state.audioLinkValue.b[0])}`
            }
        }
        this.props.onChange(ev);
        this.props.onAudioLinkValueChange(this.props.name, this.state.audioLinkValue);
    }
    render() {
        const rgbMarks = [
            {
                value: 0,
                label: 0,
            },
            {
                value: 255,
                label: 255,
            },
        ]
        let isAudioLinkEnabled = this.props.audioLinks?.includes(this.props.name)
        const audioLinks = this.props.hasAudioLinks
            ? (
                <Grid item xs={1}>
                    <ToggleButton
                        size="small"
                        value={isAudioLinkEnabled}
                        selected={isAudioLinkEnabled}
                        onChange={this.audioLinkChange}
                        name={"audioLink"}
                    >
                        <GraphicEqIcon style={{ fontSize: "1em" }} />
                    </ToggleButton>
                </Grid>)
            : "";
        let html = this.props.hasAudioLinks && isAudioLinkEnabled
            ? <FormControl fullWidth margin='none'>
                <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                    <Box px={2}>
                        <InputLabel style={{ position: "relative", margin: "0", transform: "none", fontSize: "small" }}>Red</InputLabel>
                        <Slider
                            value={this.state.audioLinkValue.r}
                            onChange={(e, val) => this.onAudioLinkComponentChange("r", val)}
                            step={1}
                            marks={rgbMarks}
                            min={0}
                            max={255}
                            valueLabelDisplay="auto"
                        />
                    </Box>
                    <Box px={2}>
                        <InputLabel style={{ position: "relative", margin: "0", transform: "none", fontSize: "small" }}>Green</InputLabel>
                        <Slider
                            value={this.state.audioLinkValue.g}
                            onChange={(e, val) => this.onAudioLinkComponentChange("g", val)}
                            step={1}
                            marks={rgbMarks}
                            min={0}
                            max={255}
                            valueLabelDisplay="auto"
                        />
                    </Box>
                    <Box px={2}>
                        <InputLabel style={{ position: "relative", margin: "0", transform: "none", fontSize: "small" }}>Blue</InputLabel>
                        <Slider
                            value={this.state.audioLinkValue.b}
                            onChange={(e, val) => this.onAudioLinkComponentChange("b", val)}
                            step={1}
                            marks={rgbMarks}
                            min={0}
                            max={255}
                            valueLabelDisplay="auto"
                        />
                    </Box>
                </Box>
            </FormControl>
            : ""

        return (
            <React.Fragment>
                <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                    {audioLinks}
                    <Grid item xs={this.props.hasAudioLinks ? 10 : 12}>
                        <FormControl fullWidth margin='none'>
                            <TextInput
                                disabled={isAudioLinkEnabled}
                                variant="outlined"
                                label={this.props.label}
                                value={this.state.value}
                                margin="dense"
                                size="small"
                                onKeyDown={this.keyPress}
                                onChange={e => this.setState({ value: e.target.value })}
                                onBlur={e => { e.target.value = this.state.value; this.props.onChange(e) }}
                                inputProps={{
                                    name: this.props.name,
                                    type: this.props.type,
                                    'aria-labelledby': this.props.name,
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {html}
            </React.Fragment>

        );
    }
}
export default ColorInput;