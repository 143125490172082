import React from 'react';
import { Slider, FormControl, InputLabel, Grid, Input, Box } from '@material-ui/core'
import TextInput from '@material-ui/core/TextField';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import ToggleButton from '@material-ui/lab/ToggleButton';

class SliderInput extends React.Component {
    state = { value: 0, audioLinkValue: [0, 0] };
    componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.value, audioLinkValue: nextProps.audioLinkValue || [nextProps.value, nextProps.max] })
    }
    componentDidMount() {
        this.setState({ value: this.props.value, audioLinkValue: this.props.audioLinkValue || [this.props.value, this.props.max] })
    }
    inputChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!this.props.allowManualMax && e.target.value > this.props.max)
            e.target.value = this.props.max;

        if (!this.props.allowManualMin && e.target.value < this.props.min)
            e.target.value = this.props.min;

        this.setState({ value: e.target.value })
        let isAudioLinkEnabled = this.props.audioLinks?.includes(this.props.name)
        if (isAudioLinkEnabled) {
            this.props.onAudioLinkValueChange(this.props.name, this.state.audioLinkValue);
        }
        else {
            this.props.onChange(e);
        }
    }
    keyPress = (e) => {
        if (e.keyCode === 13)
            e.target.blur();
    }
    numberInputChange = (e) => {
        let number = Number(e.target.value);
        if (!Number.isNaN(number)) {
            if (this.props.allowManualMin || e.target.value >= this.props.min) {
                if (this.props.allowManualMax || e.target.value <= this.props.max) {
                    return this.inputChange(e);
                }
            }
        }
        this.setState({ value: e.target.value });
    }
    mouseDown = (e) => {
        e.stopPropagation();
        const mouseUp = () => {
            document.removeEventListener('mouseup', mouseUp, true);
            document.removeEventListener('touchend', mouseUp, true);
            let isAudioLinkEnabled = this.props.audioLinks?.includes(this.props.name)
            if (isAudioLinkEnabled) {
                this.props.onAudioLinkValueChange(this.props.name, this.state.audioLinkValue);
            }
            else {
                this.props.onChange({ target: { name: this.props.name, value: this.state.value, type: "number" } })
            }
        }
        document.addEventListener('mouseup', mouseUp, true);
        document.addEventListener('touchend', mouseUp, true);
    }

    audioLinkChange = (e) => {
        let ev = {
            target: {
                name: this.props.name,
                value: this.state.audioLinkValue[0]
            }
        }
        this.props.onChange(ev);
        this.props.onAudioLinkValueChange(this.props.name, [this.state.value, this.props.max]);
        this.props.audioLinkChange(this.props.name)
    }

    render() {
        let isAudioLinkEnabled = this.props.audioLinks?.includes(this.props.name)
        const marks = [
            ...(this.props.marks || []),
            {
                value: this.props.min,
                label: this.props.min,
            },
            {
                value: this.props.max,
                label: this.props.max,
            },
        ]


        const audioLinks = this.props.hasAudioLinks
            ? (
                <Grid item xs={1}>
                    <ToggleButton
                        size="small"
                        value={isAudioLinkEnabled}
                        selected={isAudioLinkEnabled}
                        onChange={this.audioLinkChange}
                        name={"audioLink"}
                    >
                        <GraphicEqIcon style={{ fontSize: "1em" }} />
                    </ToggleButton>
                </Grid>)
            : "";

        let sliderValue = [this.state.value]
        if (isAudioLinkEnabled) {
            sliderValue = [...this.state.audioLinkValue]
        }
        return (
            <FormControl fullWidth margin='none'>
                <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                    <InputLabel style={{ position: "relative", margin: "0", transform: "none", fontSize: "small" }} id={this.props.name}>{this.props.label}</InputLabel>
                    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                        {audioLinks}
                        <Grid item xs>
                            <Slider
                                value={sliderValue}
                                onMouseDown={this.mouseDown}
                                onTouchStart={this.mouseDown}
                                onChange={(e, val) => this.setState({ value: val[0], audioLinkValue: val })}
                                aria-labelledby={this.props.name}
                                step={this.props.step}
                                marks={marks}
                                min={this.props.min}
                                max={this.props.max}
                                valueLabelDisplay="auto"
                            />
                        </Grid>

                        <Grid item>
                            <TextInput
                                variant="outlined"
                                value={this.state.value}
                                margin="dense"
                                onChange={this.numberInputChange}
                                onBlur={e => { e.target.value = this.state.value; this.inputChange(e) }}
                                onKeyDown={this.keyPress}
                                style={{width:"100px"}}
                                inputProps={{
                                    name: this.props.name,
                                    step: this.props.step,
                                    min: this.props.allowManualMin ? undefined : this.props.min,
                                    max: this.props.allowManualMax ? undefined : this.props.max,
                                    type: 'number',
                                    'aria-labelledby': this.props.name,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </FormControl>
        );
    }
}
export default SliderInput;