import * as BABYLON from 'babylonjs';
import { getShapeValue } from "./utility";

export const sphereProperties = [
    "segments",
    "diameterX",
    "diameterY",
    "diameterZ",
    "arc",
    "slice",
]
export const sphere = (scene, component, audioFactor, time) => {
    let options = {
        segments: Math.floor(getShapeValue(component, 'segments', audioFactor, time)),
        diameterX: getShapeValue(component, 'diameterX', audioFactor, time),
        diameterY: getShapeValue(component, 'diameterY', audioFactor, time),
        diameterZ: getShapeValue(component, 'diameterZ', audioFactor, time),
        arc: getShapeValue(component, 'arc', audioFactor, time),
        slice: getShapeValue(component, 'slice', audioFactor, time),
        updatable: true,
    }

    return BABYLON.MeshBuilder.CreateSphere(component.id, options, scene);
}