import * as BABYLON from 'babylonjs';

BABYLON.Effect.ShadersStore["wireframeVertexShader"] = `   
precision highp float;
attribute vec3 position;
attribute vec3 barycentric;
uniform mat4 worldViewProjection;
varying vec3 vBarycentric;
void main(void) {
    vBarycentric = barycentric;
    gl_Position = worldViewProjection * vec4(position, 1.0);
}`;

BABYLON.Effect.ShadersStore["wireframeFragmentShader"] = `
precision highp float;
uniform float thickness;
varying vec3 vBarycentric;
uniform vec4 color;
const vec4 interiorColor = vec4(0.0, 0.0, 0.0, 0.0);

#define METHOD 1
float edgeFactor(){
    vec3 d = fwidth(vBarycentric);
    vec3 a3 = smoothstep(vec3(0.0), d * thickness, vBarycentric);
    return min(min(a3.x, a3.y), a3.z);
}

void main(void) {
    #if METHOD == 1
        gl_FragColor = mix(color, interiorColor, edgeFactor());
    #else
        float d = min(vBarycentric.x, min(vBarycentric.y, vBarycentric.z));
        float I = exp2(-100. * d / thickness);
        gl_FragColor = I * color + (1.0 - I) * interiorColor;
    #endif
}`;