import _assetsService from "../../services/assetsService";
import { _assetCache } from "./indexedDB";
const ENABLE_CACHE = true;

export const _assets = {
    create: async (file) => {
        let asset = await _assetsService.postFile(file);
        // if (ENABLE_CACHE) {
        //     _assetCache.add(asset);
        // }
        return asset;
    },
    get: async (id) => {
        let asset = undefined;
        if (ENABLE_CACHE) {
            asset = await _assetCache.get(id);
        }
        if (!asset) {
            asset = await _assetsService.getAssetBase64(id);
            if (ENABLE_CACHE && asset && !Array.isArray(asset)) {
                _assetCache.add(asset);
            }
        }

        return asset;
    },
}
