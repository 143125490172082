import * as BABYLON from 'babylonjs';
import { getShapeValue } from "./utility";

export const capsuleProperties = [
    "height",
    "radiusTop",
    "radiusBottom",
    "tessellation",
    "subdivisions",
    "capSubdivisions",
]
export const capsule = (scene, component, audioFactor, time) => {
    let options = {
        height: getShapeValue(component, 'height', audioFactor, time),
        radiusTop: getShapeValue(component, 'radiusTop', audioFactor, time),
        radiusBottom: getShapeValue(component, 'radiusBottom', audioFactor, time),
        tessellation: Math.floor(getShapeValue(component, 'tessellation', audioFactor, time)),
        subdivisions: Math.floor(getShapeValue(component, 'subdivisions', audioFactor, time)),
        capSubdivisions: Math.floor(getShapeValue(component, 'capSubdivisions', audioFactor, time)),
    }

    let mesh = BABYLON.MeshBuilder.CreateCapsule(component.id, options, scene);
    mesh.markVerticesDataAsUpdatable(BABYLON.VertexBuffer.PositionKind, true);
    return mesh;
}