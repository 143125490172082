import * as BABYLON from 'babylonjs';
import { _assets } from '../../../factories/assets';

let lookup = {};
export const _assetManager = {
    get: (id, scene) => {
        if (!lookup[id]) {
            lookup[id] = new Promise((res, rej) => {
                _assets.get(id).then(asset => {
                    let texture = BABYLON.Texture.CreateFromBase64String(asset.base64, id, scene);
                    texture.hasAlpha = true;
                    res(texture);
                }).catch(e => {
                    rej();
                })
            });
        }

        return lookup[id];
    }
}

