const STORAGE_KEY = "exec-playlist";

let defaultSettings = {
    playlistId: undefined,
    playlistName: "new project",
    playlistLastModified: undefined,
    playlistUserId: undefined,
    projects: [],
    sceneTime: 30,
}

const init = () => {
    let saved = sessionStorage.getItem(STORAGE_KEY);
    if (saved)
        saved = JSON.parse(saved);
    return saved ? {...saved } : defaultSettings;
}

const reduce = (state, action) => {
    switch (action.type) {
        case 'PLAYLIST_LOAD_DEFAULT':
            return {
                ...defaultSettings,
            }
        case 'PLAYLIST_LOAD':
            return {
                ...action.value,
            }
        case 'PLAYLIST_SETTING_CHANGE':
            state[action.name] = action.value;
            return {
                ...state,
            }
        case 'PLAYLIST_SETTINGS_CHANGE':
            for (let key of Object.keys(action.value))
                state[key] = action.value[key];
            
            return {
                ...state,
            }
        default:
            return state
    }
}

const playlistReducer = (state = init(), action) => {
    state = reduce(state, action);
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    return state;
}
export default playlistReducer