import * as BABYLON from 'babylonjs';
import * as MeshWriter from "meshwriter";
import { _assets } from '../../../factories/assets';
import { degreesToRadians } from '../../../utility/math';
import { _assetManager } from '../assets/assetManager';
import { bindMeshAndMaterial, getGlowLayer, getMaterial, updateMaterial } from '../material/material';
import { getAttributePropertyShapeValue } from '../shapes/shapeOptions/utility';
import { scramble } from '../utility/noise';

const getMesh = (scene, component) => {
    let Writer = MeshWriter(scene);
    let text = new Writer(component.text, {
        "font-family": component.font,
        "letter-height": component.size,
        "letter-thickness": component.depth,
    });

    text.getMaterial().dispose();
    return text.getMesh();
}

const updateMesh = (mesh, component, audioFactor, time) => {
    mesh.position.x = getAttributePropertyShapeValue(component, 'position', 'x', audioFactor, time)
    mesh.position.y = getAttributePropertyShapeValue(component, 'position', 'y', audioFactor, time)
    mesh.position.z = getAttributePropertyShapeValue(component, 'position', 'z', audioFactor, time)

    mesh.scaling = BABYLON.Vector3.Zero();
    mesh.scaling.x = getAttributePropertyShapeValue(component, 'scaling', 'x', audioFactor, time)
    mesh.scaling.y = getAttributePropertyShapeValue(component, 'scaling', 'y', audioFactor, time)
    mesh.scaling.z = getAttributePropertyShapeValue(component, 'scaling', 'z', audioFactor, time)

    mesh.rotation = BABYLON.Vector3.Zero();
    mesh.rotation.x = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'x', audioFactor, time))
    mesh.rotation.y = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'y', audioFactor, time))
    mesh.rotation.z = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'z', audioFactor, time))
}

const createMesh = (scene, component, audioFactor, time) => {
    let mesh = getMesh(scene, component);
    mesh.id = component.id;
    mesh.isVisible = component.visible;

    mesh.position.x = getAttributePropertyShapeValue(component, 'position', 'x', audioFactor, time)
    mesh.position.y = component.position.y
    mesh.position.z = component.position.z

    mesh.scaling = BABYLON.Vector3.Zero();
    mesh.scaling.x = component.scaling.x
    mesh.scaling.y = component.scaling.y
    mesh.scaling.z = component.scaling.z

    mesh.rotation = BABYLON.Vector3.Zero();
    mesh.rotation.x = degreesToRadians(component.rotation.x);
    mesh.rotation.y = degreesToRadians(component.rotation.y);
    mesh.rotation.z = degreesToRadians(component.rotation.z);

    return mesh;
}

export const buildText = (scene, gizmoManager, component, parent, videoInputs) => {
    let _component;
    let mesh;
    let color;
    let glowColor;
    let image;
    let bumpMap;
    let material;
    let glowLayer;
    let videoTexture = { ref: undefined };

    const setMaterialCallback = () => {
        if (material) {
            material.dispose();
        }
        material = getMaterial(scene, parent, component, color, image, bumpMap, videoTexture, videoInputs);
        bindMeshAndMaterial(component, mesh, material, glowLayer);
    }
    const updateComponent = (component, audioFactor) => {
        _component = component;
        color = new BABYLON.Color3.FromHexString(component.material.colorHex).toColor4();
        glowColor = new BABYLON.Color3.FromHexString(component.glowColor || component.material.colorHex).toColor4();
        color.a = component.opacity;
        glowColor.a = component.opacity;
        mesh = createMesh(scene, component, audioFactor);
        glowLayer = getGlowLayer(scene, component, mesh, glowColor);
        if (component.morph) {
            mesh.updateMeshPositions(scramble(component.morph))
        }
        gizmoManager.attachableMeshes.push(mesh);
        if (parent) {
            mesh.parent = parent;
            if (typeof parent.visibility != "undefined") mesh.visibility = parent.visibility;
        }
        let materialPromises = [];
        if ((component.material.type == "image" || component.material.type == "advanced") && component.material.image) {         
            let imagePromise = _assetManager.get(component.material.image, scene).then(texture => {
                image = texture;
            });
            materialPromises.push(imagePromise);
        }
        if (component.material.type == "advanced" && component.material.bumpMap) {
           let bumpMapPromise = _assetManager.get(component.material.bumpMap, scene).then(texture => {
                bumpMap = texture;
            });
            materialPromises.push(bumpMapPromise);
        }

        Promise.all(materialPromises).then(setMaterialCallback);
    }
    updateComponent(component);

    class TextComponent {
        id = component.id;
        mesh = mesh;
        component = _component;
        gizmoActive = false;
        parent = parent;
        dispose = () => {
            const index = gizmoManager.attachableMeshes.indexOf(mesh);
            if (index > -1) {
                gizmoManager.attachableMeshes.splice(index, 1);
            }
            if (videoTexture.ref) {
                videoTexture.ref.dispose();
                videoTexture.ref = undefined;
            }
            // if (image) {
            //     image.dispose();
            // }
            // if (bumpMap) {
            //     bumpMap.dispose();
            // }
            if (mesh) {
                mesh.parent = null;
                mesh.dispose();
            }
            if (material) {
                material.dispose();
            }
            if (glowLayer) {
                glowLayer.dispose();
            }
        }
        update = (component) => {
            this.dispose();
            updateComponent(component);
            this.mesh = mesh;
            this.component = _component;
        }
        animate = (data) => {
            updateMaterial(component, material, glowLayer, data.audioFactor, data.currentTime, parent);
            updateMesh(mesh, component, data.audioFactor, data.currentTime);
        }
    }

    return new TextComponent();
}