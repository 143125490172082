import React from 'react';
import { Fab, Grid, Slider, CircularProgress } from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';
import { secondsToTrackTimeString } from '../../utility/math';
import { audioData, LoadedAudio } from '../../factories/audioData';
import WaveSurfer from 'wavesurfer.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.js'
import { v4 as uuidv4 } from 'uuid';

class AudioController extends React.Component {
    waveformHeight = 25;
    wavesurfer = undefined;
    wavesurferManualSeek = false;
    wavesurferReady = false;
    waveformId = uuidv4();
    state = {
        currentTime: 0,
        wavesurferReady: false,
        zoom: 20,
    }

    play = () => {
        this.props.propsChange(this.props.input.id, 'play', !this.props.input.play);
        this.setState({ currentTime: 0 })
    }

    seek = (e) => {
        e.stopPropagation();
        const _this = this;
        this.props.propsChange(this.props.input.id, 'play', false);
        this.props.propsChange(this.props.input.id, 'currentTime', 0);
        const mouseUp = () => {
            document.removeEventListener('mouseup', mouseUp, true);
            document.removeEventListener('touchend', mouseUp, true);
            this.props.propsChange(this.props.input.id, 'currentTime', _this.state.currentTime);
            this.props.propsChange(this.props.input.id, 'play', true);
            this.setState({ currentTime: 0 })
        }
        document.addEventListener('mouseup', mouseUp, true);
        document.addEventListener('touchend', mouseUp, true);
    }

    updateTime = (time) => {
        const _this = this;
        this.setState({ currentTime: time });
        if (_this.wavesurfer && _this.wavesurfer.url != _this.props.input.audioFileUrl) {
            _this.setState({ wavesurferReady: false })
            _this.wavesurfer.destroy();
            delete _this.wavesurfer;
        }
        if (!_this.wavesurfer) {
            let elem = document.getElementById(this.waveformId);
            if (elem) {
                _this.wavesurfer = WaveSurfer.create({
                    container: elem,
                    waveColor: 'white',
                    progressColor: 'grey',
                    height: _this.waveformHeight,
                    barWidth: 1,
                    normalize: true,
                    hideScrollbar: true,
                    plugins: [
                        CursorPlugin.create({
                            showTime: true,
                            opacity: 1,
                            customShowTimeStyle: {
                                'background-color': '#000',
                                color: '#fff',
                                padding: '2px',
                                'font-size': '10px'
                            }
                        })
                    ]
                });
                _this.wavesurfer.on('ready', function () {
                    _this.setState({ wavesurferReady: true })
                });
                _this.wavesurfer.drawer.on('click', function (data) {
                    _this.wavesurferManualSeek = true;
                });
                _this.wavesurfer.on('seek', function (position) {
                    if (_this.wavesurferManualSeek){
                        let currentTime = position * _this.wavesurfer.getDuration();
                        _this.props.propsChange(_this.props.input.id, 'play', false);
                         _this.props.propsChange(_this.props.input.id, 'currentTime', currentTime);
                         _this.props.propsChange(_this.props.input.id, 'play', true);
                         _this.setState({ currentTime: 0 })
                         _this.wavesurferManualSeek = false;
                    }         
                });      
                if (_this.props.input.audioFileUrl) {
                    _this.wavesurfer.url = _this.props.input.audioFileUrl;
                    _this.wavesurfer.load(_this.props.input.audioFileUrl);
                }
            }    
        }
        else {
            let position = (_this.props.input.currentTime + _this.state.currentTime)/_this.props.input.trackLength;
            if (position >= 0 && position <= 1){
                _this.wavesurfer.seekAndCenter(position)
            }
        }  
    }

    // setZoom = (e) => {
    //     let val = Number(e.target.value);
    //     this.setState({zoom: val});
    //     if (this.wavesurfer) {
    //         this.wavesurfer.zoom(val);
    //     }
    // }

    componentDidMount() {
        if (!audioData.loaded[`${this.props.input.id}-input`]) {
            audioData.loaded[`${this.props.input.id}-input`] = new LoadedAudio();
        }
        audioData.loaded[`${this.props.input.id}-input`].time.subscriptions.push(this.updateTime);
    }

    render() {
        // audioData.loaded[`${this.props.input.id}-input`] = {
        //     updateTime: this.updateTime,
        // };
        
        const btnStyle = { outline: "none", width: "20px", height: "20px", minHeight: "20px" }

        return (
            <React.Fragment>
                
                <Grid spacing={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px", width: "100%" }} container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Fab style={btnStyle} title="play/pause" onClick={this.play} disabled={this.props.input.status == "nofile"}>
                            {
                                this.props.input.status == 'loading'
                                    ? <CircularProgress style={{ color: "black" }} size={15} />
                                    : (this.props.input.play ? <Pause /> : <PlayArrowIcon />)
                            }
                        </Fab>
                    </Grid>
                    <Grid item style={{width:"50px"}}>
                        <span name={`${this.props.input.id}-time-label`}>{secondsToTrackTimeString(this.props.input.currentTime + this.state.currentTime)}</span>
                    </Grid>
                    <Grid item xs style={{marginBottom:"-15px"}}>
                        <div id={this.waveformId} style={{height:"15px", overflow:"hidden"}}></div>
                        <Slider
                            disabled={this.props.input.status == "nofile" || this.props.input.status == "loading"}
                            value={this.props.input.currentTime + this.state.currentTime}
                            onMouseDown={this.seek}
                            onTouchStart={this.seek}
                            onChange={(e, val) => this.setState({ currentTime: val })}
                            step={.01}
                            min={0}
                            max={this.props.input.trackLength}
                        />
                    </Grid>
                    <Grid item style={{width:"50px"}}>
                        <span>{secondsToTrackTimeString(this.props.input.trackLength)}</span>
                    </Grid>
                </Grid>
                {/* <SliderInput value={this.state.zoom} onChange={this.setZoom} min={20} max={1000} step={1} label={"zoom"} name={"zoom"} /> */}
            </React.Fragment>

        )
    }
}

export default AudioController;
