import React, { Component } from "react";
import { Tree } from "@minoru/react-dnd-treeview";
import { connect } from 'react-redux';
import { reorderComponents, deleteComponent, propChange, copyComponent } from "../../../redux/actions/componentsDataActions";
import { appSettingChange } from "../../../redux/actions/appSettingsActions";
import TreeItem from "./treeItem";
import { IsNestedType, LAYER_TYPE } from "../../factories/canvasData";
import DragPreview from "./dragPreview";
import DragPlaceholder from "./dragPlaceholder";

class LayerTree extends Component {
    onDrop = (tree) => {
        const components = tree.map(i => { return { ...i.data, parentId: i.parent } });
        this.props.reorder(components);
    }

    select = (id) => {
        if (this.props.selectedComponentId == id)
            id = undefined;
        return this.props.select(id);
    }

    delete = (id) => {
        if ((this.props.selectedComponentId && this.props.selectedComponentId === id))
            this.props.select(null);
        return this.props.delete(id);
    }

    copy = (id) => {
        const component = this.props.components.find(c => c.id == id);
        this.props.copyComponent(component);
    }

    toggleVisibility = (id, value) => {
        return this.props.propChange(id, "visible", value);
    }

    tune = () => {
        this.props.appSettingChange("view", "mobile-component-edit");
    }

    render() {
        const components = this.props.components;
        const selectedComponentId = this.props.selectedComponentId;
        const treeData = components.map(c => {
            return {
                id: c.id,
                parent: c.parentId,
                text: c.name,
                droppable: IsNestedType(c.type),
                data: {
                    ...c
                },
            }
        })

        return (
            <Tree
                tree={treeData}
                rootId={0}
                render={(node, { depth, isOpen, onToggle }) => (
                    <TreeItem
                        id={node.data.id}
                        name={node.data.name}
                        type={node.data.type}
                        isSelected={selectedComponentId == node.data.id}
                        visible={node.data.visible}
                        select={this.select}
                        tune={this.tune}
                        delete={this.delete}
                        toggleVisibility={this.toggleVisibility}
                        copy={this.copy}
                        node={node}
                        depth={depth}
                        isOpen={isOpen}
                        onToggle={onToggle}
                    />
                )}
                dragPreviewRender={(monitorProps) => (
                    <DragPreview monitorProps={monitorProps} />
                )}
                onDrop={this.onDrop}
                classes={{
                    root: "component-tree",
                    draggingSource: "component-tree-source",
                    placeholder: "component-tree-placeholder"
                }}
                sort={false}
                insertDroppableFirst={false}
                canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
                    if (dragSource.data.type == LAYER_TYPE) {
                        if (dropTarget){
                            return false;
                        }
                    }
                    if (dragSource?.parent === dropTargetId) {
                        return true;
                    }
                }}
                dropTargetOffset={5}
                placeholderRender={(node, { depth }) => (
                    <DragPlaceholder depth={depth} />
                )}
            />
        );
    }
}
const mapCanvasData = (state) => { return { components: state.components } };
const actions = {
    reorder: reorderComponents,
    copyComponent: copyComponent,
    delete: deleteComponent,
    propChange: propChange,
    appSettingChange: appSettingChange,
};
export default connect(mapCanvasData, actions)(LayerTree)