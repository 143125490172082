import * as BABYLON from 'babylonjs';
import { getShapeValue } from "./utility";

export const torusProperties = [
    "diameter",
    "thickness",
    "tessellation",
]
export const torus = (scene, component, audioFactor, time) => {
    let options = {
        diameter: getShapeValue(component, 'diameter', audioFactor, time),
        thickness: getShapeValue(component, 'thickness', audioFactor, time),
        tessellation: Math.floor(getShapeValue(component, 'tessellation', audioFactor, time)),
        updatable: true,
    }

    return BABYLON.MeshBuilder.CreateTorus(component.id, options, scene);
}