export const getChromaKeyShader = (chromakeyColor, chromakeyTolerance) => {
    return `  
    vec3 chromaKey = vec3(${chromakeyColor.r},${chromakeyColor.g},${chromakeyColor.b});
    vec3 tolerance = vec3(${chromakeyTolerance});
    vec3 limitMin = vec3(chromaKey-tolerance); 
    vec3 limitMax = vec3(chromaKey+tolerance);
    limitMin.r = max(limitMin.r, 0.);
    limitMin.g = max(limitMin.g, 0.);
    limitMin.b = max(limitMin.b, 0.);
    limitMax.r = min(limitMax.r, 1.);
    limitMax.g = min(limitMax.g, 1.);
    limitMax.b = min(limitMax.b, 1.);
    
    if(
        ((color.r >= limitMin.r) && (color.r <= limitMax.r)) &&
        ((color.g >= limitMin.g) && (color.g <= limitMax.g)) &&
        ((color.b >= limitMin.b) && (color.b <= limitMax.b))
    ){
        discard;
    }else{
        //keep pixel; 
    }
    `
}