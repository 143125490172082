
//////////////////////////////////////////////////
// client
/////////////////////////////////////////////////
// local
// export const LOCAL_URL = "http://localhost:3001";
// export const AUTH_CLIENT_ID = "local-exec-venue";

// build
// export const LOCAL_URL = "https://localhost:7249";
// export const AUTH_CLIENT_ID = "local-exec-build";

// prod
export const LOCAL_URL = "https://exec-venue.vaudiox.com";
export const AUTH_CLIENT_ID = "exec-venue";
/////////////////////////////////////////////////


/////////////////////////////////////////////////
// API Server
/////////////////////////////////////////////////
// local
// export const API_URL = "https://localhost:7249/api";

// prod
export const API_URL = "https://exec.vaudiox.com/api";
/////////////////////////////////////////////////



/////////////////////////////////////////////////
// Authentication Server
/////////////////////////////////////////////////
// local
// export const AUTH_SERVER_URL = "https://localhost:5001";

// prod
export const AUTH_SERVER_URL = "https://auth.vaudiox.com";
/////////////////////////////////////////////////



/////////////////////////////////////////////////
// Streaming Server
/////////////////////////////////////////////////
// local
// export const STREAM_URL = "https://localhost:7236";

// prod
export const STREAM_URL = "https://watch.vaudiox.com";
/////////////////////////////////////////////////



/////////////////////////////////////////////////
// Chat Server
/////////////////////////////////////////////////
// local
// export const CHAT_URL = "https://localhost:7003/hubs/chathub";

// prod
export const CHAT_URL = "https://chat.vaudiox.com/hubs/chathub";
/////////////////////////////////////////////////



/////////////////////////////////////////////////
// Venue Server
/////////////////////////////////////////////////
// local
// export const VENUE_HUB_URL = "https://localhost:7267/hubs/venuehub";
// export const VENUE_URL = "https://localhost:7267/api";

// prod
export const VENUE_HUB_URL = "https://venue.vaudiox.com/hubs/venuehub";
export const VENUE_URL = "https://venue.vaudiox.com/api";
/////////////////////////////////////////////////

/////////////////////////////////////////////////
// MAIN SITE
/////////////////////////////////////////////////
// local
// export const MAIN_SITE_URL = "http://localhost:3000";

// prod
export const MAIN_SITE_URL = "https://exec.vaudiox.com";
/////////////////////////////////////////////////