import * as BABYLON from 'babylonjs';
import { degreesToRadians } from '../../../utility/math';
import { getAttributePropertyShapeValue, getShapeValue } from '../shapes/shapeOptions/utility';

const updateMesh = (mesh, component, audioFactor, time, parent) => {
    mesh.position.x = getAttributePropertyShapeValue(component, 'position', 'x', audioFactor, time)
    mesh.position.y = getAttributePropertyShapeValue(component, 'position', 'y', audioFactor, time)
    mesh.position.z = getAttributePropertyShapeValue(component, 'position', 'z', audioFactor, time)

    mesh.scaling = BABYLON.Vector3.Zero();
    mesh.scaling.x = getAttributePropertyShapeValue(component, 'scaling', 'x', audioFactor, time)
    mesh.scaling.y = getAttributePropertyShapeValue(component, 'scaling', 'y', audioFactor, time)
    mesh.scaling.z = getAttributePropertyShapeValue(component, 'scaling', 'z', audioFactor, time)

    mesh.rotation = BABYLON.Vector3.Zero();
    mesh.rotation.x = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'x', audioFactor, time))
    mesh.rotation.y = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'y', audioFactor, time))
    mesh.rotation.z = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'z', audioFactor, time))

    const opacity = getShapeValue(component, 'opacity', audioFactor, time);
    mesh.visibility = parent ? (parent.visibility * opacity) : opacity;
}

export const buildGroup = (scene, gizmoManager, component, parent) => {
    let _component;
    let mesh;
    mesh = new BABYLON.Mesh(component.id, scene);
    // mesh.onEnabledStateChangedObservable.add(m => {
    //     setVisibility(m, .5)
    // })
    const updateComponent = (component) => {
        _component = component;
        component.opacity = typeof component.opacity === "undefined" ? 1 : component.opacity;
        mesh.setEnabled(component.visible);

        mesh.position.x = component.position.x
        mesh.position.y = component.position.y
        mesh.position.z = component.position.z

        mesh.scaling = BABYLON.Vector3.Zero();
        mesh.scaling.x = component.scaling.x
        mesh.scaling.y = component.scaling.y
        mesh.scaling.z = component.scaling.z

        mesh.rotation = BABYLON.Vector3.Zero();
        mesh.rotation.x = degreesToRadians(component.rotation.x);
        mesh.rotation.y = degreesToRadians(component.rotation.y);
        mesh.rotation.z = degreesToRadians(component.rotation.z);

        gizmoManager.attachableMeshes.push(mesh);
        mesh.visibility = component.opacity;

        if (parent) {
            mesh.parent = parent;
            if (typeof parent.visibility != "undefined") mesh.visibility *= parent.visibility
        }
        
        return mesh;
    }
    updateComponent(component);

    class GroupComponent {
        id = component.id;
        mesh = mesh;
        component = _component;
        gizmoActive = false;
        parent = parent;
        dispose = () => {
            const index = gizmoManager.attachableMeshes.indexOf(mesh);
            if (index > -1) {
                gizmoManager.attachableMeshes.splice(index, 1);
            }

            mesh.dispose();
        }
        update = (component) => {
            updateComponent(component);
            this.mesh = mesh;
            this.component = _component;
        }
        animate = (d) => {
            updateMesh(mesh, component, d.audioFactor, d.currentTime, parent);
        }
    }

    return new GroupComponent();
}