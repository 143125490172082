import * as BABYLON from 'babylonjs';
import { getShapeValue } from "./utility";

export const polyhedronProperties = [
    "complexity",
    "width",
    "height",
    "depth",
]
export const polyhedron = (scene, component, audioFactor, time) => {
    let options = {
        type: Math.floor(getShapeValue(component, 'complexity', audioFactor, time)),
        sizeX: getShapeValue(component, 'width', audioFactor, time),
        sizeY: getShapeValue(component, 'height', audioFactor, time),
        sizeZ: getShapeValue(component, 'depth', audioFactor, time),
        updatable: true,
    }

    return BABYLON.MeshBuilder.CreatePolyhedron(component.id, options, scene);
}