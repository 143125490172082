export const loadDefaultInputs = (inputs) => ({
    type: 'DEFAULT_INPUTS',
    inputs: inputs,
})

export const loadInputs = (inputs) => ({
    type: 'LOAD_INPUTS',
    inputs: inputs,
})

export const addInput = (type) => ({
    type: 'ADD_INPUT',
    iType: type,
})

export const deleteInput = (id) => ({
    type: 'DELETE_INPUT',
    id: id,
})

export const inputPropChange = (id, name, value) => ({
    type: 'INPUT_PROP_CHANGE',
    id: id,
    name: name,
    value: value,
})

export const reorderInputs = (inputs) => ({
    type: 'REORDER_INPUTS',
    inputs: inputs
})

