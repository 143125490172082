import React from 'react';
import { FormControl, Box, RadioGroup, FormControlLabel, Radio, FormLabel } from '@material-ui/core'

class RadioInput extends React.Component {

    render() {
        return (
            <FormControl fullWidth margin='none'>
                <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                    <FormLabel style={{ position: "relative", margin: "0", transform: "none", fontSize: "small" }}>{this.props.label}</FormLabel>
                    <RadioGroup row={this.props.row} onChange={this.props.onChange} name={this.props.name} value={this.props.value}>
                        {
                            this.props.options.map(o => (
                                <FormControlLabel value={o.value} control={<Radio />} label={o.name} />
                            ))
                        }
                    </RadioGroup>
                </Box>
            </FormControl>
        );
    }
}
export default RadioInput;