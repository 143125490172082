export const loadUserPreferences = (preferences) => ({
    type: 'LOAD_USER_PREFERENCES',
    preferences: preferences,
})

export const userPreferencesSettingChange = (name, value) => ({
    type: 'USER_PREFERENCES_SETTING_CHANGE',
    name: name,
    value: value,
})
