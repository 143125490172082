import React from 'react';
import { FormControl, InputLabel, MenuItem, ListSubheader } from '@material-ui/core'
import TextInput from '@material-ui/core/TextField';
class SelectInput extends React.Component {

    render() {
        let options = [];
        if (this.props.includeGroups) {
            options = [];
            for (let group of this.props.options) {
                options.push (<ListSubheader key={group.name}>{group.name}</ListSubheader>);
                for (let option of group.value) {
                    options.push(<MenuItem key={option.value} value={option.value}>{this.props.includeIcons && option.icon?(<span style={{marginRight:"10px"}}><i className={option.icon}></i></span>):""}{option.name}</MenuItem>)
                }
            }
        }
        else {
            options = this.props.options.map(o => {
                return (
                    <MenuItem key={o.value} value={o.value}>{o.name}</MenuItem>
                );
            })
        }
        return (
            <FormControl fullWidth margin='dense'>
                <TextInput
                    variant="outlined"
                    size="small"
                    select
                    value={String(this.props.value) || ""}
                    label={this.props.label}
                    name={this.props.name}
                    onChange={this.props.onChange}
                    SelectProps={{
                        MenuProps:{
                            transitionDuration: 0
                        }
                    }}
                    style={{transition: "all 0s ease-in-out"}}
                >
                    {options}
                </TextInput >
            </FormControl>
        );
    }
}
export default SelectInput;