const imageGenerator = undefined;

export const generateImage = async (params) => {
    try {
        return await imageGenerator.create({
            operation: "generate",
            ...params
        }, {})
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}

export const upscaleImage = async (image) => {
    try {
        return await imageGenerator.create({
            operation: "upscale",
            image
        }, {})
    }

    catch (e) {
        console.log(e);
        return undefined;
    }
}
