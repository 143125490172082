import * as BABYLON from 'babylonjs';
import { degreesToRadians } from '../../../utility/math';
import { _assetManager } from '../assets/assetManager';
import { updateBarycentrics } from '../shapes/shapeOptions/utility';
const HEIGHT_SCALE = 2000;

export const buildBars = (scene, gizmoManager, component, parent, videoInputs) => {
    let _component;
    let bars = [];
    let container;
    let material;
    let color;
    let glowColor;
    let glowLayer;

    const updateComponent = (component) => {
        _component = component;
        color = new BABYLON.Color3.FromHexString(component.material.colorHex).toColor4();
        glowColor = new BABYLON.Color3.FromHexString(component.glowColor || component.material.colorHex).toColor4();
        color.a = component.opacity;
        glowColor.a = component.opacity;
        // if (component.material.type == "image" && component.material.image) {
        //     _assetManager.get(component.material.image, scene).then(texture => {
        //         image = texture;
        //     })
        // }
        glowLayer = new BABYLON.GlowLayer(`${component.id}-glow`, scene);
        glowLayer.intensity = component.glow;
        container = new BABYLON.Mesh(component.id, scene);
        container.isVisible = component.visible;

        container.position.x = component.position.x
        container.position.y = component.position.y
        container.position.z = component.position.z

        container.scaling = BABYLON.Vector3.Zero();
        container.scaling.x = component.scaling.x
        container.scaling.y = component.scaling.y
        container.scaling.z = component.scaling.z

        container.rotation = BABYLON.Vector3.Zero();
        container.rotation.x = degreesToRadians(component.rotation.x);
        container.rotation.y = degreesToRadians(component.rotation.y);
        container.rotation.z = degreesToRadians(component.rotation.z);

        if (component.count && component.count > 0) {
            let bar;
            switch (component.shape) {
                case "box": {
                    bar = BABYLON.MeshBuilder.CreateBox(component.id, { width: component.width, depth: component.width, height: .01, updatable: true }, scene);
                    break;
                }
                case "sphere": {
                    bar = BABYLON.MeshBuilder.CreateSphere(component.id, { diameter: component.width, diameterY: .01, updatable: true }, scene);
                    break;
                }
                case "cylinder": {
                    bar = BABYLON.MeshBuilder.CreateCylinder(component.id, { diameter: component.width, height: .01, updatable: true }, scene);
                    break;
                }
                case "capsule": {
                    bar = BABYLON.MeshBuilder.CreateCapsule(component.id, { radius: component.width, height: 1, updatable: true }, scene);
                    break;
                }
            }

            switch (component.material.type) {
                // case "image": {
                //     material = new BABYLON.StandardMaterial(`${component.id}-material`, scene);
                //     material.diffuseTexture = image;
                //     // material.emissiveTexture = image;
                //     break;
                // }
                case "wireframe": {
                    material = new BABYLON.ShaderMaterial(`${component.id}-material}`, scene, {
                        vertex: "wireframe",
                        fragment: "wireframe",
                    },
                        {
                            attributes: ["position", "normal", "uv", "barycentric", "color"],
                            uniforms: ["worldViewProjection", "thickness"],
                            needAlphaBlending: true,
                            needAlphaTesting: true
                        });
                    let texture = new BABYLON.Texture("textures/amiga.jpg", scene);
                    material.setTexture("textureSampler", texture);
                    material.sideOrientation = BABYLON.Material.ClockWiseSideOrientation;
                    material.transparencyMode = BABYLON.Material.MATERIAL_ALPHATESTANDBLEND;
                    material.setFloat("thickness", component.material.wireframethickness);
                    let wireframeColor = new BABYLON.Color3.FromHexString(component.material.colorHex).toColor4();
                    wireframeColor.a = component.opacity;

                    if (parent) {
                        if (typeof parent.visibility != "undefined") wireframeColor.a *= parent.visibility;
                    }
                    material.setColor4("color", wireframeColor);
                    // material.emissiveColor = color;
                    material.diffuseColor = wireframeColor;
                    material.onBindObservable.add((mesh) => {
                        mesh.material.setColor4("color", mesh.material.diffuseColor);
                    });
                    break;
                }
                case "color": {
                    material = new BABYLON.StandardMaterial(`${component.id}-material`, scene);
                    // material.emissiveColor = color;
                    material.diffuseColor = color;
                    break;
                }
            }
            
            material.alpha = component.opacity;
            if (parent) {
                if (typeof parent?.visibility != "undefined") {
                    material.alpha = component.opacity * parent.visibility;
                }
            }

            bar.position.x = component.position.x;
            bar.position.y = component.position.y;
            bar.position.z = component.position.z;

            bar.rotation = BABYLON.Vector3.Zero();
            bar.rotation.x = degreesToRadians(component.rotation.x);
            bar.rotation.y = degreesToRadians(component.rotation.y);
            bar.rotation.z = degreesToRadians(component.rotation.z);

            bar.isVisible = component.visible;
            bar.convertToFlatShadedMesh();
            updateBarycentrics(bar);
            bar.material = material;

            glowLayer.addIncludedOnlyMesh(bar)
            //glowLayer.referenceMeshToUseItsOwnMaterial(bar);
            glowLayer.customEmissiveColorSelector = (mesh, subMesh, material, result) => {
                if (mesh.id == component.id) {
                    result.set(glowColor.r, glowColor.g, glowColor.b, glowColor.a)
                }
            }
                   
            bars.push(bar);
            bar.setParent(container);
            bar.visibility = container.visibility;
            for (let i = 1; i < component.count; i++) {
                let clone = bar.clone("bar" + i);
                clone.position.y = 0;
                clone.position.x = i * (component.width + component.spacing);
                clone.position.z = 0;
                glowLayer.addIncludedOnlyMesh(clone);
                bars.push(clone);
                clone.setParent(container);
                clone.visibility = container.visibility;
            }
        }

        gizmoManager.attachableMeshes.push(container);
        if (parent) {
            container.parent = parent;
            if (typeof parent?.visibility != "undefined") {
                material.alpha = component.opacity * parent.visibility;
            }        
        }
    }
    updateComponent(component);

    class BarsComponent {
        id = component.id;
        mesh = container;
        component = _component;
        parent = parent;
        dispose = () => {
            const index = gizmoManager.attachableMeshes.indexOf(container);
            if (index > -1) {
                gizmoManager.attachableMeshes.splice(index, 1);
            }

            container.parent = null;
            container.dispose();
            material.dispose();
            // image.dispose();
            glowLayer.dispose();
            bars.forEach(bar => bar.dispose());
            bars = [];

        }
        update = (component) => {
            this.dispose();
            updateComponent(component);
            this.mesh = container;
        }

        animate = (d) => {
            const capsuleScale = component.shape == "capsule" ? .01 : 1;
            if (d.frequency) {
                let data = d.frequency;
                var sliceWidth = component.width / data.length;
                let x = 0;
                let take = Math.trunc(data.length / component.count);
                for (var i = 0; i < component.count; i++) {

                    let v = 0;
                    for (var h = 0; h < take; h++) {
                        v += (data[h + (i * take)] / 255.0);
                    }
                    v = v / take;
                    var scale = (capsuleScale * ((v * component.height * HEIGHT_SCALE) / 2)) + (component.minHeight * 100 * capsuleScale);
                    x += sliceWidth;
                    if (bars[i])
                        bars[i].scaling.y = scale;
                }
            }
            else if (d.timeDomain) {
                let data = d.timeDomain;
                let sliceWidth = component.width / data.length;
                let x = 0;
                let take = Math.trunc(data.length / component.count);
                for (var i = 0; i < component.count; i++) {

                    let v = 0;
                    for (let h = 0; h < take; h++) {
                        v += Math.abs((data[h + (i * take)])) / 128;
                    }
                    v = v / take;
                    let scale = (capsuleScale * ((v * component.height * HEIGHT_SCALE) / 2)) + (component.minHeight * 100 * capsuleScale);
                    x += sliceWidth;
                    if (bars[i])
                        bars[i].scaling.y = scale;
                }
            }
            if (typeof parent?.visibility != "undefined") {
                material.alpha = component.opacity * parent.visibility;
            }
        }
    }

    return new BarsComponent();
}