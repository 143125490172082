import { VENUE_URL } from "./config";
import _api from "./serviceClient";

const _assets = {
    postFile: (file) => {
        var data = new FormData()
        data.append('file', file)
        return _api.postFile(VENUE_URL, `/Asset/SaveAsset`, data);
    },
    getAssetById: (id) => {

        return _api.get(VENUE_URL, `/Asset/GetAssetById?id=${id}`);
    },
    getAssetBase64: (id) => {

        return _api.get(VENUE_URL, `/Asset/GetAssetBase64?id=${id}`);
    },
    getFileUrl: (id) => {
        return _api.getUrl(VENUE_URL, `/Asset/GetAssetById?id=${id}`)
    }
}

export default _assets;