import React from 'react';
import { GetComponentIcon } from '../../factories/canvasData';

class DragPreview extends React.Component {

    render() {
        const style = {
            backgroundColor: "#B0B0B0",
            borderRadius: "4px",
            display: "inline-grid",
            gap: "8px",
            gridTemplateColumns: "auto auto",
            padding: "4px 8px",
        };

        const component = this.props.monitorProps.item.data;
        return (
            <div style={style}>
                <div>
                    <i className={GetComponentIcon(component.type)} />
                </div>
                <div>
                    <span>{component.name}</span>
                </div>
            </div>
        )

    }
}

export default DragPreview;