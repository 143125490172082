import React from 'react';
import * as $ from "jquery";
import { _renderClock } from '../../factories/renderClock';

class LineChart extends React.Component {
    doubleClick = false;
    _this = this;
    data = [];
    chart = undefined;
    setData = (data) => {
        this.props.onChange(data);
    }

    shouldComponentUpdate(newProps) {
        if (newProps.data != this.props.data) {
            if (this.chart) {
                this.data = newProps.data;
                this.chart.data.datasets[0].data = newProps.data;
                this.chart.update();
            }
        }
        return false;
    }

    componentWillUnmount() {
        if(this.props.showRenderClock){
            _renderClock.unsubscribe(this.props.id+"_chart")
        }
    }

    componentDidMount() {
        let ctx = $("#"+this.props.id);
        this.data = this.props.data;   
        this.chart = new window.Chart(ctx, {
            type: 'line',
            data: {
                datasets: [
                    { 
                        data: this.data,
                        backgroundColor: "#BBBBBB",
                        borderColor: "#BBBBBB",
                        fill: {
                            target: 'origin',
                            above: 'rgba(255, 255, 255, .2)',
                        },
                    }
                ]
            },
            options: {
                animation: {
                    duration: 0
                },
                responsive: true,
                plugins: {
                    legend: {
                        display:false
                    },
                    tooltip: {
                        enabled:false,
                    },
                    dragData: {
                        round: 0,
                        dragX: true,
                        onDragStart: (e, datasetIndex, index, value) => {

                        },
                        onDrag: (e, datasetIndex, index, value) => {
                            if (index == 0) {
                                value.x = 0;
                            }
                            if (index == this.data.length - 1) {
                                value.x = this.props.xmax;
                            }
                            if (index != this.data.length -1 && this.data[index+1].x <= value.x){
                                value.x = this.data[index+1].x - 1;
                               // return false;
                            }
                            if (index != 0 && this.data[index-1].x >= value.x) {
                                value.x = this.data[index-1].x + 1
                               // return false;
                            }
                        },
                        onDragEnd: (e, datasetIndex, index, value) => {
                            this.setData(this.data);
                        },
                    },
                },
                scales: {
                    x: {
                        axis: 'r',
                        type: 'linear',
                        display: false,
                        min: this.props.xmin,
                        max: this.props.xmax,
                        ticks: {
                            count: 11
                        },
                        grid: {
                            color: "rgba(255, 255, 255, .1)",
                            drawTicks: false,

                        }
                    },
                    y: {
                        display: false,
                        min: this.props.ymin,
                        max: this.props.ymax,
                        stepValue: 1,
                        ticks: {
                            count: 11
                        },
                        grid: {
                            color: "rgba(255, 255, 255, .1)",
                            drawTicks: false,
                        }
                    },
                },
                elements: {
                    point: {
                        radius: 8,
                        borderWidth: 0,
                        pointHoverRadius: 8,
                        pointHoverBackgroundColor: "#FFFFFF",
                        pointHoverBorderWidth: 0,
                    },
                },
            }
        });

        ctx.on('click', (e) => {
            if (this.doubleClick){
                this.doubleClick = false;
                var points = this.chart.getElementsAtEventForMode(e, 'index', { intersect: true }, false);
                let index = Number(points[0]?.index);

                if (!Number.isNaN(index) && index != 0 && index != this.data.length - 1) {
                    this.data.splice(index, 1);
                   // this.chart.update();
                    this.setData(this.data);
                }
                else if (Number.isNaN(index)) {
                    let x = Math.floor(this.chart.scales.x.getValueForPixel(e.offsetX));
                    let y = Math.floor(this.chart.scales.y.getValueForPixel(e.offsetY));
                    let index;
                    for (index = 0; index < this.data.length; index++) {
                        if (this.data[index].x > x){
                            break;
                        }
                    }
                    this.data.splice(index, 0, {x, y});
                    //this.chart.update();
                    this.setData(this.data);
                }
            }
            else {
                this.doubleClick = true;
                setTimeout(() => this.doubleClick = false, 500)
            } 
         });

         if (this.props.showRenderClock){
            _renderClock.subscribe(this.props.id+"_chart", (time) => {
                let height = $("#"+this.props.id).height();
                let loopTime = (((time/1000) / this.props.loopLength) % 1) * 100;
                let indicator = $("#"+this.props.id+"_renderClock");
                indicator.css({'margin-left' : loopTime+"%", "margin-top":"-"+height + "px", "height": height + "px"});
            })
        }
    }

    render() {
        return (
            <div>
                  <canvas id={this.props.id}></canvas>
                  <div style={{width:"100%"}}><div id={this.props.id+"_renderClock"} style={{borderLeft:"solid white 1px"}}></div></div>
            </div>
        );
    }
}

export default LineChart;