export const noise = []
for (let i = 0; i < 100000; i++) {
    noise.push(Math.random());
}

export const scramble = (morph, mesh) => {
    return (data) => {
        const inArray = mesh?.originalMeshPositions || data;
        for (let index = 0; index < data.length; index++) {
            data[index] = inArray[index] + (morph * (noise[index] * 2 - 1));
        }
    }
}
