import { VENUE_URL } from "./config";
import _api from "./serviceClient";
import { v4 as uuidv4 } from 'uuid';

const defaultVenueData = (id) => {
    return {
        venueId: id,
        components: undefined,
        shared: undefined,
        inputs: undefined,
    }
}

const _venueData = {
    getDefaultVenueData: (id) => {
        return defaultVenueData(id);
    },
    saveVenueData: (venueData, userId) => {
        venueData = {
            userId, 
            id: uuidv4(), 
            ...venueData,
            components: JSON.stringify(venueData.components),
            inputs: JSON.stringify(venueData.inputs),
            videoInputs: JSON.stringify(venueData.videoInputs),
            shared: JSON.stringify(venueData.shared),
        }
        return _api.post(VENUE_URL, `/VenueData/SaveVenueData`, venueData);
    },
    getVenueData: (id) => {
        const _this = this;
        return _api.get(VENUE_URL, `/VenueData/GetVenueData?venueId=${id}`).then(v => 
            {
                if (v) {
                    return {
                        ...v, 
                        components: JSON.parse(v.components), 
                        inputs: JSON.parse(v.inputs), 
                        videoInputs: JSON.parse(v.videoInputs), 
                        shared: JSON.parse(v.shared) 
                    };
                }
                return defaultVenueData(id);
            });
    },
    deleteVenueData: (id) => {
        return _api.delete(VENUE_URL, `/VenueData/DeleteVenueDataByVenueId?venueId=${id}`);
    },
}

export default _venueData;
