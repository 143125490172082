import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import { _assets } from '../../../factories/assets';
import { degreesToRadians } from '../../../utility/math';
import { _assetManager } from '../assets/assetManager';
import { bindMeshAndMaterial, getGlowLayer, getMaterial, updateMaterial } from '../material/material';
import { getAttributePropertyShapeValue } from '../shapes/shapeOptions/utility';
import { PHYSICS_SHAPE_TYPE_BOX, PHYSICS_SHAPE_TYPE_SPHERE } from '../../../factories/canvasData';

const updateMesh = (mesh, component, audioFactor, time) => {
    mesh.position.x = getAttributePropertyShapeValue(component, 'position', 'x', audioFactor, time)
    mesh.position.y = getAttributePropertyShapeValue(component, 'position', 'y', audioFactor, time)
    mesh.position.z = getAttributePropertyShapeValue(component, 'position', 'z', audioFactor, time)

    mesh.scaling = BABYLON.Vector3.Zero();
    mesh.scaling.x = getAttributePropertyShapeValue(component, 'scaling', 'x', audioFactor, time)
    mesh.scaling.y = getAttributePropertyShapeValue(component, 'scaling', 'y', audioFactor, time)
    mesh.scaling.z = getAttributePropertyShapeValue(component, 'scaling', 'z', audioFactor, time)

    mesh.rotation = BABYLON.Vector3.Zero();
    mesh.rotation.x = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'x', audioFactor, time))
    mesh.rotation.y = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'y', audioFactor, time))
    mesh.rotation.z = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'z', audioFactor, time))
}

export const buildModel = (scene, gizmoManager, component, parent, getVideoInputs) => {
    let _component = component;
    let mesh;
    let assetMeshWrapper;
    let material;
    let assetContainer;
    let modelSrc = component.asset;
    let color;
    let glowColor;
    let image;
    let bumpMap;
    let glowLayer;
    let videoTexture = { ref: undefined };
    let physics = undefined;

    const updateComponent = (component) => {
        const setMaterialCallback = () => {
            material = getMaterial(scene, parent, component, color, image, bumpMap, videoTexture, getVideoInputs);
            assetContainer.meshes.forEach(m => {
                bindMeshAndMaterial(component, m, material, glowLayer);
                if (component.material.type == "wireframe") {
                    glowLayer.referenceMeshToUseItsOwnMaterial(m);
                }
            })
        }
        _component = component;
        if (modelSrc != component.asset) {
            modelSrc = component.asset;
            modelComponent.disposeMesh();
            loadModel();
        }
        if (assetMeshWrapper) {
            if(mesh) {
                assetMeshWrapper.parent = null;
                mesh.dispose();
            }

            switch (component.physics?.physicsShape) {
                case PHYSICS_SHAPE_TYPE_SPHERE: {
                    mesh = BABYLON.MeshBuilder.CreateSphere(component.id, { diameterX: component.physics.width||1, diameterY:component.physics.height||1, diameterZ:component.physics.depth||1}, scene);
                    break;
                }
                case PHYSICS_SHAPE_TYPE_BOX:
                default: {
                    mesh = BABYLON.MeshBuilder.CreateBox(component.id, { width: component.physics?.width||1, height: component.physics?.height||1, depth: component.physics?.depth||1 }, scene);
                    
                }
            }
            mesh.id = component.id;
            mesh.isVisible = false;
            if (component.physics?.debug) {
                mesh.isVisible = true;
                mesh.material = new BABYLON.StandardMaterial(`${component.id}-physics-material}`, scene);
                mesh.material.diffuseColor = new BABYLON.Color3.White();
                mesh.material.wireframe = true;
            }
            assetMeshWrapper.parent = mesh;
            mesh.setEnabled(component.visible);
            updateMesh(mesh, component)

            if (component.physics?.enable) {
                
                physics = new BABYLON.PhysicsAggregate(mesh, BABYLON.PhysicsShapeType.MESH, { mass: component.physics?.mass || 0, friction: component.physics?.friction || 0, restitution: component.physics?.restitution || 0 }, scene);
                physics.body.disablePreStep = false;
            }
            color = new BABYLON.Color3.FromHexString(component.material.colorHex).toColor4();
            glowColor = new BABYLON.Color3.FromHexString(component.glowColor || component.material.colorHex).toColor4();
            color.a = component.opacity;
            glowColor.a = component.opacity;
            glowLayer = getGlowLayer(scene, component, mesh, glowColor);
            assetContainer.meshes.forEach(m => {
                glowLayer.addIncludedOnlyMesh(m);
            })

            if (component.material.type == "none") {          
                assetContainer.meshes.forEach(m => {
                    m.material = m.originalMaterial;
                })
            }
            else {
                let materialPromises = [];
                if ((component.material.type == "image" || component.material.type == "advanced") && component.material.image) {
                    let imagePromise = _assetManager.get(component.material.image, scene).then(texture => {
                        image = texture;
                    });
                    materialPromises.push(imagePromise);
                }
                if (component.material.type == "advanced" && component.material.bumpMap) {
                    let bumpMapPromise = _assetManager.get(component.material.bumpMap, scene).then(texture => {
                        bumpMap = texture;
                    });
                    materialPromises.push(bumpMapPromise);
                }

                Promise.all(materialPromises).then(setMaterialCallback);
            }

            gizmoManager.attachableMeshes.push(mesh);
            if (parent) {
                mesh.parent = parent;
                if (typeof parent.visibility != "undefined") mesh.visibility = parent.visibility;
            }
        }

        return mesh;
    }
    class ModelComponent {
        id = component.id;
        mesh = mesh;
        component = _component;
        gizmoActive = false;
        parent = parent;
        shouldDispose = false;
        dispose = () => {
            this.shouldDispose = true;
            this.disposeMesh();
        }
        disposeMesh = () => {
            this.disposeModifications();
            if (mesh) {
                mesh.parent = null;
                mesh.dispose();
                mesh = undefined;
            }
            if (assetContainer) {
                assetContainer.dispose();
                assetContainer = undefined;
            }
            assetMeshWrapper = undefined;
        }
        disposeModifications = () => {
            const index = gizmoManager.attachableMeshes.indexOf(mesh);
            if (index > -1) {
                gizmoManager.attachableMeshes.splice(index, 1);
            }
            if (videoTexture.ref) {
                videoTexture.ref.dispose();
                videoTexture.ref = undefined;
            }
            if (physics) {
                physics.dispose();
            }
            // if (image) {
            //     image.dispose();
            //     image = undefined;
            // }
            // if (bumpMap) {
            //     bumpMap.dispose();
            //     bumpMap = undefined;
            // }
            if (material) {
                material.dispose();
                material = undefined;
            }
            if (glowLayer) {
                glowLayer.dispose();
                glowLayer = undefined;
            }
        }
        update = (component) => {
            this.disposeModifications();
            updateComponent(component);
            this.mesh = mesh;
            this.component = _component;
        }
        animate = (d) => {
            if (material) {
                updateMaterial(component, material, glowLayer, d.audioFactor, d.currentTime, parent);
            }
            if (mesh) {
                updateMesh(mesh, component, d.audioFactor, d.currentTime);
            }
        }
    }

    const modelComponent = new ModelComponent();
    // const onLoadModel = (meshes) => {
    //     mesh = meshes[0]
    //     mesh.id = component.id + "-child";
    //     if (modelComponent.shouldDispose){          
    //         mesh.dispose();
    //         if (boundingMesh) {
    //             boundingMesh.dispose();
    //         }
    //         modelComponent.shouldDispose = false;
    //     }
    //     else {
    //         let boundingBox = mesh.getBoundingInfo().boundingBox;
    //         let avg = ((boundingBox.maximum.x-boundingBox.minimum.x)
    //         + (boundingBox.maximum.y-boundingBox.minimum.y)
    //         + (boundingBox.maximum.z-boundingBox.minimum.z)) / 3;
    //         let scale = 2/avg;
    //         mesh.scaling = BABYLON.Vector3.Zero();
    //         mesh.scaling.x = scale
    //         mesh.scaling.y = scale
    //         mesh.scaling.z = scale

    //         let center = boundingBox.centerWorld;
    //         mesh.locallyTranslate(new BABYLON.Vector3(-center.x, -center.y, -center.z));
    //         boundingMesh = BABYLON.MeshBuilder.CreateBox(component.id, {size:1}, scene);
    //         boundingMesh.id = component.id;
    //         boundingMesh.isVisible = false;
    //         modelComponent.update(component);
    //         mesh.parent = boundingMesh
    //     }
    // }
    const onLoadContainer = (container) => {

        if (modelComponent.shouldDispose) {
            container.dispose();
            if (mesh) {
                mesh.dispose();
            }
            modelComponent.shouldDispose = false;
        }
        else {
            let meshes = container.meshes;
            container.addAllToScene();
            assetContainer = container;
            assetMeshWrapper = BABYLON.MeshBuilder.CreateBox(component.id + "-wrapper", { size: 1 }, scene);
            let masterBoundingInfo = meshes[0].getBoundingInfo();
            for (let i = 0; i < meshes.length; i++) {
                let meshInModel = meshes[i];
                let geometry = assetContainer.geometries.find(g => g.uniqueId == meshInModel.uniqueId);
                if (geometry) {
                    meshInModel._geometry = geometry;
                }
                meshInModel.originalMaterial = assetContainer.materials.find(mat => Object.keys(mat.meshMap).indexOf(String(meshInModel.uniqueId)) != -1);
                meshInModel.id = component.id + "-child-" + i;
                let boundingInfo = meshInModel.getBoundingInfo();
                masterBoundingInfo.encapsulateBoundingInfo(boundingInfo)
                assetMeshWrapper.addChild(meshInModel);   
            }

            let boundingBox = masterBoundingInfo.boundingBox;
            let avg = ((boundingBox.maximum.x - boundingBox.minimum.x)
                + (boundingBox.maximum.y - boundingBox.minimum.y)
                + (boundingBox.maximum.z - boundingBox.minimum.z)) / 3;
            let scale = 2 / avg;
            assetMeshWrapper.id = component.id;
            assetMeshWrapper.isVisible = false;
            assetMeshWrapper.scaling = BABYLON.Vector3.Zero();
            assetMeshWrapper.scaling.x = scale
            assetMeshWrapper.scaling.y = scale
            assetMeshWrapper.scaling.z = scale
            let center = boundingBox.centerWorld;
            assetMeshWrapper.locallyTranslate(new BABYLON.Vector3(-center.x, -center.y, -center.z));
            meshes.push(assetMeshWrapper);

            modelComponent.update(component);
        }
    }
    const loadModel = () => {
        if (component.asset) {
            _assets.get(component.asset).then(asset => {
                if (asset) {
                    BABYLON.SceneLoader.LoadAssetContainer(
                        "",
                        asset.base64,
                        scene,
                        onLoadContainer,
                        undefined,
                        undefined,
                        `.${asset.extension}`
                    )
                }              
            })
        }
    }
    loadModel();

    return modelComponent;
}