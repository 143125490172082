import * as BABYLON from 'babylonjs';
import { FFTSIZE } from '../../../factories/canvasData';
import { degreesToRadians } from '../../../utility/math';

export const buildWaveform = (scene, gizmoManager, component, parent) => {
    let _component;
    let line;
    let color;
    let glowColor;
    let glowLayer;
    const updateComponent = (component) => {
        _component = component;
        component.opacity = typeof component.opacity === "undefined" ? 1 : component.opacity
        color = new BABYLON.Color3.FromHexString(component.lineColor).toColor4();
        glowColor = new BABYLON.Color3.FromHexString(component.glowColor || component.lineColor);
        glowLayer = new BABYLON.GlowLayer(`${component.id}-glow`, scene);
        glowLayer.intensity = component.glow;

        let vectors = [];
        let x = 0;
        var sliceWidth = component.width / FFTSIZE / 4;
        for (var i = 0; i < FFTSIZE / 4; i++) {
            vectors.push(new BABYLON.Vector3(x, 0, 0));
            x += sliceWidth;
        }

        line = BABYLON.MeshBuilder.CreateLineSystem(`${component.id}`, { lines: [vectors], updatable: true }, scene);
        glowLayer.addIncludedOnlyMesh(line)
        glowLayer.customEmissiveColorSelector = (mesh, subMesh, material, result) => {
            if (mesh == line) {
                result.r = glowColor.r;
                result.g = glowColor.g;
                result.b = glowColor.b;
                result.a = component.opacity;
            }
        }
        line.color = color;
        line.alpha = component.opacity
        line.isVisible = component.visible
        line.isVisible = component.visible;

        line.position.x = component.position.x
        line.position.y = component.position.y
        line.position.z = component.position.z

        line.scaling = BABYLON.Vector3.Zero();
        line.scaling.x = component.scaling.x
        line.scaling.y = component.scaling.y
        line.scaling.z = component.scaling.z

        line.rotation = BABYLON.Vector3.Zero();
        line.rotation.x = degreesToRadians(component.rotation.x);
        line.rotation.y = degreesToRadians(component.rotation.y);
        line.rotation.z = degreesToRadians(component.rotation.z);

        gizmoManager.attachableMeshes.push(line);
        if (parent) {
            line.parent = parent;
            if (typeof parent.visibility != "undefined") line.alpha *= parent.visibility;
        }
    }
    updateComponent(component);

    class WaveformComponent {
        id = component.id;
        mesh = line;
        component = _component;
        parent = parent;
        dispose = () => {
            const index = gizmoManager.attachableMeshes.indexOf(line);
            if (index > -1) {
                gizmoManager.attachableMeshes.splice(index, 1);
            }
            line.parent = null;
            line.dispose();
            glowLayer.dispose();
        }
        update = (component) => {
            this.dispose();
            updateComponent(component);
            this.mesh = line;
        }
        animate = (d) => {
            if (d.frequency) {
                let data = d.frequency;
                let vectors = [];
                var sliceWidth = component.width / data.length;
                let x = 0;
                for (var i = 0; i < data.length; i++) {
                    var v = (data[i] / 255.0);
                    var y = ((v * component.height) / 2);
                    vectors.push(new BABYLON.Vector3(x, y, 0));
                    x += sliceWidth;
                }
                line = BABYLON.MeshBuilder.CreateLineSystem(`${component.id}-line`, { lines: [vectors, []], instance: line }, scene);
            }
            else if (d.timeDomain) {
                let data = d.timeDomain;
                let vectors = [];
                var sliceWidth = component.width / data.length;
                let x = 0;
                for (var i = 0; i < data.length; i++) {
                    var v = (data[i] / 128.0);
                    var y = ((v * component.height) / 2);
                    vectors.push(new BABYLON.Vector3(x, y, 0));
                    x += sliceWidth;
                }
                line = BABYLON.MeshBuilder.CreateLineSystem(`${component.id}-line`, { lines: [vectors, []], instance: line }, scene);
            }
            if (typeof parent?.visibility != "undefined")  {
                line.alpha = component.opacity * parent.visibility;
            }
        }
    }

    return new WaveformComponent();
}