import React from "react";

class DragPlaceholder extends React.Component {
    render() {
        const style = {
            left: this.props.depth * 24,
            height: "1px",
            backgroundColor: "#B0B0B0",
            right: "0",
            width: "100%"
           // transform: "translateY(-50%)",
        }
        return (
            <div style={style}></div>
        )
    }
};

export default DragPlaceholder;