import '../../components/shaders/babylonShaders';
import * as BABYLON from 'babylonjs';
import { degreesToRadians } from '../../../utility/math';
import { BOX_TYPE, CAPSULE_TYPE, CYLINDER_TYPE, DISC_TYPE, GROUND_TYPE, ICOSPHERE_TYPE, PLANE_TYPE, POLYHEDRON_TYPE, SPHERE_TYPE, TORUSKNOT_TYPE, TORUS_TYPE } from '../../../factories/canvasData';
import { box, boxProperties } from './shapeOptions/box';
import { sphere, sphereProperties } from './shapeOptions/sphere';
import { cylinder, cylinderProperties } from './shapeOptions/cylinder';
import { capsule, capsuleProperties } from './shapeOptions/capsule';
import { torus, torusProperties } from './shapeOptions/torus';
import { torusknot, torusknotProperties } from './shapeOptions/torusknot';
import { polyhedron, polyhedronProperties } from './shapeOptions/polyhedron';
import { getShapeValue, getAttributePropertyShapeValue, updateBarycentrics } from './shapeOptions/utility';
import { plane, planeProperties } from './shapeOptions/plane';
import { disc, discProperties } from './shapeOptions/disc';
import { ground, groundProperties } from './shapeOptions/ground';
import { bindMeshAndMaterial, getGlowLayer, getMaterial, updateMaterial } from '../material/material';
import { scramble } from '../utility/noise';
import { _assets } from '../../../factories/assets';
import { _assetManager } from '../assets/assetManager';
import { icosphere, icosphereProperties } from './shapeOptions/icosphere';

const getMesh = (scene, component, audioFactor, time) => {
    switch (component.type) {
        case PLANE_TYPE: return plane(scene, component, audioFactor, time);
        case DISC_TYPE: return disc(scene, component, audioFactor, time);
        case GROUND_TYPE: return ground(scene, component, audioFactor, time);
        case BOX_TYPE: return box(scene, component, audioFactor, time);
        case SPHERE_TYPE: return sphere(scene, component, audioFactor, time);
        case ICOSPHERE_TYPE: return icosphere(scene, component, audioFactor, time);
        case CYLINDER_TYPE: return cylinder(scene, component, audioFactor, time);
        case CAPSULE_TYPE: return capsule(scene, component, audioFactor, time);
        case TORUS_TYPE: return torus(scene, component, audioFactor, time);
        case TORUSKNOT_TYPE: return torusknot(scene, component, audioFactor, time);
        case POLYHEDRON_TYPE: return polyhedron(scene, component, audioFactor, time);
        default: return undefined;
    }
}

const getShapeProperties = (component) => {
    switch (component.type) {
        case PLANE_TYPE: return planeProperties;
        case DISC_TYPE: return discProperties;
        case GROUND_TYPE: return groundProperties;
        case BOX_TYPE: return boxProperties;
        case SPHERE_TYPE: return sphereProperties;
        case ICOSPHERE_TYPE: return icosphereProperties;
        case CYLINDER_TYPE: return cylinderProperties;
        case CAPSULE_TYPE: return capsuleProperties;
        case TORUS_TYPE: return torusProperties;
        case TORUSKNOT_TYPE: return torusknotProperties;
        case POLYHEDRON_TYPE: return polyhedronProperties;
        default: return undefined;
    }
}

const createMesh = (scene, component, audioFactor, time, mesh = undefined) => {
    const shapeProperties = getShapeProperties(component);
    const forceUpdateMesh = component.time?.links?.some(l => shapeProperties.indexOf(l) != -1) || component.audio?.links?.some(l => shapeProperties.indexOf(l) != -1)
    if (!mesh || forceUpdateMesh) {
        mesh = getMesh(scene, component, audioFactor, time);
        mesh.originalMeshPositions = [...mesh.getVerticesData("position")];
    }
    mesh.isVisible = component.visible;

    mesh.position.x = getAttributePropertyShapeValue(component, 'position', 'x', audioFactor, time)
    mesh.position.y = getAttributePropertyShapeValue(component, 'position', 'y', audioFactor, time)
    mesh.position.z = getAttributePropertyShapeValue(component, 'position', 'z', audioFactor, time)

    mesh.scaling = BABYLON.Vector3.Zero();
    mesh.scaling.x = getAttributePropertyShapeValue(component, 'scaling', 'x', audioFactor, time)
    mesh.scaling.y = getAttributePropertyShapeValue(component, 'scaling', 'y', audioFactor, time)
    mesh.scaling.z = getAttributePropertyShapeValue(component, 'scaling', 'z', audioFactor, time)

    mesh.rotation = BABYLON.Vector3.Zero();
    mesh.rotation.x = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'x', audioFactor, time))
    mesh.rotation.y = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'y', audioFactor, time))
    mesh.rotation.z = degreesToRadians(getAttributePropertyShapeValue(component, 'rotation', 'z', audioFactor, time))

    return mesh;
}

export const buildShape = (scene, gizmoManager, component, parent, getVideoInputs) => {
    let _component;
    let mesh;
    let color;
    let glowColor;
    let image;
    let bumpMap;
    let material;
    let glowLayer;
    let videoTexture = { ref: undefined };
    let physics;

    const setMaterialCallback = () => {
        if (material) {
            material.dispose();
        }
        material = getMaterial(scene, parent, component, color, image, bumpMap, videoTexture, getVideoInputs);
        bindMeshAndMaterial(component, mesh, material, glowLayer);
    }
    const updateComponent = (component, audioFactor) => {
        _component = component;
        color = new BABYLON.Color3.FromHexString(component.material.colorHex).toColor4();
        glowColor = new BABYLON.Color3.FromHexString(component.glowColor || component.material.colorHex).toColor4();
        color.a = component.opacity;
        glowColor.a = component.opacity;
        mesh = createMesh(scene, component, audioFactor);
        if (component.physics?.enable) {
            physics = new BABYLON.PhysicsAggregate(mesh, BABYLON.PhysicsShapeType.CONVEX_HULL, { mass: component.physics?.mass || 0, friction: component.physics?.friction || 0, restitution: component.physics?.restitution || 0 }, scene);
            physics.body.disablePreStep = false;
        }

        glowLayer = getGlowLayer(scene, component, mesh, glowColor);
        let morph = getShapeValue(component, 'morph');
        if (morph) {
            mesh.updateMeshPositions(scramble(morph, mesh))
        }
        gizmoManager.attachableMeshes.push(mesh);
        if (parent) {
            mesh.parent = parent;
            if (typeof parent.visibility != "undefined") mesh.visibility = parent.visibility;
        }

        let materialPromises = [];
        if ((component.material.type == "image" || component.material.type == "advanced") && component.material.image) {         
            let imagePromise = _assetManager.get(component.material.image, scene).then(texture => {
                image = texture;
            });
            materialPromises.push(imagePromise);
        }
        if (component.material.type == "advanced" && component.material.bumpMap) {
           let bumpMapPromise = _assetManager.get(component.material.bumpMap, scene).then(texture => {
                bumpMap = texture;
            });
            materialPromises.push(bumpMapPromise);
        }

        Promise.all(materialPromises).then(setMaterialCallback);
    }
    updateComponent(component);

    class ShapeComponent {
        id = component.id;
        mesh = mesh;
        component = _component;
        gizmoActive = false;
        parent = parent;
        updateMesh = (mesh, audioFactor, time) => {
            glowLayer.removeIncludedOnlyMesh(this.mesh);
            glowLayer.unReferenceMeshFromUsingItsOwnMaterial(this.mesh);
            const index = gizmoManager.attachableMeshes.indexOf(this.mesh);
            if (index > -1) {
                gizmoManager.attachableMeshes.splice(index, 1);
            }

            if (this.mesh != mesh) {
                this.mesh.dispose();
            }

            if (physics) {
                physics.dispose();
            }

            bindMeshAndMaterial(component, mesh, material, glowLayer, true);
            glowLayer.addIncludedOnlyMesh(mesh);
            let morph = getShapeValue(component, 'morph', audioFactor, time);
            if (morph) {
                mesh.updateMeshPositions(scramble(morph, mesh))
            }
            gizmoManager.attachableMeshes.push(mesh);
            if (parent) {
                mesh.parent = parent;
                if (typeof parent.visibility != "undefined") mesh.visibility = parent.visibility;
            }

            if (component.physics?.enable) {
                physics = new BABYLON.PhysicsAggregate(mesh, BABYLON.PhysicsShapeType.CONVEX_HULL, { mass: component.physics?.mass || 0, friction: component.physics?.friction || 0, restitution: component.physics?.restitution || 0 }, scene);
                physics.body.disablePreStep = false;
            }

            this.mesh = mesh;
        }
        dispose = () => {
            const index = gizmoManager.attachableMeshes.indexOf(mesh);
            if (index > -1) {
                gizmoManager.attachableMeshes.splice(index, 1);
            }
            if (videoTexture.ref) {
                videoTexture.ref.dispose();
            }

            if (physics) {
                physics.dispose();
            }
            // if (image) {
            //     image.dispose();
            // }
            // if (bumpMap) {
            //     bumpMap.dispose();
            // }
            mesh.parent = null;
            mesh.dispose();
            if (material) {
                material.dispose();
            }
            glowLayer.dispose();
        }
        update = (component) => {
            this.dispose();
            updateComponent(component);
            this.mesh = mesh;
            this.component = _component;
        }
        animate = (data) => {
            updateMaterial(component, material, glowLayer, data.audioFactor, data.currentTime, parent);
            mesh = createMesh(scene, component, data.audioFactor, data.currentTime, this.mesh);
            this.updateMesh(mesh, data.audioFactor, data.currentTime);
        }
    }

    return new ShapeComponent();
}