import Dexie from 'dexie';

const DB_NAME = "exec";
const ASSET_TABLE = "assets";
const db = new Dexie(DB_NAME);
db.version(1).stores({
  [ASSET_TABLE]: "id",
});

export const _assetCache = {
  add: (asset) => {
    return db[ASSET_TABLE].put(asset);
  },
  get: async (id) => {
    return db[ASSET_TABLE].get({id});
  }
}
