import { v4 as uuidv4 } from 'uuid';
import { FONTS } from '../utility/fonts';

export const FFTSIZE = 4096;

export const GROUP_TYPE = "group";
export const LAYER_TYPE = "layer";
export const BARS_TYPE = "spectrum";
export const WAVEFORM_TYPE = "waveform";
export const PLANE_TYPE = "plane";
export const DISC_TYPE = "disc";
export const GROUND_TYPE = "ground";
export const BOX_TYPE = "box";
export const ICOSPHERE_TYPE = "icosphere";
export const SPHERE_TYPE = "sphere";
export const CYLINDER_TYPE = "cylinder";
export const CAPSULE_TYPE = "capsule";
export const TORUS_TYPE = "torus";
export const TORUSKNOT_TYPE = "torusKnot";
export const POLYHEDRON_TYPE = "polyhedron";
export const TEXT_TYPE = "text";
export const MODEL_TYPE = "model";
export const PARTICLES_TYPE = "particles";
export const TERRAIN_TYPE = "terrain";

export const ASSET_TYPE_3D = "3d";
export const ASSET_TYPE_IMG = "img";

export const NestedTypes = [
    GROUP_TYPE,
    LAYER_TYPE
];

export const IsNestedType = (type) => {
    return NestedTypes.indexOf(type) != -1;
}

export const GetComponentIcon = (type) => {
    switch (type) {
        case GROUP_TYPE: {
            return "fas fa-th";
        }
        case LAYER_TYPE: {
            return "fas fa-layer-group";
        }
        case PARTICLES_TYPE: {
            return "fas fa-ellipsis-v";
        }
        case TERRAIN_TYPE: {
            return "fas fa-mountain";
        }
        case BARS_TYPE: {
            return "fas fa-sm fa-flip-horizontal fa-signal";
        }
        case WAVEFORM_TYPE: {
            return "fas fa-wave-square";
        }
        case PLANE_TYPE: {
            return "fas fa-square";
        }
        case DISC_TYPE: {
            return "fas fa-circle";
        }
        case GROUND_TYPE: {
            return "fas fa-square";
        }
        case BOX_TYPE: {
            return "fas fa-cube";
        }
        case SPHERE_TYPE: {
            return "fas fa-globe";
        }
        case ICOSPHERE_TYPE: {
            return "fas fa-globe";
        }
        case CYLINDER_TYPE: {
            return "fas fa-database";
        }
        case CAPSULE_TYPE: {
            return "fas fa-capsules";
        }
        case TORUS_TYPE: {
            return "far fa-circle";
        }
        case TORUSKNOT_TYPE: {
            return "fas fa-link";
        }
        case POLYHEDRON_TYPE: {
            return "fas fa-gem";
        }
        case TEXT_TYPE: {
            return "fas fa-font";
        }
        case MODEL_TYPE: {
            return "fas fa-cubes";
        }
        default: {
            return "";
        }
    }
}

export const ComponentTypes = [
    {
        name: "shapes",
        value: [
            {
                name: "plane",
                value: PLANE_TYPE,
                icon: GetComponentIcon(PLANE_TYPE),
            },
            {
                name: "disc",
                value: DISC_TYPE,
                icon: GetComponentIcon(DISC_TYPE),
            },
            {
                name: "ground",
                value: GROUND_TYPE,
                icon: GetComponentIcon(GROUND_TYPE),
            },
            {
                name: "box",
                value: BOX_TYPE,
                icon: GetComponentIcon(BOX_TYPE),
            },
            {
                name: "sphere",
                value: SPHERE_TYPE,
                icon: GetComponentIcon(SPHERE_TYPE),
            },
            {
                name: "icosphere",
                value: ICOSPHERE_TYPE,
                icon: GetComponentIcon(ICOSPHERE_TYPE),
            },
            {
                name: "cylinder",
                value: CYLINDER_TYPE,
                icon: GetComponentIcon(CYLINDER_TYPE),
            },
            {
                name: "capsule",
                value: CAPSULE_TYPE,
                icon: GetComponentIcon(CAPSULE_TYPE),
            },
            {
                name: "polyhedron",
                value: POLYHEDRON_TYPE,
                icon: GetComponentIcon(POLYHEDRON_TYPE),
            },
            {
                name: "torus",
                value: TORUS_TYPE,
                icon: GetComponentIcon(TORUS_TYPE),
            },
            {
                name: "torus knot",
                value: TORUSKNOT_TYPE,
                icon: GetComponentIcon(TORUSKNOT_TYPE),
            },

        ]
    },
    {
        name: "structure",
        value: [
            {
                name: "group",
                value: GROUP_TYPE,
                icon: GetComponentIcon(GROUP_TYPE),
            },
            // {
            //     name: "layer",
            //     value: LAYER_TYPE,
            //     icon: GetComponentIcon(LAYER_TYPE),
            // },
        ]
    },
    {
        name: "special",
        value: [
            {
                name: "text",
                value: TEXT_TYPE,
                icon: GetComponentIcon(TEXT_TYPE),
            },
            {
                name: "model",
                value: MODEL_TYPE,
                icon: GetComponentIcon(MODEL_TYPE),
            },
            {
                name: "particles",
                value: PARTICLES_TYPE,
                icon: GetComponentIcon(PARTICLES_TYPE),
            },
            {
                name: "terrain",
                value: TERRAIN_TYPE,
                icon: GetComponentIcon(TERRAIN_TYPE),
            },
        ]
    },
    {
        name: "graphs",
        value: [
            {
                name: "spectrum",
                value: BARS_TYPE,
                icon: GetComponentIcon(BARS_TYPE),
            },
            {
                name: "waveform",
                value: WAVEFORM_TYPE,
                icon: GetComponentIcon(WAVEFORM_TYPE),
            },
        ]
    },
];

const PhysicsShapeTypes = {
    SPHERE: 0,
    CAPSULE: 1,
    CYLINDER: 2,
    BOX: 3,
    CONVEX_HULL: 4,
    CONTAINER: 5,
    MESH: 6,
    HEIGHTFIELD: 7
}

const vector3 = (x, y, z) => {
    return { x: x || 0, y: y || 0, z: z || 0 };
}

export const getDefaultFilterData = () => [
    {
        x: 0,
        y: 100
    },
    {
        x: 512,
        y: 100
    },
    {
        x: 1024,
        y: 100
    }
];
const defaultLFO = () => {
    return [
        { x: 0, y: 0 },
        { x: 50, y: 100 },
        { x: 100, y: 0 }
    ]
}
const defaultTimeLFO = () => {
    return [
        { x: 0, y: 0 },
        { x: 100, y: 100 }
    ]
}
export const getDefaultTimeLFO = (name) => {
    switch (name) {
        case "position":
        case "scaling":
            return { x: defaultLFO(), y: defaultLFO(), z: defaultLFO() };
        case "rotation":
            return { x: defaultTimeLFO(), y: defaultTimeLFO(), z: defaultTimeLFO() };
        case "material":
            return { colorHex: defaultLFO(), wireframethickness: defaultLFO() }
        default: return defaultLFO();
    }
}
export const getDefaultTimeLFOTimes = (name) => {
    switch (name) {
        case "position":
        case "scaling":
        case "rotation":
            return { x: 10, y: 10, z: 10 };
        case "material":
            return { colorHex: 10, wireframethickness: 10 }
        default:
            return 10;
    }
}
export const getDefaultTimeLinkValues = (name, values) => {
    let result = typeof values === 'object' ? { ...values } : values;
    return result;
}
const audio = (options) => {
    return { input: options.input || 0, data: options.data || "frequency", filter: options.filter || "none", filterData: options.filterData || getDefaultFilterData(), links: options.links || [], linkValues: {} }
}
const time = (options) => {
    return {
        links: options?.links || [],
        linkValues: {},
        lfo: {},
        times: {},
    }
}

export const GetDefaultComponentFieldValue = (componentType, field) => {
    return GetComponentDefaultFieldValues(componentType)[field];
}

export const GetDefaultGroundComponent = () => {
    let ground = GetComponentDefaultFieldValues(GROUND_TYPE);
    ground.width = 100;
    ground.height = 100;
    ground.subdivisions = 64;
    ground.material.type = 'wireframe';
    ground.physics.enable = true;
    return ground;
}

export const GetComponentDefaultFieldValues = (type) => {
    let defaultValues = {
        id: uuidv4(),
        parentId: 0,
        name: type,
        type: type,
        visible: true,
        position: vector3(0, 0, 0),
        scaling: vector3(1, 1, 1),
        rotation: vector3(),
        opacity: 1,
    }
    let glowable = {
        glow: 0,
        glowColor: "#FFFFFF",
    }
    let morphable = {
        morph: 0,
    }
    let material = {
        type: "color", 
        colorHex: "#808080", 
        wireframethickness: 1, 
        colorGradient: [], 
        colorGradientInputs: [], 
        colorGradientBreakpoints: [],
        video: 0,
    }
    let physics = {
        enable: false,
        shapeType: 6, // BABYLON.PhysicsShapeType.MESH
        mass: 0,
        restitution: 0,
        friction: 0,
    }
    let shapeValues = {
        material: { ...material },
        ...glowable,
        ...morphable,
        physics: {...physics },
    }
    switch (type) {
        case GROUP_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                opacity: 1,
            }
        }
        case LAYER_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
            }
        }
        case TERRAIN_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                length: 1,
                width: 1,
                height: 1,
                subdivisions: 10,
                noiseVelocity: 0,
                noiseOffset: 0,
                ...shapeValues,
            }
        }
        case PARTICLES_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                image: "",
                emitterType: { type:"sphere" },
                minSize: 1,
                maxSize: 1,
                minSpeed: 1,
                maxSpeed: 1,
                minLifetime: 5,
                maxLifetime: 5,
                rate: 10,
                color1: "#FFFFFF",
                color2: "#FFFFFF",
                color3: "#FFFFFF",
                opacity: 1,
            }
        }
        case MODEL_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                asset: "",
                material: { ...material, type:"none", override: false },
                ...glowable,
                physics: { ...physics },
            }
        }
        case BARS_TYPE: {
            return {
                ...defaultValues,
                position: vector3(-3, 0, 0),
                animatesWithoutLinks: true,
                audio: audio({ data: "frequency" }),
                time: time(),
                shape: "box",
                material: { type: "color", colorHex: "#808080", wireframethickness: 1 },
                count: 32,
                spacing: .1,
                height: .1,
                width: .1,
                minHeight: .1,
                ...glowable,
            }
        }
        case WAVEFORM_TYPE: {
            return {
                ...defaultValues,
                position: vector3(-2, 0, 0),
                animatesWithoutLinks: true,
                audio: audio({ data: "time domain" }),
                time: time(),
                lineColor: "#808080",
                height: 10,
                width: 4,
                thickness: 1,
                opacity: 1,
                ...glowable,
            }
        }
        case BOX_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                height: 1,
                width: 1,
                depth: 1,
                ...shapeValues,
            }
        }
        case PLANE_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                width: 1,
                height: 1,
                ...shapeValues,
            }
        }
        case DISC_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                radius: 0.5,
                tessellation: 64,
                arc: 1,
                ...shapeValues,
            }
        }
        case GROUND_TYPE: {
            return {
                ...defaultValues,
                position: vector3(0, -1, 0),
                audio: audio({ data: "frequency" }),
                time: time(),
                width: 5,
                height: 5,
                subdivisions: 1,
                ...shapeValues,
            }
        }
        case SPHERE_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                segments: 32,
                diameterX: 1,
                diameterY: 1,
                diameterZ: 1,
                arc: 1,
                slice: 1,
                ...shapeValues,
            }
        }
        case ICOSPHERE_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                subdivisions: 4,
                radiusX: .5,
                radiusY: .5,
                radiusZ: .5,
                ...shapeValues,
            }
        }
        case CYLINDER_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                height: 2,
                diameterTop: 1,
                diameterBottom: 1,
                tessellation: 24,
                subdivisions: 1,
                arc: 1,
                ...shapeValues,
            }
        }
        case CAPSULE_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                height: 2,
                radiusTop: .25,
                radiusBottom: .25,
                tessellation: 16,
                subdivisions: 2,
                capSubdivisions: 6,
                arc: 1,
                ...shapeValues,
            }
        }
        case TORUS_TYPE: {
            return {
                ...defaultValues,
                rotation: vector3(90, 0, 0),
                audio: audio({ data: "frequency" }),
                time: time(),
                diameter: 1,
                thickness: .5,
                tessellation: 16,
                ...shapeValues,
            }
        }
        case TORUSKNOT_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                radius: 2,
                thickness: .5,
                radialSegments: 32,
                tubularSegments: 32,
                twist1: 2,
                twist2: 3,
                ...shapeValues,
            }
        }
        case POLYHEDRON_TYPE: {
            return {
                ...defaultValues,
                audio: audio({ data: "frequency" }),
                time: time(),
                complexity: 0,
                width: 1,
                height: 1,
                depth: 1,
                ...shapeValues,
            }
        }
        case TEXT_TYPE: {
            return {
                ...defaultValues,
                position: vector3(-1, 0, 0),
                rotation: vector3(-90, 0, 0),
                audio: audio({ data: "frequency" }),
                time: time(),
                text: "text",
                font: FONTS[0],
                size: 1,
                depth: .01,
                ...shapeValues,
            }
        }

        default: {
            return {
                id: uuidv4(),
                name: type,
                type: type,
            }
        }
    }
}

export const GetComponentFields = (type) => {
    const defaultFields = [
        { name: "name", type: "text", isConstant: true },

    ];
    const geometricTransformFields = [
        { name: "position", type: "vector3", min: -20, max: 20 },
        { name: "scaling", type: "vector3", icon: "fas fa-ruler-vertical", min: -20, max: 20 },
        { name: "rotation", type: "vector3", icon: "fas fa-sync-alt", min: 0, max: 360 },
    ];
    const glowFields = [
        { name: "glow", type: "range", min: 0, max: 10, step: .01 },
        { name: "glowColor", type: "color" },
    ]
    const morphFields = [
        { name: "morph", type: "range", min: 0, max: 1, step: .01 },
    ]
    const materialFields = [
        { name: "material", type: "material" },
        { name: "opacity", type: "range", min: 0, max: 1, step: .01 },
    ]
    const physicsFields = [
        { name: "physics", type: "physics", isConstant: true },
    ]
    const shapeFields = [
        ...materialFields,
        ...glowFields,
        ...morphFields,
        ...physicsFields,
    ];

    switch (type) {
        case GROUP_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "opacity", type: "range", min: 0, max: 1, step: .01 },
            ]
        }
        case LAYER_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
            ]
        }
        case TERRAIN_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "subdivisions", type: "range", min: 2, max: 100, step: 1 },
                { name: "length", type: "range", min: 0.01, max: 100, step: .01 },
                { name: "width", type: "range", min: 0.01, max: 100, step: .01 },
                { name: "height", type: "range", min: 0.01, max: 10, step: .01 },
                { name: "noiseVelocity", type: "range", min: 0, max: 100, step: 1 },
                { name: "noiseOffset", type: "range", min: 0, max: 100, step: .001 },
                ...shapeFields,
            ]
        }
        case PARTICLES_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "image", type: "file", isConstant: true, assetType: ASSET_TYPE_IMG },
                { name: "emitterType", type: "particleEmitterType", isConstant: true },
                { name: "minSize", type: "range", min: 0.01, max: 10, step: .01, isConstant: true },
                { name: "maxSize", type: "range", min: 0.01, max: 10, step: .01, isConstant: true },
                { name: "minSpeed", type: "range", min: 0.01, max: 10, step: .01, isConstant: true },
                { name: "maxSpeed", type: "range", min: 0.01, max: 10, step: .01, isConstant: true },
                { name: "minLifetime", type: "range", min: 0.01, max: 10, step: .01, isConstant: true },
                { name: "maxLifetime", type: "range", min: 0.01, max: 10, step: .01, isConstant: true },
                { name: "rate", type: "range", min: 1, max: 100, step: 1, isConstant: true },
                // { name: "angleSpeed", type: "range2", min: 0, max: 100, step: .01 },
                // { name: "angleRotation", type: "range2", min: 0, max: 360, step: .01 },
                { name: "color1", type: "color", isConstant: true },
                { name: "color2", type: "color", isConstant: true },
                { name: "color3", type: "color", isConstant: true },
                { name: "opacity", type: "range", min: 0, max: 1, step: .01, isConstant: true },
            ]
        }
        case MODEL_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { 
                    name: "asset", 
                    type: "file", 
                    isConstant: true, 
                    assetType: ASSET_TYPE_3D 
                },
                ...materialFields,
                ...glowFields,
                ...physicsFields,
            ]
        }
        case BARS_TYPE: {
            return [
                ...defaultFields,
                { name: "position", type: "vector3", min: -20, max: 20, isConstant: true },
                { name: "scaling", type: "vector3", icon: "fas fa-ruler-vertical", min: -20, max: 20, isConstant: true },
                { name: "rotation", type: "vector3", icon: "fas fa-sync-alt", min: 0, max: 360, isConstant: true },
                { name: "audio", type: "audio", isConstant: true },
                { name: "shape", type: "select", options: ["box", "sphere", "cylinder", "capsule"], isConstant: true },
                { name: "material", type: "material", isConstant: true },
                { name: "opacity", type: "range", min: 0, max: 1, step: .01, isConstant: true },
                { name: "count", type: "range", min: 1, max: 256, isConstant: true },
                { name: "spacing", type: "range", min: 0, max: 10, step: .01, isConstant: true },
                { name: "height", type: "range", min: 0, max: 10, step: .01, isConstant: true },
                { name: "width", type: "range", min: 0.01, max: 10, step: .01, isConstant: true },
                { name: "minHeight", type: "range", min: 0, max: 10, step: .01, isConstant: true },
                { name: "glow", type: "range", min: 0, max: 10, step: .01, isConstant: true },
                { name: "glowColor", type: "color", isConstant: true },
            ]
        }
        case WAVEFORM_TYPE: {
            return [
                ...defaultFields,
                { name: "position", type: "vector3", min: -20, max: 20, isConstant: true },
                { name: "scaling", type: "vector3", icon: "fas fa-ruler-vertical", min: -20, max: 20, isConstant: true },
                { name: "rotation", type: "vector3", icon: "fas fa-sync-alt", min: 0, max: 360, isConstant: true },
                { name: "audio", type: "audio", isConstant: true },
                { name: "lineColor", type: "color", isConstant: true },
                { name: "opacity", type: "range", min: 0, max: 1, step: .01, isConstant: true },
                { name: "height", type: "range", min: 0, max: 100, isConstant: true },
                { name: "width", type: "range", min: 0, max: 20, isConstant: true },
                { name: "glow", type: "range", min: 0, max: 10, step: .01, isConstant: true },
                { name: "glowColor", type: "color", isConstant: true },
            ]
        }
        case PLANE_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "height", type: "range", min: .01, max: 100, step: .01 },
                { name: "width", type: "range", min: .01, max: 100, step: .01 },
                ...shapeFields,
            ]
        }
        case DISC_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "radius", type: "range", min: .01, max: 50, step: .01 },
                { name: "tessellation", type: "range", min: 1, max: 64, step: 1 },
                { name: "arc", type: "range", min: .01, max: 1, step: .01 },
                ...shapeFields,
            ]
        }
        case GROUND_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "height", type: "range", min: .01, max: 100, step: .01 },
                { name: "width", type: "range", min: .01, max: 100, step: .01 },
                { name: "subdivisions", type: "range", min: 1, max: 64, step: 1 },
                ...shapeFields,
            ]
        }
        case BOX_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "height", type: "range", min: .01, max: 100, step: .01 },
                { name: "width", type: "range", min: .01, max: 100, step: .01 },
                { name: "depth", type: "range", min: .01, max: 100, step: .01 },
                ...shapeFields,
            ]
        }
        case SPHERE_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "segments", type: "range", min: 1, max: 64, step: 1 },
                { name: "diameterX", type: "range", min: .01, max: 100, step: .01 },
                { name: "diameterY", type: "range", min: .01, max: 100, step: .01 },
                { name: "diameterZ", type: "range", min: .01, max: 100, step: .01 },
                { name: "arc", type: "range", min: .01, max: 1, step: .01 },
                { name: "slice", type: "range", min: .01, max: 1, step: .01 },
                ...shapeFields,
            ]
        }
        case ICOSPHERE_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "subdivisions", type: "range", min: 1, max: 32, step: 1 },
                { name: "radiusX", type: "range", min: .01, max: 20, step: .01 },
                { name: "radiusY", type: "range", min: .01, max: 20, step: .01 },
                { name: "radiusZ", type: "range", min: .01, max: 20, step: .01 },
                ...shapeFields,
            ]
        }
        case CYLINDER_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "height", type: "range", min: .01, max: 100, step: .01 },
                { name: "diameterTop", type: "range", min: .01, max: 100, step: .01 },
                { name: "diameterBottom", type: "range", min: .01, max: 100, step: .01 },
                { name: "tessellation", type: "range", min: 1, max: 64, step: 1 },
                { name: "subdivisions", type: "range", min: 1, max: 64, step: 1 },
                { name: "arc", type: "range", min: .01, max: 1, step: .01 },
                ...shapeFields,
            ]
        }
        case CAPSULE_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "height", type: "range", min: .01, max: 100, step: .01 },
                { name: "radiusTop", type: "range", min: .01, max: 1, step: .01 },
                { name: "radiusBottom", type: "range", min: .01, max: 1, step: .01 },
                { name: "tessellation", type: "range", min: 1, max: 64, step: 1 },
                { name: "subdivisions", type: "range", min: 1, max: 64, step: 1 },
                { name: "capSubdivisions", type: "range", min: 1, max: 64, step: 1 },
                ...shapeFields,
            ]
        }
        case TORUS_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "diameter", type: "range", min: .01, max: 100, step: .01 },
                { name: "thickness", type: "range", min: .01, max: 10, step: .01 },
                { name: "tessellation", type: "range", min: 3, max: 64, step: 1 },
                ...shapeFields,
            ]
        }
        case TORUSKNOT_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "radius", type: "range", min: .01, max: 10, step: .01 },
                { name: "thickness", type: "range", min: .25, max: 5, step: .01 },
                { name: "radialSegments", type: "range", min: 3, max: 64, step: 1 },
                { name: "tubularSegments", type: "range", min: 3, max: 64, step: 1 },
                { name: "twist1", type: "range", min: 1, max: 20, step: 1 },
                { name: "twist2", type: "range", min: 1, max: 20, step: 1 },
                ...shapeFields,
            ]
        }
        case POLYHEDRON_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "complexity", type: "range", min: 0, max: 14, step: 1 },
                { name: "height", type: "range", min: .01, max: 100, step: .01 },
                { name: "width", type: "range", min: .01, max: 100, step: .01 },
                { name: "depth", type: "range", min: .01, max: 100, step: .01 },
                ...shapeFields,
            ]
        }
        case TEXT_TYPE: {
            return [
                ...defaultFields,
                ...geometricTransformFields,
                { name: "audio", type: "audio", isConstant: true },
                { name: "text", type: "text", isConstant: true },
                { name: "font", type: "select", options: FONTS, isConstant: true },
                { name: "size", type: "range", min: .01, max: 10, step: .01, isConstant: true },
                { name: "depth", type: "range", min: .01, max: 10, step: .01, isConstant: true },
                { name: "material", type: "material" },
                { name: "opacity", type: "range", min: 0, max: 1, step: .01 },
                ...glowFields,
                { name: "morph", type: "range", min: 0, max: 1, step: .01, isConstant: true },
            ]
        }
        default: {
            return [
                { name: "name", type: "text" },
            ]
        }
    }
}

const defaultMaterialTypes = [
    { name: "color", value: "color" },
    { name: "image", value: "image" },
    { name: "wireframe", value: "wireframe" },
    { name: "video", value: "video" },
    { name: "advanced", value: "advanced" },
];
export const GetMaterialTypes = (componentType) => { 
    switch (componentType) {
        case "model": 
        return [
            { name: "none", value:"none" },
            ...defaultMaterialTypes
        ]
        case "spectrum": 
        return [
            { name: "color", value: "color" },
            { name: "wireframe", value: "wireframe" },
        ]
        default:
            return [
                ...defaultMaterialTypes
            ]
    }
}
export const GetParticleEmitterTypes = (componentType) => { 
    switch (componentType) {
        default:
            return [
                { name: "point", value: "point" },
                { name: "box", value: "box" },
                { name: "sphere", value: "sphere" },
                { name: "cylinder", value: "cylinder" },   
                { name: "cone", value: "cone" },   
            ]
    }
}

export const GetDefaultInputValue = (type) => {
    let result = {
        id: uuidv4(),
        type: type,
        volume: 1,
        smoothingTimeConstant: .9,
    }
    switch (type) {
        case "file":
            return {
                ...result,
                play: false,
                audioFileUrl: "",
                audioFileType: "",
                audioFileName: "",
                trackLength: 0,
                status: "nofile",
                currentTime: 0,
            }
        case "mic":
            return {
                ...result,
                deviceId: "",
                deviceName: "",
            }
    }
}

export const GetSupportedAssetTypes = (type) => {
    switch(type) {
        case ASSET_TYPE_3D : return ["stl", "glb"];
        case ASSET_TYPE_IMG : return ["jpg", "png", "webp"];
        default: return [];
    }
}

export const inputOptions = [
    {
        name: "file",
        value: "file"
    },
    {
        name: "microphone",
        value: "mic"
    },
]

export const GetDefaultVideoInputValue = (type) => {
    let result = {
        id: uuidv4(),
        type: type,
    }
    switch (type) {
        case "none":
            return {
                ...result,
            }
        case "webcam":
            return {
                ...result,
                deviceId: "",
                deviceName: "",
            }   
        case "file":
            return {
                ...result,
                deviceId: "",
                deviceName: "",
                play: false,
                videoFileUrl: "",
                videoFileType: "",
                videoFileName: "",
                trackLength: 0,
                status: "nofile",
                currentTime: 0,
            }   
        default : return {...result}
    }
}

export const videoInputOptions = [
    // {
    //     name: "none",
    //     value: "none"
    // },
    {
        name: "file",
        value: "file"
    },
    {
        name: "webcam",
        value: "webcam"
    },  
]

export const RECORD_FPS_OPTIONS = [
    {
        name: "120 FPS",
        value: 120
    },
    {
        name: "60 FPS",
        value: 60
    },
    {
        name: "30 FPS",
        value: 30
    },
    {
        name: "24 FPS",
        value: 24
    },
];

export const ASPECT_RATIO_OPTIONS = [
    {
        name: "dynamic",
        value: undefined,
    },
    {
        name: "HD Video 16:9",
        value: "16:9",
    },
    {
        name: "Standard Monitor 4:3",
        value: "4:3",
    },
    {
        name: "Classic Film 3:2",
        value: "3:2",
    },
    {
        name: "Ultrawide 21:9",
        value: "2.37:1",
    },
];

export const STREAM_DESTINATIONS = [
    {
        name: "none",
        value: undefined,
    },
    {
        name: "Exec",
        value: "exec",
    },
];

export const supportedAudioTypes = [
    "audio/x-wav",
    "audio/ogg",
    "audio/mpeg",
    "audio/wav"
]

export const supportedVideoTypes = [
    "video/webm",
    "video/mp4",
    "video/ogg",
]

export const CAMERA_TYPE_BIRDSEYE = "birdseye";
export const CAMERA_TYPE_FIRSTPERSON = "firstperson";
export const CAMERA_TYPES = [
    { value: CAMERA_TYPE_BIRDSEYE , name: "birds-eye" },
    { value: CAMERA_TYPE_FIRSTPERSON , name: "first-person" },
]

export const PHYSICS_SHAPE_TYPE_BOX = "box";
export const PHYSICS_SHAPE_TYPE_SPHERE = "sphere";
export const PHYSICS_SHAPE_TYPES = [
    { value: PHYSICS_SHAPE_TYPE_BOX , name: "box" },
    { value: PHYSICS_SHAPE_TYPE_SPHERE , name: "sphere" },
]
