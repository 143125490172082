import { getSupportedRecordingMimeTypes } from "../../app/utility/utility";

const STORAGE_KEY = "exec-user-preferences-settings";

let getDefaultSettings = () => {
    let defaultVideoFormats = getSupportedRecordingMimeTypes();   
    let defaultVideoFormat = 'video/webm'
    if (defaultVideoFormats.length){
        defaultVideoFormat = defaultVideoFormats[0].mime;
    }
    return {
        globalVolume: 1,
        displayFPS: true,
        fps: 30,
        videoFormat: defaultVideoFormat,
        streamVideoFormat: defaultVideoFormat,
    }   
}

const init = () => {
    let saved = localStorage.getItem(STORAGE_KEY);
    if (saved)
        saved = JSON.parse(saved);
    return saved || getDefaultSettings();
}

const reduce = (state, action) => {
    switch (action.type) {
        case 'LOAD_USER_PREFERENCES':
            if (action.preferences) {
                return {
                    ...getDefaultSettings(),
                    ...action.preferences,
                }
            }
            return state;
        case 'USER_PREFERENCES_SETTING_CHANGE':
            state[action.name] = action.value;
            return {
                ...state,
            }
        default:
            return state
    }
}

const sharedReducer = (state = init(), action) => {
    state = reduce(state, action);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    return state;
}
export default sharedReducer