import * as BABYLON from 'babylonjs';
import { getShapeValue } from "./utility";

export const icosphereProperties = [
    "subdivisions",
    "radiusX",
    "radiusY",
    "radiusZ",
]
export const icosphere = (scene, component, audioFactor, time) => {
    let options = {
        subdivisions: Math.floor(getShapeValue(component, 'subdivisions', audioFactor, time)),
        radiusX: getShapeValue(component, 'radiusX', audioFactor, time),
        radiusY: getShapeValue(component, 'radiusY', audioFactor, time),
        radiusZ: getShapeValue(component, 'radiusZ', audioFactor, time),
        updatable: true,
    }

    return BABYLON.MeshBuilder.CreateIcoSphere(component.id, options, scene);
}