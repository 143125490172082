import React from 'react';
import { Box, InputLabel } from '@material-ui/core'
import SelectInput from './selectbox';
import ColorInput from './color';
import SliderInput from './slider';
import FileInput from './file';
import { ASSET_TYPE_IMG, GetParticleEmitterTypes } from '../../factories/canvasData';
class ParticleEmitterType extends React.Component {
    setValue = (e) => {
        let value = e.target.value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        this.props.value[e.target.name] = value;
        this.props.onChange({ target: { name: 'particleEmitterType', value: this.props.value } });
    }
    render() {
        const particleTypes = GetParticleEmitterTypes(this.props.componentType)
        return (
            <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                <InputLabel style={{ fontSize: "small" }}>emitter</InputLabel>
                <SelectInput name="type" value={this.props.value.type} onChange={this.setValue} options={particleTypes} label="type" />
                {
                    this.props.value.type == "sphere"
                        ? (
                            <React.Fragment>
                                <SliderInput name="radius" value={this.props.value.radius} onChange={this.setValue} min={0.01} max={10} step={.01} label="radius" />
                            </React.Fragment>

                        )
                        : ""
                }
                {
                    this.props.value.type == "cylinder"
                        ? (
                            <React.Fragment>
                                <SliderInput name="radius" value={this.props.value.radius} onChange={this.setValue} min={0.01} max={10} step={.01} label="radius" />
                                <SliderInput name="radiusRange" value={this.props.value.radiusRange} onChange={this.setValue} min={0} max={1} step={.01} label="radius range" />
                                <SliderInput name="height" value={this.props.value.height} onChange={this.setValue} min={0.01} max={10} step={.01} label="height" />
                            </React.Fragment>

                        )
                        : ""
                }
                {
                    this.props.value.type == "cone"
                        ? (
                            <React.Fragment>
                                <SliderInput name="radius" value={this.props.value.radius} onChange={this.setValue} min={0.01} max={10} step={.01} label="radius" />
                                <SliderInput name="angle" value={this.props.value.angle} onChange={this.setValue} min={0} max={359} step={1} label="angle" />
                            </React.Fragment>

                        )
                        : ""
                }
                {
                    this.props.value.type == "box"
                        ? (
                            <React.Fragment>
                                <SliderInput name="length" value={this.props.value.length} onChange={this.setValue} min={0.01} max={10} step={.01} label="length" />
                                <SliderInput name="width" value={this.props.value.width} onChange={this.setValue} min={0.01} max={10} step={.01} label="width" />
                                <SliderInput name="height" value={this.props.value.height} onChange={this.setValue} min={0.01} max={10} step={.01} label="height" />
                            </React.Fragment>

                        )
                        : ""
                }
            </Box>
        );
    }
}
export default ParticleEmitterType;