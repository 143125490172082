class DirtyInputs {
    add = [];
    delete = [];
    update = [];
    shouldClearCanvas = false;
    clear = () => {
        this.add = [];
        this.delete = [];
        this.update = [];
        this.shouldClearCanvas = false;
    }
}

class AudioData {
    loaded = {};
    dirty = new DirtyInputs();
}

export class LoadedAudio {
    time = new LoadedAudioTime();
}

class LoadedAudioTime {
    subscriptions = [];
    update = (currentTime) => {
        this.subscriptions.forEach(s => { s(currentTime) })
    }
}

export const audioData = new AudioData();