import { COMPONENTS_STORAGE_KEY } from "../redux/reducers/componentsReducer";
import { SHARED_STORAGE_KEY } from "../redux/reducers/sharedReducer";

const HISTORY_KEY = "exec-venue-history";
const SHARED_STATE = "shared";
const COMPONENTS_STATE = "components";
const HISTORY_SIZE = 50;
const snapshot = (snapshot) => {
    let history = {
        index: 0,
        stack: [],
    };
    let historyJSON = sessionStorage.getItem(HISTORY_KEY);
    if (historyJSON) {
        history = JSON.parse(historyJSON);
        if (history.index > 0) {
            history.stack = history.stack.slice(history.index);
        }
    }
    
    history.index = 0;   
    history.stack.unshift(snapshot);
    history.stack = history.stack.slice(0, HISTORY_SIZE);
    sessionStorage.setItem(HISTORY_KEY, JSON.stringify(history));
}

const loadSnapshot = (snapshot, loadComponents, loadShared) => {
    for(let stateType of Object.keys(snapshot)){
        switch (stateType) {
            case COMPONENTS_STATE:
                loadComponents(snapshot[stateType]);
                break;
            case SHARED_STATE:
                loadShared(snapshot[stateType]);
                break;
        }
    }
}

export const _venueHistory = {
    hasHistory: () => {
        return Boolean(sessionStorage.getItem(HISTORY_KEY));
    },
    initSnapshot:(components, shared) => {
        sessionStorage.removeItem(HISTORY_KEY);
        snapshot({
            [COMPONENTS_STATE]: components,
            [SHARED_STATE]: shared,
        });
    },
    sharedSnapshot: (state) => {
        snapshot({
            [COMPONENTS_STATE]: JSON.parse(sessionStorage.getItem(COMPONENTS_STORAGE_KEY)),
            [SHARED_STATE]: state,
        });
    },
    componentsSnapshot: (state) => {
        snapshot({
            [COMPONENTS_STATE]: state,
            [SHARED_STATE]: JSON.parse(sessionStorage.getItem(SHARED_STORAGE_KEY)),
        });
    },
    undo:(loadComponents, loadShared) => {
        let historyJSON = sessionStorage.getItem(HISTORY_KEY);
        if (historyJSON) {
            let history = JSON.parse(historyJSON);
            history.index++
            let snapshot = history.stack[history.index];    
            loadSnapshot(snapshot, loadComponents, loadShared);
            sessionStorage.setItem(HISTORY_KEY, JSON.stringify(history));
        }
    },
    redo: (loadComponents, loadShared) => {
        let historyJSON = sessionStorage.getItem(HISTORY_KEY);
        if (historyJSON) {
            let history = JSON.parse(historyJSON);
            history.index--
            let snapshot = history.stack[history.index];
            loadSnapshot(snapshot, loadComponents, loadShared);
            sessionStorage.setItem(HISTORY_KEY, JSON.stringify(history));
        }
    },
    canRedo: () => {
        let historyJSON = sessionStorage.getItem(HISTORY_KEY);
        if (historyJSON) {
            let history = JSON.parse(historyJSON);
            return history.index > 0;
        }
    
        return false;
    },
    canUndo: () => {
        let historyJSON = sessionStorage.getItem(HISTORY_KEY);
        if (historyJSON) {
            let history = JSON.parse(historyJSON);
            return history.index < history.stack.length-1;
        }
    
        return false;
    } 
}
