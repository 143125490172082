import * as BABYLON from 'babylonjs';
import { getShapeValue } from "./utility";

export const groundProperties = [
    "height",
    "width",
    "subdivisions",
]
export const ground = (scene, component, audioFactor, time) => {
    let options = {
        height: getShapeValue(component, 'height', audioFactor, time),
        width: getShapeValue(component, 'width', audioFactor, time),
        subdivisions: Math.floor(getShapeValue(component, 'subdivisions', audioFactor, time)),
        updatable: true,
    }

    return BABYLON.MeshBuilder.CreateGround(component.id, options, scene);
}