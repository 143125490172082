import * as BABYLON from 'babylonjs';
import { v4 as uuidv4 } from 'uuid';

const keys = {}
window.addEventListener('keydown', e => {
    keys[e.keyCode] = true
    if (e.keyCode === 9) e.preventDefault()
})
window.addEventListener('keyup', e => {
    keys[e.keyCode] = false
    if (e.keyCode === 9) e.preventDefault()
})

let _head = undefined;

export class Player {
    id = uuidv4();
    body = undefined;
    physics = undefined;
    constructor(engine, scene, canvas, position) {
        const _this = this;
        this.scene = scene;
        this.engine = engine;
        this.canvas = canvas;
        const body = this.body = new BABYLON.MeshBuilder.CreateSphere("player-body", { diameter: 2 }, scene)
        body.position = position
        body.visibility = 0
        this.head = _head = new BABYLON.MeshBuilder.CreateSphere("player-head", { diameter: 2 }, scene)
        this.right = new BABYLON.MeshBuilder.CreateSphere("player-right", { diameter: 1 }, scene)
        this.right.position = new BABYLON.Vector3(-1, 0, 0)
        this.right.visibility = 0
        this.camera = new BABYLON.UniversalCamera("player-camera", new BABYLON.Vector3(0, 0, -1), scene)
        this.right.parent = this.head
        this.camera.parent = this.head
        this.physics = new BABYLON.PhysicsAggregate(body, BABYLON.PhysicsShapeType.SPHERE, { mass: 1, restitution: 0, friction: 0 }, scene)
        this.scene.registerBeforeRender(this.eventListener);
        scene.onPointerDown = e => canvas.requestPointerLock()
    }
    getFront() {
        const fuc = this.head.absolutePosition.subtract(this.camera.globalPosition)
        fuc.y = 0
        return fuc.normalize()
    }
    getBack() {
        const fuc = this.camera.globalPosition.subtract(this.head.absolutePosition)
        fuc.y = 0
        return fuc.normalize()
    }
    getRight() {
        const fuc = this.head.absolutePosition.subtract(this.right.absolutePosition)
        fuc.y = 0
        return fuc.normalize()
    }
    getLeft() {
        const fuc = this.right.absolutePosition.subtract(this.head.absolutePosition)
        fuc.y = 0
        return fuc.normalize()
    }
    isOnGround() {
        const info = this.scene.pickWithRay(new BABYLON.Ray(this.body.position, new BABYLON.Vector3(0, -1, 0)), e => {
            return !(e === this.body)
        })
        return info.hit && (Math.round(info.pickedPoint.y * 1000) / 1000 + 0.1) >= (this.body.getBoundingInfo().minimum.y + this.body.position.y)
    }
    

    updatePosition = () => {
        const _this = this;
        const data = {
            playerId: _this.id,
            position: {
                x: _this.body.position.x.toString(),
                y: _this.body.position.y.toString(),
                z: _this.body.position.z.toString(),
            },
        }

        // _venue.updatePosition(this.props.channelId, data);
    }

    eventListener = () => {
        this.head.position.x = this.body.position.x
        this.head.position.y = this.body.position.y + 5
        this.head.position.z = this.body.position.z

        const force = 2 * this.engine.getDeltaTime()
        if (document.pointerLockElement === document.getElementById('renderCanvas')) {
            if (this.isOnGround()) {
                let velocity = new BABYLON.Vector3(0, 0, 0)
                if (keys[32]) {
                    this.physics.body.applyImpulse(new BABYLON.Vector3(0, 5, 0), this.body.getAbsolutePosition())
                } else {
                    if (keys[87]) {
                        velocity = velocity.add(this.getFront())
                    }
                    if (keys[83]) {
                        velocity = velocity.add(this.getBack())
                    }
                    if (keys[65]) {
                        velocity = velocity.add(this.getLeft())
                    }
                    if (keys[68]) {
                        velocity = velocity.add(this.getRight())
                    }


                    this.physics.body.setLinearVelocity(velocity.multiplyByFloats(force, force, force))
                }
            }
            if (keys[9]) {
                this.body.position = new BABYLON.Vector3(0, 1, 0);
            }

            this.updatePosition();
        }
    }

    dispose = () => {
        this.scene.unregisterBeforeRender(this.eventListener);
        this.physics.dispose();
        this.body.dispose();
        this.head.dispose();
        this.right.dispose();
        this.scene.onPointerDown = _ => {}
    }
}

const mousemove = (e) => {
    _head.rotate(BABYLON.Axis.X, e.movementY / 1000, BABYLON.Space.LOCAL)
    _head.rotate(BABYLON.Axis.Y, e.movementX / 1000, BABYLON.Space.WORLD)
}

const pointerLockEventHandler = (e) => {  
    if (document.pointerLockElement === document.getElementById('renderCanvas')) {
        document.addEventListener("mousemove", mousemove, false);
    } else {
        document.removeEventListener("mousemove", mousemove, false);
    }
    
}
document.addEventListener('pointerlockchange', pointerLockEventHandler, false);
