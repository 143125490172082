import { API_URL } from "./config";
import _api from "./serviceClient";

const _users = {
    getUsers: () => {
        return _api.get(API_URL, `/User/GetUsers`);
    },
    getUserInfo: () => {
        return _api.get(API_URL, '/User/GetUserInfo');
    },
    updateProfileImage: (profileImageId) => {
        let payload = {
            profileImageId: profileImageId
        }
        return _api.post(API_URL, `/User/UpdateUserProfileImage`, payload);
    },
    getUserProfilePictureByUserIdUrl: (userId) => {
        return _api.getUrl(API_URL, `/User/GetUserProfilePictureByUserId?userId=${userId}`);
    },
    searchUserByUsername: (userName) => {
        return _api.get(API_URL, `/User/SearchUserByUsername?userName=${userName}`);
    },
}

export default _users;