import React from 'react';
import { FormControl, Box, InputLabel } from '@material-ui/core'
import SelectInput from './selectbox';
import TextInputBox from './textbox';
import ColorInput from './color';
import SliderInput from './slider';
import SwitchInput from './switch';
import FileInput from './file';
import { ASSET_TYPE_IMG, GetMaterialTypes } from '../../factories/canvasData';
class Material extends React.Component {
    setValue = (e) => {
        let value = e.target.value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        this.props.value[e.target.name] = value;
        this.props.onChange({ target: { name: 'material', value: this.props.value } });
    }
    render() {
        const videoInputs = [
            { name: "none", value: undefined },
            ...this.props.videoInputs,
        ]
        const materialTypes = GetMaterialTypes(this.props.componentType)
        return (
            <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                <InputLabel style={{ fontSize: "small" }}>material</InputLabel>
                <SelectInput name="type" value={this.props.value.type} onChange={this.setValue} options={materialTypes} label="type" />
                {
                    this.props.value.type == "color" || this.props.value.type == "wireframe"
                        ? (
                            <ColorInput type="color" name="colorHex" value={this.props.value.colorHex} onChange={this.setValue} label="color" />
                        )
                        : ""
                }
                {
                    this.props.value.type == "wireframe"
                        ? (
                            <SliderInput name="wireframethickness" value={this.props.value.wireframethickness} onChange={this.setValue} min={.01} max={20} step={.01} label="thickness" />
                        )
                        : ""
                }
                {
                    this.props.value.type == "image"
                        ? (
                            <FileInput name="image" value={this.props.value.image} onChange={this.setValue} label="image" assetType={ASSET_TYPE_IMG} userId={this.props.userId} />
                        )
                        : ""
                }
                {
                    this.props.value.type == "video"
                        ? (
                            <React.Fragment>
                                <SelectInput name="video" value={this.props.value.video} onChange={this.setValue} options={videoInputs} label="video input"/>
                                <SwitchInput name="chromakey" value={this.props.value.chromakey} onChange={this.setValue} label="chroma key filter"/>
                                {
                                    this.props.value.chromakey && (
                                        <React.Fragment>
                                            <ColorInput type="color" name="chromakeyColor" value={this.props.value.chromakeyColor || '#000000'} onChange={this.setValue} label="chroma key color" />
                                            <SliderInput name="chromakeyTolerance" value={this.props.value.chromakeyTolerance} onChange={this.setValue} min={0} max={1} step={.01} label="chroma key tolerance" />
                                        </React.Fragment>
                                    )
                                    
                                }
                            </React.Fragment>
                        )
                        : ""
                }
                {
                    this.props.value.type == "advanced"
                        ? (
                            <React.Fragment>
                                <ColorInput type="color" name="colorHex" value={this.props.value.colorHex} onChange={this.setValue} label="color" />
                                <FileInput name="image" value={this.props.value.image} onChange={this.setValue} label="image" assetType={ASSET_TYPE_IMG} userId={this.props.userId} />
                                <FileInput name="bumpMap" value={this.props.value.bumpMap} onChange={this.setValue} label="bump map" assetType={ASSET_TYPE_IMG} userId={this.props.userId} />
                                <SliderInput name="metallic" value={this.props.value.metallic} onChange={this.setValue} min={0} max={1} step={.01} label="metallic" />
                                <SliderInput name="roughness" value={this.props.value.roughness} onChange={this.setValue} min={0} max={1} step={.01} label="roughness" />
                                <SliderInput name="clearCoat" value={this.props.value.clearCoat} onChange={this.setValue} min={0} max={1} step={.01} label="clearCoat" />
                                <SliderInput name="iridescence" value={this.props.value.iridescence} onChange={this.setValue} min={0} max={1} step={.01} label="iridescence" />
                                <SliderInput name="anisotropy" value={this.props.value.anisotropy} onChange={this.setValue} min={0} max={1} step={.01} label="anisotropy" />
                            </React.Fragment>

                        )
                        : ""
                }
            </Box>
        );
    }
}
export default Material;