import React from 'react';
import { ComponentTypes, GetComponentDefaultFieldValues, GetDefaultInputValue, GROUP_TYPE } from '../../factories/canvasData';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { connect } from 'react-redux';
import { appSettingChange, appSettingsChange } from '../../../redux/actions/appSettingsActions';
import { loadDefaultShared, loadShared } from '../../../redux/actions/sharedActions';
import { addComponent, addDefinedComponents, loadDefaultComponents, loadComponents } from '../../../redux/actions/componentsDataActions';
import { Fab, Grid, Box, Button, CircularProgress } from '@material-ui/core'
import SelectInput from '../inputs/selectbox';
import InputsList from '../audioInputsEditor/inputsList';
import VideoInputsList from '../videoInputsEditor/inputsList';
import LayersIcon from '@material-ui/icons/Layers';
import VolumeUpIcon from '@material-ui/icons/SettingsInputComponent';
import SettingsIcon from '@material-ui/icons/Settings';
import LayerTree from '../layersEditor/layerTree';
import PanoramaIcon from '@material-ui/icons/Panorama';
import { Link, NavLink } from 'react-router-dom';
import TextInput from '@material-ui/core/TextField';
import _venue from '../../../services/venueService';
import SaveIcon from '@material-ui/icons/Save';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import _venueData from '../../../services/venueDataService';
import VideocamIcon from '@material-ui/icons/Videocam';
import BackgroundEditor from '../backgroundEditor/backgroundEditor';
import GlobalEditor from '../globalEditor/globalEditor';
import { v4 as uuidv4 } from 'uuid';
import { MOBILE_WIDTH } from '../../constants';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EditIcon from '@material-ui/icons/Edit';
import { _venueHistory } from '../../../services/venueHistoryService';
import { getDefaultSharedSettings } from '../../../redux/reducers/sharedReducer';
import { getDefaultComponentsSettings } from '../../../redux/reducers/componentsReducer';
import { _assets } from '../../factories/assets';
import { base64toFile } from '../../utility/utility';
import { MAIN_SITE_URL } from '../../../services/config';

// import Shepherd from 'shepherd.js';
// import "shepherd.js/dist/css/shepherd.css"; // import the stylesheet

class MainEditor extends React.Component {
    state = {
        open: true,
        file: "",
        isDragover: false,
        type: "spectrum",
        saving: false,
        isImportProjectDialogOpen: false,
    }

    selectComponentId = (id) => {
        this.props.appSettingChange('selectedComponentId', id);
    }

    toggleOpen = () => {
        this.setState({ open: !this.state.open })
    }

    selectType = (e) => {
        this.setState({ type: e.target.value });
    }

    addComponent = () => {
        this.props.addComponent(this.state.type);
    }

    appSettingChange = (e) => {
        let value = e.target.value;
        this.props.appSettingChange(e.target.name, value)
    }

    newProject = () => {
        const _this = this;
        _this.props.loadDefaultComponents();
        _this.props.loadDefaultShared();
        _venueHistory.initSnapshot(getDefaultComponentsSettings(), getDefaultSharedSettings());

        _this.props.appSettingsChange({
            projectId: undefined,
            projectDataId: undefined,
            projectLastModified: undefined,
            projectUserId: this.props.user?.userId || undefined,
            projectName: "new project",
        })
        this.props.history.push(`/build`)
    }

    copyProject = () => {
        const _this = this;
        const newProjectName = _this.props.appSettings.projectName + " (1)";
        _this.props.appSettings.projectId = undefined;
        _this.props.appSettings.projectDataId = undefined;
        _this.props.appSettings.projectUserId = undefined;
        _this.props.appSettings.projectName = newProjectName;
        _this.props.appSettings.access = "read";
        _this.props.appSettingsChange({
            projectId: undefined,
            projectDataId: undefined,
            projectUserId: undefined,
            projectName: newProjectName,
            access: "read",
        })
        _this.saveProject();
    }

    canUserSaveProject = () => {
        const _this = this;
        if (typeof _this.props.appSettings.projectUserId !== "undefined" && _this.props.user.userId != _this.props.appSettings.projectUserId) {
            return _this.props.appSettings.access == "write";
        }
        return true;
    }

    saveProject = () => {
        const _this = this;
        _this.props.canvasInterface.takeScreenshot(400, (data) => {
            let shouldCopy = !_this.canUserSaveProject();
            let inputs = JSON.parse(JSON.stringify(_this.props.inputs));
            let videoInputs = JSON.parse(JSON.stringify(_this.props.videoInputs));
            inputs.forEach(i => {
                const newInput = GetDefaultInputValue(i.type);
                newInput.smoothingTimeConstant = i.smoothingTimeConstant;
                newInput.volume = i.volume;
                Object.keys(i).forEach(key => {
                    delete i[key];
                })
                Object.keys(newInput).forEach(key => {
                    i[key] = newInput[key];
                })
            })
            _assets.create(base64toFile(data)).then(pictureInfo => {

                // videoInputs.forEach(i => {
                //     const newInput = GetDefaultVideoInputValue(i.type);
                //     Object.keys(i).forEach(key => {
                //         delete i[key];
                //     })
                //     Object.keys(newInput).forEach(key => {
                //         i[key] = newInput[key];
                //     })
                // })
                let project = {
                    id: shouldCopy ? uuidv4() : (_this.props.appSettings.projectId || uuidv4()),
                    name: _this.props.appSettings.projectName,
                    dateModified: new Date(),
                    userId: _this.props.user?.userId || undefined,
                    userDisplayName: _this.props.user?.displayName,
                    pictureId: pictureInfo.id,
                };
                let projectData = {
                    id: shouldCopy ? uuidv4() : (_this.props.appSettings.projectDataId || uuidv4()),
                    projectId: undefined,
                    components: _this.props.components,
                    inputs: inputs,
                    videoInputs: videoInputs,
                    shared: _this.props.shared,
                }

                this.setState({ saving: true })
                const productDataCB = (productData) => {
                    _this.props.appSettingChange("projectDataId", productData.id);
                    _this.setState({ saving: false });
                }
                const cb = (p) => {
                    _this.props.appSettingsChange({
                        projectId: p.id,
                        projectLastModified: p.dateModified,
                        projectUserId: p.userId,
                    })
                    if (projectData.id) {
                        _venueData.saveVenueData({
                            ...projectData,
                            venueId: p.id,
                        }, _this.props.user.userId).then(pd => productDataCB(pd));
                    }
                    else {
                        _venueData.saveVenueData({
                            ...projectData,
                            venueId: p.id,
                        }, _this.props.user.userId).then(pd => productDataCB(pd));
                    }

                }
                _venue.saveVenue(project, _this.props.user.userId).then(p => cb(p));
            })
        })
    }

    undo = () => {
        const _this = this;
        _venueHistory.undo(_this.props.loadComponents, _this.props.loadShared);
    }
    redo = () => {
        const _this = this;
        _venueHistory.redo(_this.props.loadComponents, _this.props.loadShared);
    }

    onImportProjectLayers = (projectId, projectName) => {
        _venueData.getVenueData(projectId).then((projectData) => {
            const components = projectData.components;
            const group = GetComponentDefaultFieldValues(GROUP_TYPE);
            group.id = group.id;
            group.name = projectName;
            const addChildren = (parentId, newParentId) => {
                let children = components.filter(c => c.parentId == parentId)
                for (let child of children) {
                    const newId = uuidv4();
                    addChildren(child.id, newId);
                    child.id = newId;
                    child.id = newId;
                    child.parentId = newParentId;
                }
            }
            addChildren(0, group.id);
            components.unshift(group);

            this.props.addDefinedComponents(components);
        })
    }

    componentDidMount() {
        const _this = this;
        // const tour = new Shepherd.Tour({
        //     defaultStepOptions: {
        //         useModalOverlay: true,
        //       scrollTo: true, // scrolls the page to the element
        //       showCancelLink: true, // displays a 'cancel' link for each step
        //       classes: 'shepherd-theme-arrows',
        //       arrow: true, // show arrow

        //     }
        //   });

        //   // add a step to the tour that points to the saveButton
        //   tour.addStep({
        //     id: 'save-button-step',
        //     text: 'Click this button to save your project.',
        //     attachTo: {
        //       element: '#saveButton',
        //       on: 'bottom'
        //     },
        //     buttons: [
        //       {
        //         text: 'Next',
        //         action: tour.next // move to the next step
        //       }
        //     ]
        //   });

        //   // start the tour
        //   tour.start();
    }

    render() {
        const icon = this.state.open ? <span style={{ fontSize: "1em", cursor: "pointer", color: 'white' }} className="material-icons">close</span> : <span style={{ fontSize: "1em", cursor: "pointer", color: 'white' }} className="material-icons">menu</span>
        const btnStyle = { outline: "none", width: "40px", height: "25px", minHeight: "20px" }
        const doesUserOwnProject = !this.props.appSettings.projectUserId || this.props.user.userId == this.props.appSettings.projectUserId;
        const canUserSaveProject = this.canUserSaveProject();
        const isMobileMode = window.innerWidth <= MOBILE_WIDTH;
        let menuWidth = 400;
        if (isMobileMode) {
            menuWidth = window.innerWidth;
        }
        const canUndo = _venueHistory.canUndo();
        const canRedo = _venueHistory.canRedo();
        console.log(MAIN_SITE_URL);
        return (
            <div id="main-menu" style={{ pointerEvents: "auto", border: "1px solid rgb(50,50,50)" }}>
                {isMobileMode || <div onClick={this.toggleOpen} style={{ transform: this.state.open ? `translate(${menuWidth - 50}px)` : "translate(0)", transition: "all 0.6s ease-in-out", position: "absolute", left: "0px", top: 0, width: "50px", height: "50px", textAlign: "center" }} className="closebtn">{icon}</div>}
                <a href={`${MAIN_SITE_URL}`}><img style={{ opacity: this.state.open ? "1" : "0", transition: "all 0.3s ease-in-out", position: "absolute", left: "5px", top: 5, width: "40px", height: "40px", textAlign: "center", zIndex: "100" }} src="/content/images/logo-transparent.png" alt="exec logo"></img></a>
                <div id="mySidenav" className="sidenav" style={{ width: this.state.open ? `${menuWidth}px` : "0px", transition: "all 0.6s ease-in-out", paddingTop: "30px", left: "0", overflowY: "hidden", display: "flex", flexDirection: "column" }}>
                    <Box p={1} style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                        <Tab.Container defaultActiveKey="layers">
                            <Nav justify variant="tabs" style={{ opacity: this.state.open ? "1" : "0", transition: "all 0.3s ease-in-out", marginTop: "15px", borderBottomColor: "rgb(50,50,50)" }}>
                                <Nav.Item>
                                    <Nav.Link style={{ border: "1px solid rgb(50,50,50)", paddingTop: "5px", paddingBottom: "5px" }} eventKey="layers"><EditIcon fontSize="small" /> venue</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link style={{ border: "1px solid rgb(50,50,50)", paddingTop: "5px", paddingBottom: "5px" }} eventKey="audio"><VolumeUpIcon fontSize="small" /> audio</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link style={{ border: "1px solid rgb(50,50,50)", paddingTop: "5px", paddingBottom: "5px" }} eventKey="video"><VideocamIcon fontSize="small" /> video</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link style={{ border: "1px solid rgb(50,50,50)", paddingTop: "5px", paddingBottom: "5px" }} eventKey="global"><SettingsIcon fontSize="small" /> settings</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content style={{ opacity: this.state.open ? "1" : "0", transition: "all 0.3s ease-in-out", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                <Tab.Pane eventKey="global">
                                    <GlobalEditor />
                                </Tab.Pane>
                                <Tab.Pane eventKey="audio">
                                    <InputsList />
                                </Tab.Pane>
                                <Tab.Pane eventKey="video">
                                    <Box p={1}>
                                        <VideoInputsList />
                                    </Box>
                                </Tab.Pane>
                                <Tab.Pane eventKey="layers" style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                    <Box p={1} style={{ opacity: this.state.open ? "1" : "0", transition: "all 0.3s ease-in-out", paddingLeft: "0", paddingRight: "0", display: "flex", flexDirection: "column" }}>
                                        <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                                            <Grid container justifyContent="space-between">
                                                <Grid item>
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item>
                                                            <Fab style={btnStyle} onClick={this.newProject} title="new"><i className="fa fa-plus"></i></Fab>
                                                        </Grid>
                                                        {
                                                            this.props.user.userId && canUserSaveProject
                                                                ? (
                                                                    <Grid item>
                                                                        <Fab style={btnStyle} onClick={this.copyProject} title="copy"><i className="fa fa-clone"></i></Fab>
                                                                    </Grid>
                                                                )
                                                                : ""
                                                        }
                                                        {
                                                            this.props.user.userId
                                                                ? (
                                                                    <Grid item>
                                                                        <a href={`${MAIN_SITE_URL}/channel-settings?tab=venues`}><Fab style={btnStyle} title="private venues"><i className="fas fa-user"></i></Fab></a>
                                                                    </Grid>
                                                                )
                                                                : ""
                                                        }

                                                        {/* 
                                                        <Grid item>
                                                            <Link to="/scenes"><Fab style={btnStyle} title="public projects"><i className="fas fa-globe"></i></Fab></Link>
                                                        </Grid> */}

                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item>
                                                            <Fab style={btnStyle} disabled={!canUndo} onClick={this.undo} title="undo"><i className="fas fa-undo"></i></Fab>
                                                        </Grid>
                                                        <Grid item>
                                                            <Fab style={btnStyle} disabled={!canRedo} onClick={this.redo} title="redo"><i className="fas fa-redo"></i></Fab>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                            <hr style={{ margin: "5px " }} />
                                            {
                                                this.props.user.userId
                                                    ? (
                                                        <Box>
                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item xs={9}>
                                                                    <TextInput
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        label="project name"
                                                                        value={this.props.appSettings.projectName}
                                                                        margin="dense"
                                                                        size="small"
                                                                        onChange={this.appSettingChange}
                                                                        disabled={!doesUserOwnProject}
                                                                        inputProps={{
                                                                            name: "projectName",
                                                                            type: "text",
                                                                        }}
                                                                    >
                                                                    </TextInput>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Button onClick={this.saveProject} id="saveButton" variant="outlined" fullWidth style={{ padding: "6px" }}>{this.state.saving ? <CircularProgress size="1em" /> : (canUserSaveProject ? <SaveIcon /> : <FileCopyIcon />)}</Button>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={1}>
                                                                    <i className="fas fa-history"></i>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    {
                                                                        this.props.appSettings.projectLastModified
                                                                            ? (
                                                                                <React.Fragment>
                                                                                    <span>{new Date(this.props.appSettings.projectLastModified).toLocaleDateString()}</span>
                                                                                    <span> - </span>
                                                                                    <span>{new Date(this.props.appSettings.projectLastModified).toLocaleTimeString()}</span>
                                                                                </React.Fragment>
                                                                            )
                                                                            : (<span>unsaved</span>)
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    )
                                                    : (
                                                        <Box>
                                                            <Link to="/login?redirect=/build">
                                                                <Button variant="outlined" fullWidth>
                                                                    <span>login <i className="fas fa-sign-in-alt"></i></span>
                                                                </Button>
                                                            </Link>
                                                        </Box>
                                                    )
                                            }
                                        </Box>
                                    </Box>
                                    <Tab.Container defaultActiveKey="tree">
                                        <Nav variant="tabs" justify>
                                            <Nav.Item>
                                                <Nav.Link style={{ border: "1px solid rgb(50,50,50)", paddingTop: "5px", paddingBottom: "5px" }} eventKey="tree"><LayersIcon fontSize={'small'} /> layers</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link style={{ border: "1px solid rgb(50,50,50)", paddingTop: "5px", paddingBottom: "5px" }} eventKey="background"><PanoramaIcon fontSize={'small'} /> background</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content className="scroller" style={{ overflow: "auto", height: "100%" }} >
                                            <Tab.Pane eventKey="tree">
                                                <Box p={1}>

                                                    <Grid container spacing={1} alignItems="center" style={{ borderBottomStyle: 'solid', borderBottomWidth: 'thin', borderBottomColor: 'rgba(255,255,255,.4)', paddingBottom: "5px" }}>
                                                        <Grid item>
                                                            <Fab style={{ outline: "none", width: "40px", height: "40px", minHeight: "40px" }} size="small" aria-label="add" onClick={this.addComponent}><i className="fa fa-plus"></i></Fab>
                                                        </Grid>
                                                        <Grid item xs>
                                                            <SelectInput name={"component-type"} value={this.state.type} onChange={this.selectType} options={ComponentTypes} includeGroups={true} includeIcons={true} label={"type"}></SelectInput>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box p={1}>
                                                    {
                                                        !this.props.components.length
                                                            ? <span><ArrowUpwardIcon /> add an element to the canvas</span>
                                                            : (<LayerTree select={this.selectComponentId} selectedComponentId={this.props.appSettings.selectedComponentId} />)
                                                    }
                                                </Box>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="background">
                                                <BackgroundEditor />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Box>
                </div>
            </div >
        );
    }
}
const mapState = (state) => { return { components: state.components, inputs: state.inputs, videoInputs: state.videoInputs, shared: state.shared, userPreferences: state.userPreferences, appSettings: state.appSettings, user: state.user } };
const mapDispatch = {
    addComponent,
    appSettingChange,
    appSettingsChange,
    addDefinedComponents,
    loadDefaultShared,
    loadDefaultComponents,
    loadComponents,
    loadShared,
};
export default connect(mapState, mapDispatch)(MainEditor);