import * as BABYLON from 'babylonjs';
import { getShapeValue } from "./utility";

export const planeProperties = [
    "height",
    "width",
]
export const plane = (scene, component, audioFactor, time) => {
    let options = {
        height: getShapeValue(component, 'height', audioFactor, time),
        width: getShapeValue(component, 'width', audioFactor, time),
        updatable: true,
    }

    return BABYLON.MeshBuilder.CreatePlane(component.id, options, scene);
}