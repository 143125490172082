import * as BABYLON from 'babylonjs';
import { getShapeValue } from "./utility";

export const discProperties = [
    "radius",
    "tessellation",
    "arc",
]
export const disc = (scene, component, audioFactor, time) => {
    let options = {
        radius: getShapeValue(component, 'radius', audioFactor, time),
        tessellation: Math.floor(getShapeValue(component, 'tessellation', audioFactor, time)),
        arc: getShapeValue(component, 'arc', audioFactor, time),
        updatable: true,
    }

    return BABYLON.MeshBuilder.CreateDisc(component.id, options, scene);
}