class DirtyVideoInputs {
    add = [];
    delete = [];
    update = [];
    shouldClearCanvas = false;
    clear = () => {
        this.add = [];
        this.delete = [];
        this.update = [];
        this.shouldClearCanvas = false;
    }
}

class VideoData {
    loaded = {};
    dirty = new DirtyVideoInputs();
}

export const videoData = new VideoData();