import React from 'react';
import { Box, InputLabel } from '@material-ui/core'
import { connect } from 'react-redux';
import SliderInput from '../inputs/slider';
import Vector3 from '../inputs/vector3';
import TextInputBox from '../inputs/textbox';
import SelectInput from '../inputs/selectbox';
import { sharedSettingChange } from '../../../redux/actions/sharedActions';
import { backgroundOptions } from '../../../redux/reducers/sharedReducer';
import FileInput from '../inputs/file';
import { ASSET_TYPE_IMG, CAMERA_TYPES, CAMERA_TYPE_BIRDSEYE } from '../../factories/canvasData';

class BackgroundEditor extends React.Component {
    sharedChange = (e) => {
        let value = e.target.value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        this.props.sharedSettingChange(e.target.name, value)
    }
    cameraChange = (e) => {
        this.props.sharedSettingChange(e.target.name, e.target.value)
    }
    render() {
        let videoInputs = [
            { name: "none", value: "none" },
        ]
        this.props.videoInputs.forEach((i, ndx) => {
            let name = "";
            switch (i.type) {
                case "webcam": name = i.deviceName; break;
                case "file": name = i.videoFileName; break;
            }
            videoInputs.push({
                name: `${ndx + 1}: ${name}`,
                value: ndx
            })
        });
        return (
            <Box p={1}>
                <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                    <InputLabel style={{ fontSize: "small" }}>background</InputLabel>
                    <SelectInput name="backgroundType" value={this.props.shared.backgroundType} onChange={this.sharedChange} options={backgroundOptions} label="type" />
                    {
                        this.props.shared.backgroundType == "color"
                            ? (
                                <TextInputBox type="color" name="backgroundColor" value={this.props.shared.backgroundColor} onChange={this.sharedChange} label="color" />
                            )
                            : ""
                    }
                    {
                        this.props.shared.backgroundType == "image"
                            ? (
                                <FileInput name="backgroundImage" value={this.props.shared.backgroundImage} onChange={this.sharedChange} label="image" assetType={ASSET_TYPE_IMG} userId={this.props.user.userId} />
                            )
                            : ""
                    }
                    {
                        this.props.shared.backgroundType == "video"
                            ? (
                                <SelectInput name="backgroundVideo" value={this.props.shared.backgroundVideo} onChange={this.sharedChange} options={videoInputs} label="video input" />
                            )
                            : ""
                    }
                </Box>

                <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                    <InputLabel style={{ fontSize: "small" }}>camera</InputLabel>
                    <SelectInput name="cameraType" value={this.props.shared.cameraType} onChange={this.sharedChange} options={CAMERA_TYPES} label="camera type" />
                    {
                        this.props.shared.cameraType === CAMERA_TYPE_BIRDSEYE ? (
                            <React.Fragment>
                                <Vector3 name={"cameraPosition"} value={this.props.shared.cameraPosition} onChange={(name, e) => { let val = { target: { name: name, value: { ...this.props.shared.cameraPosition } } }; val.target.value[e.target.name] = Number(e.target.value); this.cameraChange(val) }} />
                                <SliderInput value={this.props.shared.cameraLatitude} onChange={this.cameraChange} min={0} max={360} step={.01} label={"latitude"} name={"cameraLatitude"} />
                                <SliderInput value={this.props.shared.cameraLongitude} onChange={this.cameraChange} min={0} max={180} step={.01} label={"longitude"} name={"cameraLongitude"} />
                                <SliderInput value={this.props.shared.cameraZoom} onChange={this.cameraChange} min={1} max={100} step={.01} label={"zoom"} name={"cameraZoom"} />
                            </React.Fragment>
                        )
                            : ""
                    }

                </Box>
            </Box>
        );
    }
}

const mapCanvasData = (state) => { return { shared: state.shared, user: state.user, videoInputs: state.videoInputs } };

const mapDispatch = {
    sharedSettingChange,
};

export default connect(mapCanvasData, mapDispatch)(BackgroundEditor);