export const loadDefaultVideoInputs = (inputs) => ({
    type: 'DEFAULT_VIDEO_INPUTS',
    inputs: inputs,
})

export const loadVideoInputs = (inputs) => ({
    type: 'LOAD_VIDEO_INPUTS',
    inputs: inputs,
})

export const addVideoInput = (type) => ({
    type: 'ADD_VIDEO_INPUT',
    iType: type,
})

export const deleteVideoInput = (id) => ({
    type: 'DELETE_VIDEO_INPUT',
    id: id,
})

export const videoInputPropChange = (id, name, value) => ({
    type: 'VIDEO_INPUT_PROP_CHANGE',
    id: id,
    name: name,
    value: value,
})

export const reorderVideoInputs = (inputs) => ({
    type: 'REORDER_VIDEO_INPUTS',
    inputs: inputs
})

