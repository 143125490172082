import React from 'react';
import { Fab, Grid, Slider, CircularProgress } from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';
import { secondsToTrackTimeString } from '../../utility/math';
import { audioData, LoadedAudio } from '../../factories/audioData';
import WaveSurfer from 'wavesurfer.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.js'
import { v4 as uuidv4 } from 'uuid';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';

class MicController extends React.Component {
    waveformHeight = 25;
    wavesurfer = undefined;
    wavesurferManualSeek = false;
    wavesurferReady = false;
    waveformId = uuidv4();
    state = {
        currentTime: 0,
        wavesurferReady: false,
        zoom: 20,
    }

    mute = () => {
        this.props.propsChange(this.props.input.id, 'mute', !this.props.input.mute);
        this.setState({ currentTime: 0 })
    }

    updateTime = (time) => {
        const _this = this;
        this.setState({ currentTime: time });
        if (_this.wavesurfer && _this.wavesurfer.url != _this.props.input.audioFileUrl) {
            _this.setState({ wavesurferReady: false })
            _this.wavesurfer.destroy();
            delete _this.wavesurfer;
        }
        if (!_this.wavesurfer) {
            let elem = document.getElementById(this.waveformId);
            if (elem) {
                _this.wavesurfer = WaveSurfer.create({
                    container: elem,
                    waveColor: 'white',
                    progressColor: 'grey',
                    height: _this.waveformHeight,
                    barWidth: 1,
                    normalize: true,
                    hideScrollbar: true,
                    plugins: [
                        CursorPlugin.create({
                            showTime: true,
                            opacity: 1,
                            customShowTimeStyle: {
                                'background-color': '#000',
                                color: '#fff',
                                padding: '2px',
                                'font-size': '10px'
                            }
                        })
                    ]
                });
                _this.wavesurfer.on('ready', function () {
                    _this.setState({ wavesurferReady: true })
                });
                _this.wavesurfer.drawer.on('click', function (data) {
                    _this.wavesurferManualSeek = true;
                });
                _this.wavesurfer.on('seek', function (position) {
                    if (_this.wavesurferManualSeek){
                        let currentTime = position * _this.wavesurfer.getDuration();
                        _this.props.propsChange(_this.props.input.id, 'mute', false);
                         _this.props.propsChange(_this.props.input.id, 'currentTime', currentTime);
                         _this.props.propsChange(_this.props.input.id, 'mute', true);
                         _this.setState({ currentTime: 0 })
                         _this.wavesurferManualSeek = false;
                    }         
                });      
                if (_this.props.input.audioFileUrl) {
                    _this.wavesurfer.url = _this.props.input.audioFileUrl;
                    _this.wavesurfer.load(_this.props.input.audioFileUrl);
                }
            }    
        }
        else {
            let position = (_this.props.input.currentTime + _this.state.currentTime)/_this.props.input.trackLength;
            if (position >= 0 && position <= 1){
                _this.wavesurfer.seekAndCenter(position)
            }
        }
    }

    

    render() {    
        const btnStyle = { outline: "none", width: "20px", height: "20px", minHeight: "20px" }
        return (
            <React.Fragment>
                
                <Grid spacing={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px", width: "100%" }} container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Fab style={btnStyle} title="mute" onClick={this.mute}>
                            {
                                this.props.input.mute ? <MicOffIcon /> : <MicIcon />
                            }
                        </Fab>
                    </Grid>
                    <Grid item xs style={{marginBottom:"-15px"}}>
                        <div id={this.waveformId} style={{ overflow:"hidden"}}></div>       
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default MicController;
