export const filterAudioData = (audioData, filter, offset) => {
    const result = [];
    let filterIndex = 0;
    let batchIndex = 0;
    let batchAmount = 0;
    offset = offset || 0;
    let x = filter[filterIndex].x;
    let y = filter[filterIndex].y / 100;
    let x1 = filter[filterIndex+1].x;
    let y1 = filter[filterIndex+1].y / 100;
    batchAmount = (y1 - y) / (x1 - x);

    if (audioData && audioData.length && filter && filter.length) {
        for (let i = 0; i< audioData.length; i++) {
            if (filterIndex+1 != filter.length && filter[filterIndex+1].x == i) {
                batchIndex = 0;
                filterIndex++;

                x = filter[filterIndex].x;
                y = filter[filterIndex].y / 100;
                x1 = filter[filterIndex+1].x;
                y1 = filter[filterIndex+1].y / 100;
                batchAmount = (y1 - y) / (x1 - x);              
            }
            
            let amount = (filter[filterIndex].y/100) + (batchAmount * batchIndex)
            result.push((audioData[i] + offset) * amount)
            batchIndex++;
        }
    }
    return result;    
}

export const rmsFrequencyDomain = (audioData) => {
    let avgPowerDecibels = 0;
    if (audioData && audioData.length){
       let sumOfSquares = 0;
       for (let i = 0; i < audioData.length; i++) {
         sumOfSquares += (Math.abs(audioData[i])) ** 2;
       }
    avgPowerDecibels = Math.sqrt(sumOfSquares / audioData.length);
    }
    return avgPowerDecibels.toFixed(2);
}

export const rmsTimeDomain = (audioData) => {
    let avgPowerDecibels = 0;
    if (audioData && audioData.length){
       let sumOfSquares = 0;
       for (let i = 0; i < audioData.length; i++) {
         sumOfSquares += (Math.abs(audioData[i])) ** 2;
       }
    avgPowerDecibels = Math.sqrt(sumOfSquares / audioData.length);
    }
    return avgPowerDecibels.toFixed(2);
}