import React from 'react';
import { Grid, FormControl, Switch, InputLabel, Box } from '@material-ui/core'

class SwitchInput extends React.Component {

    render() {
        return (
            <FormControl fullWidth margin='none'>
                <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                    <Grid container>
                        <Grid item xs={10}>
                            <InputLabel style={{ position: "relative", margin: "0", transform: "none", fontSize: "small" }} id={this.props.name}>{this.props.label}</InputLabel>

                        </Grid>
                        <Grid item xs={2}>
                            <Switch
                                checked={this.props.value}
                                onChange={this.props.onChange}
                                name={this.props.name}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </FormControl>
        );
    }
}
export default SwitchInput;