export const getFileExtension = (filename) => { return filename.split('.').pop(); }
export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
export const file2Base64Async = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })
}

export const base64toFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const VIDEO_TYPES = ["webm", "ogg", "mp4", "x-matroska", "quicktime"];
export const VIDEO_EXTENSIONS = {
  "webm": "webm",
  "ogg": "ogg",
  "mp4": "mp4",
  "x-matroska": "mkv",
}
export const AUDIO_TYPES = ["webm", "ogg", "mp3", "x-matroska"];
export const VIDEO_CODECS = ["vp9", "av1", "avc", "hevc", "h265", "h.265", "h264", "h.264", "h263", "h.263", "mp4v-es", "mp4", "mp4a", "mpeg", "vp8", "theora", "ogg"];
export const AUDIO_CODECS = ["aac", "opus", "alac", "flac", "mp3", "vorbis", "pcm"]
// export const CODECS = ["vp9", "vp9.0", "vp8", "vp8.0", "avc1", "av1", "h265", "h.265", "h264", "h.264", "opus", "pcm", "aac", "mpeg", "mp4a"];
export function getSupportedRecordingMimeTypes() {
  const supported = [];
  VIDEO_TYPES.forEach((type) => {
    const mimeType = `${"video"}/${type}`;
    VIDEO_CODECS.forEach((vCodec) => {
      AUDIO_CODECS.forEach((aCodec) => {
        let variation = `${mimeType};codecs=${vCodec},${aCodec}`;
        if (MediaRecorder.isTypeSupported(variation)) {
          supported.push({
            type: type,
            extension: VIDEO_EXTENSIONS[type],
            mime: variation,
          });
        }
      })
    })
    // VIDEO_CODECS.forEach((codec) => [
    //     `${mimeType};codecs=${codec}`,
    //     `${mimeType};codecs=${codec.toUpperCase()}`,
    //   ].forEach(variation => {
    //     if(MediaRecorder.isTypeSupported(variation)) {
    //       supported.push({
    //           type: type,
    //           extension: VIDEO_EXTENSIONS[type],
    //           mime: variation,
    //       });
    //     }
    // }));
    // if (MediaRecorder.isTypeSupported(mimeType)) {
    //   supported.push({
    //       type: type,
    //       extension: VIDEO_EXTENSIONS[type],
    //       mime: mimeType,
    //   });
    // }
  });

  return supported;
};

export function getSupportedStreamingMimeTypes() {
  const supported = [];
  VIDEO_TYPES.forEach((type) => {
    const mimeType = `${"video"}/${type}`;
    VIDEO_CODECS.forEach((vCodec) => {
      AUDIO_CODECS.forEach((aCodec) => {
        let variation = `${mimeType};codecs=${vCodec},${aCodec}`;
        if (MediaSource.isTypeSupported(variation))
          if (MediaRecorder.isTypeSupported(variation)) {
            supported.push({
              type: type,
              extension: VIDEO_EXTENSIONS[type],
              mime: variation,
            });
          }
      })
    })
    // VIDEO_CODECS.forEach((codec) => [
    //     `${mimeType};codecs=${codec}`,
    //     `${mimeType};codecs=${codec.toUpperCase()}`,
    //   ].forEach(variation => {
    //     if(MediaRecorder.isTypeSupported(variation)) {
    //       supported.push({
    //           type: type,
    //           extension: VIDEO_EXTENSIONS[type],
    //           mime: variation,
    //       });
    //     }
    // }));
    // if (MediaRecorder.isTypeSupported(mimeType)) {
    //   supported.push({
    //       type: type,
    //       extension: VIDEO_EXTENSIONS[type],
    //       mime: mimeType,
    //   });
    // }
  });

  return supported;
};

export function download(url, filename) {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
}