import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './redux/store'
import _users from './services/usersService';
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#b7b7b7',
    },
    background: {
      default: "#181818",
      paper: "#222222",
    }
  },
  typography: {
    fontFamily: 'monospace',
    button: {
      textTransform: "none"
    }
  },
  overrides: {
    MuiTab: {
        wrapper: {
            textAlign: "left",
            display:"block"
        }
    },
    MuiTableHeader: {
      header: {
        textAlign:"left !important"
      }
    }
}
});
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
