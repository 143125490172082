import { v4 as uuidv4 } from 'uuid';

const STORAGE_KEY = "exec-app-settings";

let defaultSettings = {
    sessionId: uuidv4(),
    projectId: undefined,
    projectDataId: undefined,
    projectName: "new project",
    projectLastModified: undefined,
    projectUserId: undefined,
    view: 'edit',
    selectedComponentId: undefined,
    gizmo: "",
    isRecording: false,
    access: "write",

    playlistId: undefined,
    playlistName: "new project",
    playlistLastModified: undefined,
    playlistUserId: undefined,
}

const init = () => {
    let saved = sessionStorage.getItem(STORAGE_KEY);
    if (saved)
        saved = JSON.parse(saved);
    return saved ? {
        sessionId: uuidv4(), 
        ...saved, 
        isRecording: false, 
    } : defaultSettings;
}

const reduce = (state, action) => {
    switch (action.type) {
        case 'APP_SETTING_CHANGE':
            state[action.name] = action.value;
            return {
                ...state,
            }
        case 'APP_SETTINGS_CHANGE':
            for (let key of Object.keys(action.value))
                state[key] = action.value[key];
            
            return {
                ...state,
            }
        default:
            return state
    }
}

const sharedReducer = (state = init(), action) => {
    state = reduce(state, action);
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    return state;
}
export default sharedReducer