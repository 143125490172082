let subscribers = {}
export const _renderClock = {
    publish: (time) => {
        Object.keys(subscribers).forEach(key => {
            subscribers[key](time);
        });
    },
    subscribe: (id, cb) => {
        subscribers[id] = cb;
    },
    unsubscribe: (id) => {
        delete subscribers[id];
    },
};
