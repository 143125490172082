import { VENUE_URL } from "./config";
import _api from "./serviceClient";
import { v4 as uuidv4 } from 'uuid';

const _venueAccess = {
    saveVenueAccess: (venueAccess) => {
        venueAccess = {id: uuidv4(), ...venueAccess}
        return _api.post(VENUE_URL, `/VenueAccess/SaveVenueAccess`, venueAccess);
    },
    getVenueAccessByVenueId: (id) => {
        return _api.get(VENUE_URL, `/VenueAccess/GetVenueAccessByVenueId?venueId=${id}`);
    },
    getVenueAccess: () => {
        return _api.get(VENUE_URL, `/VenueAccess/GetVenueAccess`);
    },
    deleteVenueAccess: (id) => {
        return _api.delete(VENUE_URL, `/VenueAccess/DeleteVenueAccessByVenueAccessId?venueAccessId=${id}`);
    },
}

export default _venueAccess;
