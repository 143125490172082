import * as BABYLON from 'babylonjs';
import { getShapeValue } from "./utility";

export const cylinderProperties = [
    "height",
    "diameterTop",
    "diameterBottom",
    "tessellation",
    "subdivisions",
]
export const cylinder = (scene, component, audioFactor, time) => {
    let options = {
        height: getShapeValue(component, 'height', audioFactor, time),
        diameterTop: getShapeValue(component, 'diameterTop', audioFactor, time),
        diameterBottom: getShapeValue(component, 'diameterBottom', audioFactor, time),
        tessellation: Math.floor(getShapeValue(component, 'tessellation', audioFactor, time)),
        subdivisions: Math.floor(getShapeValue(component, 'subdivisions', audioFactor, time)),
        arc: getShapeValue(component, 'arc', audioFactor, time),
        updatable: true,
    }

    return BABYLON.MeshBuilder.CreateCylinder(component.id, options, scene);
}